@import "styles/mixins";
@import "styles/variables";

#folder-page {
  height: 100%;
  overflow-y: scroll;

  .without-padding {
    padding-top: 0;
  }
  .folder-name {
    padding: 2px 20px 0;
    font-weight: bold;
    font-size: 25px;
    justify-content: left;
    line-height: 1.27;
  }
  .edit-folder-name-title {
    margin-left: 10px;
  }
  .edit-folder-icon{
    cursor: pointer;
  }
  .folder-pointer{
    cursor: pointer;  
    //margin-top: 5px;
  }
  .folder-description {
    padding: 14px 0 0 20px;
    line-height: 16px;
    color: #A2A3A3;
    font-size: 13px;
    align-items: center;
    @include media-orientation(landscape) {
      @include media(xs sm) {
        padding-top: 4px;
        padding-bottom: 14px;
      }
    }
    @include media-orientation(portrait) {
      @include media(xs sm) {
        padding-top: 14px;
      }
    }
    svg {
      min-width: 34px;
    }
   
    > div {
      margin-left: 10px;
    }
  }
}

