@import "styles/variables";
@import "styles/mixins";
.note-editor {
  //border-top: 1px solid #E1E6E6;
  height: 100%;
  padding: 12px 20px;
  line-height: normal;
  background: $white;
  overflow: overlay;
  position: relative;
  @include media-orientation(landscape) {
    @include media(xs sm) {
      border-top: none;
    }
  }
  .editor-focused-marker {
    position: absolute;
    left: -4px;
    background: $orange;
    border-radius: 20px;
    top: 12px;
    bottom: 12px;
    width: 8px;
  }

  textarea {
    height: 100%;
    background: unset;
    border: none;
    width: 100%;
    resize: none;
    padding: 0;
    font-family: "Open Sans";
    font-size: 15px;
    color: $grey-light-11;
    text-shadow: 0 -0.2px 0.2px rgba(75, 77, 77, 0.39), 0 -0.1px 0 $white;
    outline: none;
    -webkit-appearance: none;
    border-radius: 0;
    @supports (-webkit-overflow-scrolling: touch) {
      /* CSS specific to iOS devices */
      margin-left: -3px;
    }

    @supports not (-webkit-overflow-scrolling: touch) {
      /* CSS for other than iOS devices */
    }
  }

  textarea::placeholder {
    font-size: 15px;
    line-height: 1.27;
    text-align: left;
    color: $grey-light-10;
    text-shadow: 0 -0.2px 0.2px rgba(75, 77, 77, 0.39), 0 -0.1px 0 $white;
  }
}
.edit-text-note{
  height: 100%;
  background: unset;
  border: none;
  width: 100%;
  resize: none;
  font-family: "Open Sans";
  font-size: 15px;
  color: #4b4d4d;
  text-shadow: 0 -0.2px 0.2px rgba(75, 77, 77, 0.39), 0 -0.1px 0 #ffffff;
  outline: none;
  white-space: pre-wrap;

  .edit-text-focus {
    font-size: 15px;
    line-height: 1.27;
    text-align: left;
    color: #b1b6b5;
    text-shadow: 0 -0.2px 0.2px rgba(75, 77, 77, 0.39), 0 -0.1px 0 #ffffff;
  }
}
