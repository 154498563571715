@import 'styles/variables';

.menu-button{
    .menu-item-container .isActive svg {
        rect, path, circle {
            stroke: $white;
        }
    }
    
    @media (pointer: fine) {
        &:hover{
            width: fit-content;

            .menu-item-container {   
                width: fit-content;
                background: $white;
                box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
                border-radius: 26px;
                padding:6px 24px 6px 6px;
            }

            .menu-name{
                display:block;
                margin-left: 8px;
                visibility: visible;

                font-weight: 700;
                font-size: 19px;
                line-height: 26px;
            }

            .lm-key-name{
                display:flex;
                margin-left: 10px;
                visibility: visible;

                background: $gradient-top-bottom;
                box-shadow: $bx-key-short;
                border-radius: 7px;
                font-size: 13px;
                width:23px;
                height:23px;
            }
        }
    }
    
    .menu-item-container {
        &:active{
            .menu-link{
                background-color: $blue;
            }
            svg {
                rect, path, circle {
                    stroke: $white;
                }
            }
        }
    }
}
