@import 'styles/mixins';

.folders-tooltip-container {
  width: 375px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: white;
  border-radius: 8px;


  @include media(xs sm) {
    height: 100%;
  }
  .folders-list-container {
    padding-top: 50px;
    height: 100%;
    @include media-orientation(landscape) {
      height: 100%;
    }

    div.folders-list{
      padding-bottom: 150px;
    }
  }
}

.folders-title-container {
  display: flex;
  align-items: center;
}

.folders-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
  line-height: 1.2;
}
