@import 'styles/variables';
@import 'styles/mixins';

.answer-statistics {

    border-radius: 21px;
    flex-grow: 1;

    margin-bottom: 1rem;

    @include media(vsm) {
        margin-bottom: 1.2rem;
    }

    svg {
        width: 26px;
        height: 26px;
    }

    .answer-inner-container {
        border: 1px solid $bx-grey;
        box-sizing: border-box;
        border-radius: 22px;
        padding: 4px;
        height: 140px;

        width: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        //margin-bottom: 1rem;	
        .answer-line {
            flex-grow: unset;
        }
    }

    .answer-assesstment {
        display: flex;
        align-items: center;

        @include media(vsm) {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
    }

    .answer-line {
        flex-grow: 1;
        height: 36px;
        width: 36px;
        display: inline-block;
        background: $default-grey;
        border-radius: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &.correct {
            background: linear-gradient(120deg, $light-green1, $light-green2);
        }
        &.wrong {
            background: linear-gradient(120deg, $dark-orange, $dark-pink2);
        }
    }

    .answer-lines-container {
        flex-grow: 1;
        align-items: center;
        padding: 0 4px;
        margin-top: 10px;
    }

    .description {
        @media (max-width: 480px) {
            @media(orientation: portrait){
                position: absolute;
                bottom: 0;
                margin-left: 0;
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }
    }

}

.hide-animation {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.show-animation {
    opacity: 1;
}

.exam-show-animation {
    opacity: 1;
}

.answer-inner-container {
    .svg-color {
        svg {
            path{
                &:first-child{
                    stroke: $white;
                }
                &:not(&:first-child) {
                    fill: $white;
                }
            }
            rect {
                fill: $white;
            }
        }
    }
    .answer-line {
        &.assessment_confident {
            &.active {
                background: $dark-green2;
                box-shadow: 0px 1px 3px $dark-green2;
                svg.confident-assessment {
                    fill: $white;
                }
            }
            
            @media (pointer: fine) {
                &:hover {
                    background: $hover-grey;
                    box-shadow: none;

                    &.active {
                        background: $light-green3;
                        box-shadow: 0px 1px 3px $dark-green2;
                        svg path:first-child {
                            stroke: $light-green3;
                        }
                    }
                }
            }

            &:active {
                background: $dark-green3;
                box-shadow: 0px 1px 3px $dark-green2;

                svg {
                    fill: $white;
                    path:first-child {
                        stroke: $dark-green3;
                        fill: $white;
                    }
                }
            }
        }

        &.assessment_neither {
            &.active {
                background: $light-grey2;
                box-shadow: 0px 1px 3px rgb(161 172 179 / 86%);
                svg.neither-assessment {
                    fill: $white;
                    path:first-child {
                        stroke: $light-grey2;
                    }
                }
            }
            
            @media (pointer: fine) {
                &:hover {
                    background: $hover-grey;
                    box-shadow: none;

                    &.active {
                        background: $light-grey3;
                        box-shadow: 0px 1px 3px rgb(161 172 179 / 86%);
                        svg{
                            fill: $white;
                            path:first-child {
                                stroke: $light-grey3;
                            }
                        } 
                    }
                }
            }

            &:active {
                background: $light-grey2;
                box-shadow: 0px 1px 3px rgb(161 172 179 / 86%);
                
                svg {
                    fill: $white;
                    path:first-child {
                        stroke: $light-grey2;
                    }
                }
                &.active {
                    background: $light-grey4;
                    box-shadow: 0px 1px 3px rgb(161 172 179 / 86%);
                    svg {
                        fill: $white;
                        path:first-child {
                            stroke: $light-grey4 !important;
                        }
                    }
                }
            }
        }

        &.assessment_uncofident {
            &.active {
                background: $light-orange;
                box-shadow: 0px 1px 3px $light-orange;
                svg.unconfident-assessment {
                    fill: $white;
                }
            }
            
            @media (pointer: fine) {
                &:hover {
                    background: $hover-grey;
                    box-shadow: none;

                    &.active {
                        background: $light-orange2;
                        box-shadow: 0px 1px 3px $light-orange2;
                        svg {
                            fill: $white;
                            path:first-child {
                                stroke: $light-orange2;
                            }
                        } 
                    }
                }
            }

            &:active {
                background: $light-orange;
                box-shadow: 0px 1px 3px $light-orange;

                svg {
                    fill: $white;
                    path:first-child {
                        stroke: $light-orange;
                    }
                }
                &.active {
                    background: $light-orange3;
                    box-shadow: 0px 1px 3px $light-orange3;
                    svg {
                        fill: $white;
                        path:first-child {
                            stroke: $light-orange3;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px)  {
    @media(orientation: portrait){
        .answer-statistics {
            margin-bottom: 0;
        }
        .answer-inner-container {
            padding: 4px !important;
            width: 140px !important;
            height: 44px !important;
            flex-direction: row !important;
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
        }
    }
}