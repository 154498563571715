@import 'styles/variables';
@import 'styles/mixins';
@import "styles/animations";

.micro-filter-inactive {
  box-shadow: 0 0 0 1px #E3E6E6;
  background: #FFFFFF;

  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 0 0 1px #C8CCCC;
    }
  }

  .micro-arrow {
    margin: 8px 12px 8px 6px !important;
    // transform:  ${props => (props.filter && props.isMarging) ? "rotate(180deg)" : "rotate(360deg)"};
    transition-duration: 0.3s;
  }

  &:active {
    background: #378EF8;
    box-shadow: none;

    .filter-text {
      color: #FFFFFF;
    }

    svg {
      circle {
        fill: #FFFFFF;
      }

      path {
        &:first-child {
          fill: #FFFFFF !important;
        }

        fill: #FFFFFF;
      }
    }
  }
}


.micro-filter-active {
  box-shadow: 0 0 0 2px #378EF8;
  background: #E6EEF7;

  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 0 0 2px #64A8FA;
    }
  }

  &:active {
    background: #378EF8;
    box-shadow: none;

    .filter-text {
      color: #FFFFFF;
    }

    svg {
      circle {
        fill: #FFFFFF;
      }

      path {
        &:first-child {
          fill: #FFFFFF !important;
        }

        fill: #FFFFFF;
      }
    }
  }

  .closeButton1 {
    box-shadow: none;
    // background: #378EF8;

    svg {
      margin: 0;
    }

    circle {
      fill: #FFFFFF;
    }

    path {
      fill: #378EF8 !important;
    }

  }


  .closeButton1 {
    height: 36px;
    width: 36px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 7;

    &:active {
      z-index: 999;
      box-shadow: none;
      // background: #378EF8;

      circle {
        fill: #FFFFFF;
      }

      path {
        fill: #378EF8 !important;
      }
    }
  }

  .closeButton {
    height: 36px;
    width: 36px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 7;

    @media (pointer: fine) {
      z-index: 999;

      &:hover {
        background: #B2D4FC;
      }
    }
  }
}
