@import 'styles/_mixins';
@import 'styles/variables';


.account-portal-wrapper{
    min-width: 300px;
    z-index: 2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    background: $white;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    border-radius: 3px;
    user-select: none;
    cursor: default;
    overflow: scroll;
    max-height: 100%;
    top: 60px;
    right: 6px;
    padding:5px;
    background-clip: padding-box;
    .mb-list:not(:last-child){
        margin-bottom: 3px;
    }
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    @media (max-width: 767px) {
        width: calc(100% - 20px);
        right: 10px;
        box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    }
}
.account-prev-list{
    height:38px;
    padding:4px 20px 4px 5px;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    align-items: center;
    align-items: center;
    display: flex;
    overflow: hidden;
    line-height: 1.428571429;
    @media (pointer: fine) {
        &:hover {
            background: $blue-x;
            .txt-color{
                color: $white !important;
            }
        }
    }
    &:active {
        background: $blue-x;
        .txt-color{
            color: $white !important;
        }
    } 
}
.user-img{
    display: flex;
    margin-right: 5px;
    width: 32px;
    height: 32px;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    img{
      border-radius:5px;
      max-width: 32px;
    }
    svg{
      border-radius:5px;
      max-width: 32px;
    }
}