@import 'styles/_mixins';
@import 'styles/variables';


.exercise-save-btn {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #FFFFFF;

  &:active {
    color: #0A7670;
  }
}

.fxied-spacing {
  padding-bottom: 150px;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: overlay;
}

.exercise-round-btn {
  position: absolute;
  right: 14px;
  box-shadow: 0 2px 6px 0 rgba(62, 87, 85, 0.32);
  margin: 0;
  display: none;

  @media (min-width: 600px) and (max-width: 1024px) and (orientation: portrait) {
    display: none !important;
  }

  @include media(xs) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.exercise-tvl-slider{
  position: fixed;
  top:0; 
  left:0; 
  right: 0;
  max-width: 375px;
  z-index: 2;
  overflow: hidden;
  padding-bottom: 10px;
  // media for iFrameMobile 
  @media (max-width: 550px) {
    max-width: 100%;
  }
}
.exercise-shadow{
  background-color: white;
  transition: box-shadow .15s linear;
  border-bottom: 1px solid rgb(225, 230, 230);

}
.fixed-page-spacing{
  padding-top: 56px;
  padding-bottom: 150px;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow:overlay;
}
.list-wrapper{
  overflow: hidden;
  padding: 2px 14px 0;
  .fade-enter-active {
    display: none;
  }
  
  .fade-enter-done {
    display: block;
  }
  
  .slide-exit {
    max-height: 120px;
  }
  
  .slide-exit-active {
    animation: slideItem .3s linear forwards, downsize .2s linear forwards;
    animation-delay: 0s, .3s;
  }
}