@import 'styles/variables';
@import 'styles/mixins';
.footer-edit-mode {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
	background-image: linear-gradient(225deg,#43DAF0 0%,#395BF8 100%);
  box-shadow: 0 -0.5px 5px 0 rgba(148, 148, 148, 0.35);
  @include media(xs sm) {
    @include media-orientation(landscape) {
        bottom: 0;
      }
    }
  align-items: center;
  .footer-label {
    font-size: 13px;
    text-align: left;
    color: #ffffff;
    padding: 14px 20px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    @media (pointer: fine) {
      &:hover {
        opacity: 0.75;
      }
    }
    &:active {
      color: #0A7670;
    }
  }
  .delete-btn {
    margin-right: 20px;
    cursor: pointer;
    &.inactive {
      cursor: default;
      user-select: none;
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
.footer-delete-mode {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 45px;
  display: flex;
  justify-content: space-between;
	background-image: linear-gradient(101deg, #fc270a, #fc1652);
	box-shadow: 0 -0.5px 5px 0 rgba(148, 148, 148, 0.35);
  align-items: center;
  @include media(xs sm) {
    @include media-orientation(landscape) {
        bottom: 0;
      }
    }
  cursor: pointer;
  .delete-btn {
    margin: auto;
    cursor: pointer;
    &.inactive {
      cursor: default;
      user-select: none;
      pointer-events: none;
      opacity: 0.6;
    }
    
  }
  &.contained {
    position: absolute;
  }
} 
svg#deletetrash{
  cursor: pointer;
  @media (pointer: fine) {
    &:hover {
      path{
        opacity: 0.75
      }
    }
  }
  &:active {
    path{
      stroke: #0A7670;
    }
  }
}
