@import 'styles/variables';

$width-line: 16px;
$height-line: 2px;

#hamburger-icon {
  top: -1px;
  cursor: pointer;
  width: $width-line;
  position: relative;
  display: inline-block;
  z-index: 1;
  &.color-white {
    .line {
      background: white;
    }
  }
  .line {
    display: block;
    background: $turquoise;
    width: $width-line;
    height: $height-line;
    margin-bottom: 4px;
    border-radius: 1px;

    &.color-white {
      background: #FFFFFF;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

#store-icon {
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
  z-index: 1;
  position: relative;
}
