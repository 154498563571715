@import 'styles/_variables';

.avatar {
  &.text {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $grey-light-2;
    font-size: 25px;
    border-radius: 50%;

    &.large {
      width: 110px;
      height: 110px;
      font-size: 40px;
      font-weight: bold;
      box-shadow: 0 6px 10px 0 rgba(62,87,85,0.32);
    }
  }

  &.large {
    width: 120px;
    height: 120px;
  }

  &.light {
    background-color: white;
    color: $turquoise;
  }

  &.dark {
    background-color: $turquoise;
    color: white;
  }
}
