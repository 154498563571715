
@import "styles/variables";
@import "styles/_mixins";
.empty-screen-subtitle {
    font-size: 13px;
    color: $grey-light-3;
    margin: auto;
    line-height: 16px;
    padding-top: 8px;
}
.empty-screen-bookmark-content{
    font-size: 13px;
    font-weight: normal;
    color: $grey-light-3;
    padding-top: 16px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.empty-screen-folder-content{
    font-size: 13px;
    font-weight: normal;
    color: $grey-light-3;
    padding-top: 16px;
    line-height: 16px;
}
.empty-screen-content{
    font-size: 13px;
    font-weight: bold;
    color: $grey-light-3;
    padding-top: 12px;
    line-height: 16px;
}
.empty-topic-content{
    font-size: 13px;
    color: $grey-light-3;
    padding-top: 12px;
    line-height: 16px;
}

.empty-screen-padding{
    padding: 0 20px;
}
.empty-search-screen{
    padding: 0 32px;
}
.empty-screen-btn{
    box-shadow: 0 0 0 1px $bx-grey;
    max-height: 36px;
    background: $white;
    border-radius: 22px;
    padding: 9px 0;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 0 0 1px $bx-hover-grey;
      }
    } 
    &:active {
      box-shadow: none;
      background: $blue;
      .emtpy-btn-text{
        color: $white !important;
      }
    }
}
.learn-more-btn{
  box-shadow: 0 0 0 1px $bx-grey;
  background: $white;
  border-radius: 22px;
  padding: 9px 0;
  margin-top: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  cursor: pointer;
  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 0 0 1px $bx-hover-grey;
    }
  } 
  &:active {
    box-shadow: none;
    background: $blue;
    .learn-more-txt{
      color: $white !important;
    }
    svg{
      path{
        fill: $white;
      }
    }
  }
}
.flashcard-empty-screen-container{
  margin-bottom: 14px;
  .no-flashcard{
    pointer-events: none;
    opacity: 0.5;
    color: #C8CCCC;
    .fc-empty-screen-btn{
      color: #C8CCCC;
    }
  }
  .flashcard-empty-screen {
    
    text-align: center;
    padding: 0 20px;
    .info-container{
      margin-top: 12px;
      .flashcard-empty-screen-subtitle {
        font-size: 13px;
        color: $grey-light-3        ;
        margin: auto;
        line-height: 16px;
      }
      .flashcard-empty-screen-content{
        font-size: 13px;
        color: $grey-light-3;
        font-weight: normal;
        padding-top: 16px;
        line-height: 16px;
      }
    }
    .flashcard-empty-screen-icon {
      margin: 0 auto;
    }
    .add-buttonss{	
      width: 100%;
      border-radius: 22px;	
      box-shadow: 0 0 0 1px $bx-grey;	
      border-radius: 22px;	
      cursor: pointer;
      transition: all .2s;
      .add-button-inner-section{	
        display: flex;	
        align-items: center;	
        justify-content: center;	
        padding: 7px 0;	
      }	
    }
    @include media(xs sm) {
      .info-container{
        padding: 0;
      }
    }
  }
}
.exams-notes{
  margin-bottom: 12px !important;
}
.fc-empty-screen-btn{
  width: 100%;
  border-radius: 22px;	
  box-shadow: 0 0 0 1px $bx-grey;	
  border-radius: 22px;	
  cursor: pointer;
  color: $blue;	
  &.no-flashcard{
    pointer-events: none; 
    opacity: 0.5; 
    color: $bx-hover-grey;
  }
  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 0 0 1px $bx-hover-grey;	
    }
  }
  &:active {
      background: $blue; 
      box-shadow: none; 
      color: $white;	
      .add-exercise-icon{
        path{
          fill: $white;
        }
        rect{
          
          fill: $white;
        }
    }
    .create-folder-icon{
      g#Folder {
        stroke: $white;
      }
      path#Combined-Shape{
        fill: $white;
      }
    }
    .no-notes-icon{
      path{
        stroke: $white;
      }
    }
  }  
}

.empty-topics-screen {
  padding: 0 20px;
}