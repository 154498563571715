@import 'styles/_mixins';
@import 'styles/variables';

.blue-osc-btn{
    @include osc-btn;
    background: $blue;
    
    @media (pointer: fine) {
        &:hover {
            background: $hover-blue;
        }
    } 
    &:active {
        background: $active-blue;
    }
}
.white-osc-btn{
    @include osc-btn;
    background: $white;
    box-shadow: 0 0 0 1px $bx-grey;
    color: $blue;
    // width: 319px;
    // position: absolute;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    @media (pointer: fine) {
        &:hover {
            box-shadow: 0 0 0 1px $bx-hover-grey;
        }
    } 
    &:active{
        box-shadow: none;
        background: $blue;
        color: $white;
    }
}
.simple-button {
    font-weight: bold;
    font-size: 13px;
    color: $turquoise;
    outline: none;
    cursor: pointer;
    text-align: right;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: inherit;
    &.inverted {
      color: white;
      @media (pointer: fine) {
        &:hover {
          opacity: 0.75;
        }
      }
      &:active {
        color: #0A7670; 
      }
  
      &.disabled {
        opacity: .4;
        cursor: default;
        pointer-events: none;
      }
    }
  
    &:active {
      color: darken($turquoise, 10);
    }
  }
  .edit-btn-text{
    font-weight: bold;
    font-size: 13px;
    text-align: right;
    letter-spacing: 2px;
    text-transform: uppercase;
    @media (pointer: fine) {
      &:hover {
        color: $hover-blue;
      }
    } 
    &:active {
      color: $active-blue;
    }
  }