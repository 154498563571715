@import 'styles/variables';
@import 'styles/mixins';
.list-title{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.short-desc{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    mark.text-highlighting-mark {
        background: transparent;
        color: #727373;
        font-weight: normal;
      }
}
.note-short-desc{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    mark.text-highlighting-mark {
        background: transparent;
        color: #727373;
        font-weight: normal;
      }
}
.math-shot-desc{
    -webkit-line-clamp: 1;
    color: rgb(45, 56, 56);
}
.exams-title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.exam-other-info-textellipse{
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
   &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        pointer-events: none;
    }
   
}

.exam-editMode-other-info-textellipse{
    max-width: 156px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
   &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        pointer-events: none;
    }
   
}

.exam-search-textellipse{
    max-width: 270px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
   &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        pointer-events: none;
    }
}

