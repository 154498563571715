@import 'styles/variables';
@import 'styles/mixins';
@import "styles/animations";

.no-edit-execrise {
    &::before {
        position: absolute;
    }
}

.flashcard-container {
    height: 100%;
    width: 100%;
    @include aspect-ratio(3, 2);

    .hello {
        width: 10px;
        height: 25px;
        position: absolute;
        z-index: 99999;
    }

    .content {
        // Overwrite the studying container content
        overflow-y: visible !important;
        -webkit-overflow-scrolling: unset !important;
    }

    .cube-wrapper {
        width: 100%;
        height: 100%;
        perspective: 1000px;
    }

    .cube-container {
        width: 100%;
        height: 100%;
        position: absolute;
        transform-style: preserve-3d;
        transition: transform 0.6s;

        .cube-side-face {
            height: 100%;
            width: 100%;
            margin: 0;
            display: block;
            position: absolute;
            backface-visibility: hidden;
            padding: 6px 0;

            &.front-side {
                @include media(xs sm) {
                    transform: rotateY(0deg) translateZ(48vh);
                }
            }

            &.top-side {
                @include media(xs sm) {
                    transform: rotateX(90deg) translateZ(48vh);
                }
            }

            &.bottom-side {
                @include media(xs sm) {
                    transform: rotateX(-90deg) translateZ(48vh);
                }
            }
        }

        .cube-side-renderer {
            height: 100%;
            width: 100%;
        }
    }

    .zoom-section {
        position: absolute;
        z-index: 4;
        right: 0;
        bottom: 0;
        display: flex;
        margin: 0 8px 8px 0;

        .pin-zoom {
            background: rgba(32, 61, 60, 0.75);
            mix-blend-mode: normal;
            //opacity: 0.75;
            border-radius: 22px;
            margin-right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            cursor: pointer;

            &:active {
                background: #378EF8;

                .pinch-zoom-icon {
                    opacity: 1 !important;

                    path {
                        fill: white;
                    }
                }
            }

            @media (pointer: fine) {
                &:hover {
                    .pinch-zoom-icon {
                        opacity: 0.75;
                    }
                }
            }
        }

        .zoom-factor {
            height: 36px;
            background: rgba(32, 61, 60, 0.75);
            mix-blend-mode: normal;
            //opacity: 0.75;
            border-radius: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;

            .main-progress-zoom {
                .input-range {
                    background: rgba(255, 255, 255, 0.4);
                    border-radius: 22px;
                    height: 4px;
                    width: 77px;
                    display: flex;
                    flex-direction: column;
                    margin: 0 7px;
                    justify-content: center;
                    z-index: 3;
                    -webkit-appearance: none;
                    outline: none;

                    &::-webkit-slider-thumb {
                        appearance: none;
                        background: #FFFFFF;
                        width: 16px;
                        height: 16px;
                        box-shadow: 0px 2px 4px rgba(51, 61, 61, 0.992925);
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }

                .progress-zoom {
                    display: flex;
                    align-items: unset;
                    justify-content: space-between;

                    .increse-zoom-button {
                        transition-property: all;
                        transition-duration: .15s;
                        transition-timing-function: linear;
                        transition-delay: 0s;
                        position: relative;
                        /* color: rgba(108,117,125,.7); */
                        /* text-indent: -9999px; */
                        border: none;
                        background-color: transparent;
                        align-items: center;
                        justify-content: center;
                        display: inline-flex;
                        /* outline: none!important; */
                        cursor: pointer;

                        &:first-child:after {
                            display: none
                        }

                        &:before {
                            //position: absolute;
                            display: block;
                            width: 6px;
                            height: 6px;
                            content: '';
                            border-radius: 50%;
                            background-color: #fff;
                            box-sizing: border-box;
                            z-index: 3;
                        }
                    }

                    .active-zoom {
                        &:before {
                            background: #FFFFFF;
                            width: 16px;
                            height: 16px;
                            box-shadow: 0px 2px 4px rgba(51, 61, 61, 0.992925);
                        }
                    }
                }
            }

            .decrease-zoom {
                cursor: pointer;
                align-items: center;
                justify-content: center;
                display: inline-flex;
                padding: 5px;

                &:active {
                    background: #378EF8;
                    border-radius: 22px;

                    .minus-zoom-icon {
                        opacity: 1 !important;

                        path {
                            fill: white;
                        }
                    }
                }

                @media (pointer: fine) {
                    &:hover {
                        .minus-zoom-icon {
                            opacity: 0.75;
                        }
                    }
                }
            }

            .increase-zoom {
                cursor: pointer;
                align-items: center;
                justify-content: center;
                display: inline-flex;
                padding: 5px;

                &:active {
                    background: #378EF8;
                    border-radius: 22px;

                    .plus-zoom-icon {
                        opacity: 1 !important;

                        path {
                            fill: white;
                        }
                    }
                }

                @media (pointer: fine) {
                    &:hover {
                        .plus-zoom-icon {
                            opacity: 0.75;
                        }
                    }
                }
            }

            .remove-increase-click {
                background: none !important;
                border-radius: 0;
                cursor: auto;
                user-select: none;

                &:hover {
                    .plus-zoom-icon {
                        opacity: 1;
                    }
                }
            }

            .remove-decrease-click {
                background: none !important;
                border-radius: 0;
                cursor: auto;
                user-select: none;

                &:hover {
                    .minus-zoom-icon {
                        opacity: 1;
                    }
                }
            }
        }

    }
}


.flashcard-button-control {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    touch-action: manipulation;
    width: 25%;
    cursor: pointer;

    &.left {
        margin: 4px 0 4px 4px;
    }

    &.right {
        margin: 4px 4px 4px 0;
    }
}

.flashcard-button-control-center {
    z-index: 3;
    height: 100%;
    flex: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    touch-action: manipulation;
    width: auto;
    display: inline-flex;
    cursor: pointer;
}

.landscape-flashcard-button-control {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    touch-action: manipulation;

}

.renderer-wrapper {
    height: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    overflow: auto;
    user-select: none;
    overflow-x: hidden;

    @include media(xs sm) {
        overflow: auto;
    }

    .renderer-area {
        position: relative;
        margin: auto;

        &.animate-rtl {
            @include shakeRtlAnimation;
        }

        &.draggable {
            .ql-editor {
                @include grabbing-cursor;
            }
        }
    }
}

.no-shadow {
    box-shadow: 0 0.8px 3px 1px rgba(128, 128, 128, 0.18);
}

.show-shadow {
    box-shadow: 0px 10px 32px rgba(62, 87, 85, 0.257293);

    @include media(ixs) {
        box-shadow: 0 0.8px 3px 1px rgba(128, 128, 128, 0.18);
    }
}

@-moz-document url-prefix() {
    .renderer-wrapper {
        box-shadow: none;
    }

    .no-shadow {
        box-shadow: none;
    }

    .show-shadow {
        box-shadow: none;
    }
}

#drag-area {
    position: relative;
    margin: auto;

    svg {
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        max-width: 100%;
        pointer-events: none;
    }

    .smart-break {
        margin: 0 !important;

        &:before {
            content: '\000A';
        }
    }

    .ql-container {
        height: auto;
    }

    .ql-editor {
        padding: 0;

    }

    .is-edit-exercise {
        cursor: pointer;

        &:active {
            cursor: pointer;
        }
    }

    .table {
        display: flex;
        flex-direction: column;
        font-family: 'Latin Roman', sans-serif !important;
        line-height: 120% !important;

        .tr {
            display: flex;
            flex-direction: row;
        }

        .td {
            overflow: hidden;
        }
    }

    .align-top,
    .align-center,
    .align-bottom {
        display: flex;
        flex-direction: column;

        .ql-editor {
            display: flex;
            flex-direction: column;
        }
    }

    .align-center {
        justify-content: center;

        .ql-editor {
            justify-content: center;
        }
    }

    .align-bottom {
        justify-content: flex-end;

        .ql-editor {
            justify-content: flex-end;
        }
    }

    >div {
        box-sizing: border-box;
        overflow: hidden;
        line-height: 1.42;
        outline: none;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .ql-container {
        height: auto;
        font-family: inherit;
        font-size: unset;
    }

    .ql-editor {
        font-family: inherit;
        line-height: unset;
        @include grabbing-cursor;

        &:active {
            @include grabbing-cursor;
        }

        ol,
        ul {
            padding-left: 0;
        }

        li:before {
            text-align: left;
            width: .3cm;
            margin: 0 0 0 -.3cm;
        }
    }

    a {
        text-decoration: underline;
        color: $blue;        
    }
}

.placeholder-card {
    margin: 0 auto;

    .content {
        background-color: white;
        box-shadow: 0 0.8px 3px 1px rgba(128, 128, 128, 0.18);
        border-radius: 10px;
        height: 100%;
    }
}

.flashcard-nav-button {
    @include round-button-with-shadow;
}

.switch-side-flashcard-button {
    bottom: 12px;
    width: calc(100% - 50%);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D9DFE4;
    border-radius: 100px;

    @include media(xs sm md) {
        bottom: 12px;
    }

    &:active {
        background: #378EF8;

        .flip-card {
            path {
                stroke: #FFFFFF;
            }

            .filp-path {
                fill: #FFFFFF;
            }
        }
    }
}

.markings-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    touch-action: none;
}

.canvas-container {
    width: 100% !important;
    height: 100% !important;
}

.flip-animation {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}