.round-btn {
    border: 1px solid rgb(225, 230, 230);
    border-radius: 50%;
    width: 44px;
    min-width: 44px;
    height: 44px;
    background: white;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 5px;
    box-shadow: rgba(62, 87, 85, 0.22) 0px 1px 3px 0px;
    outline: none;
    pointer-events: all;
}