@mixin checkboxPosition {
  position: absolute;
  top: calc(50% - 9px);
  left: 9px;
  height: 18px;
  width: 18px;
}

.form-checkbox-wrapper {
  margin-top: 22px;
  display: flex;

  .form-checkbox-container {
    width: 36px;
    position: relative;
  }

  .form-checkbox {
    opacity: 0;
    z-index: 1;
    @include checkboxPosition;
    cursor: pointer;

    & + .input-checkbox {
      background: transparent url('../../assets/images/checkbox/Inactive.svg') center/100% no-repeat;
    }

    &:checked + .input-checkbox {
      background: transparent url('../../assets/images/checkbox/Active.svg') center/100% no-repeat;
    }
  }

  .input-checkbox {
    @include checkboxPosition;
  }

  .form-label {
    width: calc(100% - 36px);
    font-size: 13px;
    color: #a2a3a3;
    line-height: 18px;
    min-height: 36px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    a {
      font-weight: 600;
      color: #2ebcb4;
      text-decoration: none;
    }
  }
}
