@import 'styles/variables';
@import 'styles/mixins';

.exercise-bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(135deg, $dark-orange 0%, $dark-pink 100%);
  right: 0;
}

.exercise-form {
  width: 100%;

  .form-input-wrapper {
    width: 100%;
    margin-top: 16px !important;

    .textarea {
      resize: vertical;
      box-sizing: border-box;
      padding-right: 28px;
      border-radius: 0;
    }
    #create-exercise-datepicker{
      height: 40px;
    }

  }

  .header-extension {
    background: $left-right-gradient;
    padding: 57px 20px 9px;
  }
  .form-label{
    vertical-align: top !important;
  }
  .form-input {
    margin-top: -3px !important;
    line-height: 34px;
  }
  #create-exercise-time{
    line-height: 35px;
  }
  #create-exercise-time-small{
    line-height: 35px;
    align-items: center;
    text-align-last: center;
  }
  

}


.exercise-input {
  background: transparent;
  border: none;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: bold;
  outline: none;
  color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  width: 100%;
  margin-right: 16px;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  height: 34px;

  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus,
  &:valid,
  &:not(:placeholder-shown) {
    color: white;
  }

}

.time-small{
  min-width: 20px;
}
.left-active-title{
  position: absolute;
  left: -14px;
  background: $blue;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  top: 0;
  bottom: 0;
  width: 4px;
}
.external-tile{
  box-shadow: 0px 0px 0px 1px $light-grey;
  border-radius: 10px;
  padding: 14px 14px 6px;
  cursor: pointer;
  user-select: none;
  
  // ${props => props.isActive && css`
  //   background: #E5ECF2;

  //   .bar-line-bg{
  //     background: white;
  //   }
      
  //   ${StyledArrow}:before {
  //     background: #e9ebeb;
  //   }
  // `}
  
  @media (pointer: fine) {
    &:hover {
      box-shadow: 0px 0px 0px 1px $grey-light-6;
      
      .bar-line-bg{
        background: white;
      }
    }
  }
  &:active{
    box-shadow: 0px 0px 0px 3px $blue;
  }
}
.external-tile-active{
  background: $grey-light-9;

  .bar-line-bg{
    background: white;
  }
}
.exercise-notifications{
  background: linear-gradient(135deg, rgb(252, 75, 7) 0%, rgb(252, 38, 102) 100%);
}