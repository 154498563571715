@import 'styles/variables';

.markings-menu {
  font-size: 17px;
  color: $charcoal;
  padding: 12px;
  min-width: 375px;
  position: relative;

  hr {
    height: 1px;
    margin: 0;
    background-color: #e3e6e6;
  }

  .icons-container {
    margin-top: 12px;
    overflow: hidden;

    .icon {
      cursor: pointer;
      margin-right: 24px;
      transform: translate(0, 50%);

      &.active {
        transform: translate(0, 10%);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .current-color {
    position: absolute;
    right: 12px;
    bottom: 20px;
  }

  .color-picker {
    overflow: hidden;
    padding-top: 13px;

    .color-button {
      margin-top: 10px;
    }
  }

  .current-tool {
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    transform: translate(0, 5px);
  }
}
