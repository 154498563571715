@import 'styles/variables';

.color-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #C8CCCC;

  &.active {
    border: 2px solid $turquoise-green;

    .inner {
      border-width: 1.5px;
    }
  }

  .inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
  }
}
