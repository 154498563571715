@import 'styles/_variables';

.subjects-changer {
  padding: 10px 0;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  background: #FFFFFF;

  .subjects-wrapper {
    padding: 13px 0 11px;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    .subject {
      min-width: 86px;
      &:first-child {
        padding-left: 9px;
        min-width: 95px;
      }
      &:last-child {
        padding-right: 9px;
        min-width: 95px;
      }
      .subject-icon {
        margin: 0 auto;
        width: 64px;
        height: 64px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 0 1px $grey-light-4;
        cursor: pointer;
      }

      .subject-title {
        padding-top: 10px;
        line-height: 16px;
        font-size: 13px;
        text-align: center;
        font-weight: 600;
        color: $grey-light-2;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }

      &.active {
        .subject-icon {
          box-shadow: 0 0 0 2px $turquoise;
          transform: translateZ(0);
        }

        .subject-title {
          color: $turquoise;
        }
      }
    }
  }

  .teaching-levels-wrapper {
    padding: 10px 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &.display-none {
      display: none;
    }

    &::after {
      content: "";
      flex: 0 0 1px;
    }

    .teaching-level {
      text-align: center;
      padding: 8px 20px 8px 20px;
      color: $grey-light-2;
      border-radius: 30px;
      font-size: 13px;
      margin: 0 11px;
      white-space: nowrap;
      font-weight: 600;
      box-shadow: 0 0 0 1px $grey-light;

      &.active {
        color: $turquoise;
        box-shadow: 0 0 0 2px $turquoise;
      }

      &:first-of-type {
        margin-left: 20px;
      }

      &:last-of-type {
        margin-right: 20px;
      }
    }
  }
}
