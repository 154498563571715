@import "styles/_mixins";

#subscriptions-page {
  height: 100%;
  padding-bottom: 80px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: white;

  @include media(xs sm) {
    height: 100%;
    -webkit-overflow-scrolling: unset;
  }

  .my-subjects-header-links-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 14px 14px 17px;

    &.shadow {
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.1));
    }

    @include media-orientation(landscape) {
      @include media(xs sm) {
        display: none;
      }
    }

    @include media(md lg xl) {
      width: 375px;
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
  }
  
  .subject-item {
    .subject-icon {
      width: 48px;
      height: 48px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 0 1px #dfe2e2;
      //margin-top: 5px;
      img {
        width: 30px;
        height: 30px;

        &.full {
          width: 100%;
          height: auto;
        }
      }
    }

    .subject-info {
      padding-left: 12px;
    }
    .from-school {
      font-weight: normal;
      font-size: 11px;
      line-height: 15px;
      color: #FFFFFF;
      margin-left: 8px;
      background: #378EF8;
      border-radius: 4px;
      padding: 2px 4px;
    }

    .subject-title {
      font-size: 15px;
      font-weight: bold;
      line-height: 19px;
      text-align: left;
      color: #2d3838;
    }

    .subscription {
      padding-top: 4px;
      font-size: 15px;
      line-height: 1.27;
      text-align: left;
      color: #2d3838;
    }

    .bought-date,
    .remaining-days {
      display: flex;
    }

    .bought-date {
      padding-top: 10px;
      height: 31px;
    }

    .remaining-days {
      padding-top: 5px;
      height: 26px;
    }

    .bought-date-title,
    .remaining-days-title {
      font-size: 15px;
      line-height: 1.27;
      text-align: left;
      color: #919191;
      padding-left: 10px;
    }
    
  }
  .plan-bottom-line:last-child{
    display: none;
  }
}
