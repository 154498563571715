@import "styles/mixins";
@import 'styles/variables';

.edit-folder-page {
  // padding-top: 49px;
  // margin-top: -56px;
  // background: $gradient-top-bottom;
  // padding-left: 20px;
  // padding-right: 20px;

  background: linear-gradient(225deg,#43daf0,#395bf8);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 49px;

  &.fixed-dimensions {
    width: 375px;
    height: 100%;
    border-radius: 6px;
  }

  @include media(xs sm) {
    height: 101vh;
  }

  .input-container {
    font-size: 25px;

    input::placeholder {
      color: rgba(#FFFFFF, .5);
    }
  }

  input {
    color: white;
    background-color: initial;
    border: none;
    font-size: 25px;
    font-weight: bold;
    outline: none;
    padding: 0;
    height: 45px;
    display: block;
    max-height: 45px;
    margin: 0 16px 0 0;
    letter-spacing: normal;
    font-family: "Open Sans";
    user-select: text;
  }
}
.edit-current-folder-page {
  padding-top: 52px;
  background: linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
  position: relative;
  margin-top: -56px;
  padding: 53px 20px 4px;
  &.fixed-dimensions {
    width: 375px;
    height: 100%;
    border-radius: 6px;
  }

  .input-container {
    font-size: 25px;
    input::placeholder {
      color: rgba(#FFFFFF, .5);
    }
  }

  input {
    color: white;
    background-color: transparent;
    border: none;
    font-size: 25px;
    font-weight: bold;
    outline: none;
    padding: 0;
    display: block;
    max-height: 45px;
    margin: 0 16px 0 0;
    letter-spacing: normal;
    font-family: "Open Sans";
  }
}