@import 'styles/_mixins';
@import 'styles/variables';

.marked-as-read {
    @media (max-width: 991px) {
        @media(orientation: landscape) {
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            bottom: 72px;
            position: absolute;
            padding: 10px 3px;

            svg {
                transform: rotate(90deg);
                margin-bottom: 6px;
            }
        }
    }

    svg {
        path {
            stroke: $white;
        }
    }

    @media (pointer: fine) {
        &:hover {
            background: $light-green3;
            cursor: pointer;
        }
    }

    &:active {
        color: $white;
        background: $dark-green2;

        svg {
            path {
                stroke: $white;
            }
        }
    }
}

.mark-as-read {
    box-shadow: 0 0 0 1px $bx-grey;

    @media (max-width: 991px) {
        @media(orientation: landscape) {
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            bottom: 72px;
            position: absolute;
            padding: 10px 3px;

            svg {
                transform: rotate(90deg);
                margin-bottom: 6px;
            }
        }
    }

    @media (pointer: fine) {
        &:hover {
            background: $white;
            box-shadow: 0 0 0 1px $bx-hover-grey;
        }
    }

    &:active {
        color: $white;
        box-shadow: none;
        background: $blue;

        svg {
            path {
                stroke: $white;
            }
        }
    }
}

.mark-text {
    @media (max-width: 991px) {
        @media(orientation: landscape) {
            margin-left: 0;
            text-orientation: mixed;
            writing-mode: vertical-rl;
        }
    }
}