@import 'styles/_mixins';
@import 'styles/variables';

.default-checkbox {
  border: 1px solid $bx-hover-grey;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.active-checkbox{
  background:linear-gradient(-133deg, #51D378 0%, #51D4B3 100%);
  border: 0
}