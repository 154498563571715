@import 'styles/mixins';
@import "styles/_variables";

.studying-location-container {
  .studing-teaching-tag-mobile {
    display: flex;
    @include media(xs sm) {
      @media(orientation: landscape){
        display: none;
      }
    }
  }

  .numbering {
    box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
    @media (max-width: 991px) {
      @media(orientation: landscape){
        margin-right: 0;
        margin-bottom: 11px;
        padding: 8px 0;
        height: unset;
        width: 20px;
      }
    }
  }

  .title {
    
    &.isTitleEllipsed {
      @media(orientation: portrait){
        width: unset !important;
      }
    }

    &.isExternalExercise {
      @media(orientation: portrait){
        width: unset !important;
      }
    }

    @media (max-width: 991px) {
      @media(orientation: landscape){
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
        content: "";
        position:relative;
      }
      @media(orientation: portrait){
        text-overflow: clip;
        text-align: left;
        content: "";
        overflow: hidden;
        text-overflow: clip;
        text-align: left;
        content: "";
        white-space: nowrap;
        writing-mode: horizontal-tb;
        position: relative;
        white-space: nowrap;
        content: "";
      }
    }
  }

  .hidden-info {
    @media(pointer: fine) {
      &:hover{
          opacity: 75;
      }
    }
  }

  .empty-screen-landscape {
    @media(orientation: landscape){
      width:20px;
    }
  }

  @media (max-width: 991px) {
    @media(orientation: landscape){
      white-space: nowrap;
      overflow: hidden;
      min-height: 0;
      max-height: inherit;
      padding: 10px 0 16px 0;
      width: 34px;
      white-space: nowrap;
      height: unset;
      writing-mode: tb-rl;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      content: "";
      margin-bottom: 8px;
    }

    @media(orientation: portrait){
      overflow: hidden;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      content: "";
    }
  }
}