@import "styles/variables";
@import "styles/mixins";

.navigation-header {
  position: absolute;
  right: 0;
  padding: 14px 20px;
  background-color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  height: $navigation-header-height;
  justify-content: space-between;

  &.is-not-link-page {
    left: 0;
  }

  &.inverted {
    background: $gradient-left-right;
  }

  &.search-inverted {
    background: $gradient-search-left-right;
  }

  &.shadow {
    box-shadow: 0px 1px 0px #E3E6E6;
    @include media-orientation(landscape) {
      @include media(xs sm) {
        box-shadow: none;
      }
    }
  }

  &.extended-width {
    padding-left: 12px;
  }

  &.contained {
    position: absolute;
    top: 0;
  }

  &.is-support-page {
    margin-left: 12px;

    @include media(xs sm) {
      margin-left: 0;
    }
  }


  &.extended-height {
    box-shadow: 0px 1px 0px #E3E6E6;
    display: block;
    height: auto;
    &.is-support-page {
      height: 49px;
    }
    &.has-two-teaching-levels {
      padding-bottom: 12px;

    }

    @include media-orientation(landscape) {
      @include media(xs sm) {
        &.contained {
          position: fixed;
        }
      }
    }

    &.is-search {
      box-shadow: none;
    }

  }

  @include media(md lg xl) {
    width: 375px;
  }

  @include media(iPad) {
    width: 375px;
  }

  @include media(iPadAir) {
    @include media-orientation(portrait) {
      width: 375px;
    }
  }

  .right-section {
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .right-section-info-icons {
    cursor: pointer;
    padding: 7px;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    right: 13px;

    @media (pointer: fine) {
      &:hover {
        background-color: #EFF4F9;
      }
    }

    &:active {
      background: #E5ECF2;
    }

  }

  .small-title {
    position: absolute;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    display: none;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 50%;
    line-height: 1.2;

    &.visible {
      display: flex;
      justify-content: center;

      @include media(xs sm) {
        align-items: center;
        @include media-orientation(landscape) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 45%;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 20%;
            height: 100%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 0%, #FFFFFF 58.71%);

          }
        }
      }
    }

    &.inverted {
      color: white;

      &.visible {
        &.invisible-menu {
          left: 40%;
        }

        bottom: initial;
      }
    }

    &.search-inverted {
      color: white;

      &.visible {
        bottom: initial;
      }
    }
  }

  &.large {
    align-items: flex-start;

    .small-title.visible {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: 50%;
      .inactive-bookmark {
        path {
          stroke: #A2A3A3 !important;
        }
      }

      .active-bookmark {
        path {
          stroke: #378EF8 !important;
          fill: #378EF8 !important;
        }
      }
    }
  }

  @include media-orientation(landscape) {
    @include media(xs sm) {
      width: 375px;
      &.large {
        margin-left: 0;
        position: absolute;
      }
      &.contained {
        display: flex;
      }
    }
  }

  .back-button {
    margin-left: 0px;
  }

}

svg.gear-icon {
  cursor: pointer;

  @media (pointer: fine) {
    &:hover {
      path#Path {
        stroke: #44CAC3;
      }
    }
  }

  &:active {
    path#Path {
      stroke: #3DA898;
    }
  }
}

svg.gear-white-icon {
  cursor: pointer;

  @media (pointer: fine) {
    &:hover {
      path#Path {
        opacity: 0.75;
      }
    }
  }

  &:active {
    path#Path {
      stroke: #0A7670;
    }
  }
}
