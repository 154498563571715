@import 'styles/_mixins';
@import 'styles/variables';
@import 'styles/animations';

.flashcard-navigation {
  &.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: -100px;
    @include overlayFadeIn;
    z-index: 3;

    @include media(md lg xl) {
      width: 375px;
    }
  }
}

.flashcard-nav-route-wrapper {
  height: 100%;
  overflow-y: scroll;
}
