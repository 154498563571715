@import 'styles/_mixins';
@import 'styles/_animations';

.pop-up {
  width: 100%;
  background: white;
  border-radius: 15px;
  max-width: 487px;
  z-index: 2;
  overflow: hidden;
  max-height: calc(100% - 32px); 
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
}

@media only screen and (max-width: 768px) {
  .pop-up {
    max-height: 100% !important;
    margin: 0;
    height: 100% !important;
    border-radius: 0;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
  }
}

.pop-up-header-big {
  transition-property: position;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
  width: 100%;
  position: absolute;
  background-color: white;
}

.pop-up-header-big-none{
  display: none;
}

.pop-up-close-icon-box {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}
 
.pop-up-close-icon{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pop-up-image{
  width: 100%;
  max-height: 216px;
  margin-bottom: -20px;
}

.pop-up-image-url {
  width: 100%;
  max-height: 216px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

@media only screen and (max-width: 768px) {
  .pop-up-image-url {
    border-radius: 0;
  }
}

.pop-up-main-container {
  overflow: auto;
  border-radius: 15px;
 }

@media only screen and (max-width: 768px) {
  .pop-up-main-container {
    border-radius: 0;
  }
} 

::-webkit-scrollbar {
  display:none;
}

.pop-up-container1 {
  padding: 10%;
  padding-top: 50px;
  overflow: auto;
  padding-bottom: 0;
}

.pop-up-title2 {
  font-size: 27px;
  font-weight: bold;
  line-height: 1.1;
  padding-bottom: 20px; 
}

.pop-up-desc {
    font-size: 14px;
    padding-bottom: 20px;
    text-align: left;
    line-height: 18px; 
}
  
.pop-up-feature-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
  
.pop-up-feature-icon {
    width: 34px;
    height: 34px;
    background: transparent;
    border-radius: 50%;
    min-width: 34px;
    margin-right: 10px; 
}

.feature-icon-image {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.pop-up-feature-box {
  display: flex;
  padding-bottom: 10px;
}

.pop-up-feature-title {
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 5px;
  padding-top: 5px;
}

.pop-up-feature-desc {
  font-size: 13px;
  line-height: 18px;
}

.pop-up-footer {
  display: flex;
  align-items: center;
  position: sticky;
  background: white;
  padding: 10%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; 
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 0;
}

.pop-up-cta {
  display: flex;
}

.pop-up-cta-button {
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 15px;
  color: $blue;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: bold;
  box-shadow: 0 0 0 1px $bx-grey;
  background: $white;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
}

@media (pointer: fine) {
  .pop-up-cta-button:hover {
    box-shadow: 0 0 0 1px $bx-hover-grey; 
  }
}

.pop-up-cta-button:active {
  box-shadow: none;
  background: $blue;
  color: $white;
}