@import 'styles/_mixins';
@import 'styles/variables';

.default-checkbox{
  border: 1px solid $bx-hover-grey;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.active-checkbox{
  border: 0;
  background-image: linear-gradient(-133deg, $light-green1 0%, $light-green2 100%);
}

.partial-checkbox{
  border: 3px solid $light-green1;
}