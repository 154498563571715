@import 'styles/variables';
@import 'styles/mixins';

.answer-statistics {

  border-radius: 21px;
  flex-grow: 1;
  margin-bottom: 1rem;

  @include media(vsm) {
    margin-bottom: 1.2rem;
  }

  svg {
    width: 26px;
    height: 26px;
  }

  .answer-inner-container {
    border: 1px solid #E3E6E6;
    box-sizing: border-box;
    border-radius: 22px;
    padding: 4px;
    height: 140px;

    width: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .answer-line {
      flex-grow: unset;
    }
  }

  .answer-assesstment {
    display: flex;
    align-items: center;

    @include media(vsm) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .answer-line {

    flex-grow: 1;
    height: 36px;
    width: 36px;
    display: inline-block;
    background: #D9DFE4;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.correct {
      background: linear-gradient(120deg, #51D378, #51D4B3);
    }

    &.wrong {
      background: linear-gradient(120deg, #FC4B07, #FC1691);
    }

  }

  .answer-lines-container {
    flex-grow: 1;
    align-items: center;
    padding: 0 4px;
    margin-top: 10px;
  }

  .description {
    @media(max-width: 480px){
      @media(orientation: portrait){
        position: absolute;
        bottom: 0;
        margin-left: 0;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }

}

.hide-animation {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.show-animation {
  opacity: 1;
}

.exam-show-animation {
  opacity: 1;
}

.fc-assessment {
  user-select: none;

  &.svg-color {
    svg {
      path:first-child {
        stroke: $white;
      }

      path:not(:first-child) {
        fill: $white;
      }

      rect {
        fill: $white;
      }
    }
  }

  @media (pointer: fine) {
    &:hover {
      background: #C6CED4;
      box-shadow: none;

      svg {
        path:first-child {
          stroke: #FFFFFF;
        }

        path:not(:first-child) {
          fill: #FFFFFF;
        }
      }
    }
  }

  &:active {
    &.unconfident {
      background: #FA9000;
      box-shadow: 0px 1px 3px #FA9000;

      svg {
        fill: $white;

        path:first-child {
          stroke: #FA9000;
        }

        path:not(:first-child) {
          fill: #FA9000;
        }
      }
    }

    &.neither {
      background: #A1ACB3;
      box-shadow: 0px 1px 3px #A1ACB3;

      svg {
        fill: $white;

        path:first-child {
          stroke: #A1ACB3;
        }

        path:not(:first-child) {
          fill: #A1ACB3;
        }

        rect {
          fill: #A1ACB3;
        }
      }
    }

    &.confident {
      background: #3EBF8F;
      box-shadow: 0px 1px 3px #3EBF8F;

      svg {
        fill: $white;

        path:first-child {
          stroke: #3EBF8F;
        }

        path:not(:first-child) {
          fill: #3EBF8F;
        }
      }
    }
  }

  &.active {
    &.unconfident {
      background: #FA9000;
      box-shadow: 0px 1px 3px #FA9000;

      @media (pointer: fine) {
        &:hover {
          background: #FBAE45;
          box-shadow: 0px 1px 3px #FBAE45;

          svg {
            path:first-child {
              stroke: #FBAE45;
            }

            path:not(:first-child) {
              fill: #FBAE45;
            }
          }
        }
      }

      &:active {
        background: #D8820D;
        box-shadow: 0px 1px 3px #D8820D;

        svg {
          path:first-child {
            stroke: #D8820D;
          }

          path:not(:first-child) {
            fill: #D8820D;
          }
        }
      }
    }

    &.neither {
      background: #A1ACB3;
      box-shadow: 0px 1px 3px #A1ACB3;

      @media (pointer: fine) {
        &:hover {
          background: #B5BFC5;
          box-shadow: 0px 1px 3px #B5BFC5;

          svg {
            path:first-child {
              stroke: #B5BFC5;
            }

            path:not(:first-child) {
              fill: #B5BFC5;
            }
          }
        }
      }

      &:active {
        background: #8B969D;
        box-shadow: 0px 1px 3px #8B969D;

        svg {
          path:first-child {
            stroke: #8B969D;
          }

          path:not(:first-child) {
            fill: #8B969D;
          }
        }
      }
    }

    &.confident {
      background: #3EBF8F;
      box-shadow: 0px 1px 3px #3EBF8F;

      @media (pointer: fine) {
        &:hover {
          background: #37D59A;
          box-shadow: 0px 1px 3px #37D59A;

          svg {
            path:first-child {
              stroke: #37D59A;
            }

            path:not(:first-child) {
              fill: #37D59A;
            }
          }
        }
      }

      &:active {
        background: #21AA77;
        box-shadow: 0px 1px 3px #21AA77;

        svg {
          path:first-child {
            stroke: #21AA77;
          }

          path:not(:first-child) {
            fill: #21AA77;
          }
        }
      }
    }

    svg {
      fill: $white;
    }
  }

  &.not-answered {
    &.unconfident {
      @media (pointer: fine) {
        &:hover {
          background: #C6CED4;
          box-shadow: none;

          svg {
            path:first-child {
              stroke: #FA9000;
            }

            path:not(:first-child) {
              fill: #FA9000;
            }
          }
        }
      }

      &:active {
        background: #FA9000;
        box-shadow: 0px 1px 3px #FA9000;

        svg {
          fill: $white;

          path:first-child {
            stroke: #FA9000;
          }

          path:not(:first-child) {
            fill: #FA9000;
          }
        }
      }
    }

    &.neither {
      @media (pointer: fine) {
        &:hover {
          background: #C6CED4;
          box-shadow: none;

          svg {
            path:first-child {
              stroke: #A1ACB3;
            }

            path:not(:first-child) {
              fill: #A1ACB3;
            }

            rect {
              fill: #A1ACB3;
            }
          }
        }
      }

      &:active {
        background: #A1ACB3;
        box-shadow: 0px 1px 3px #A1ACB3;

        svg {
          fill: $white;

          path:first-child {
            stroke: #A1ACB3;
          }

          path:not(:first-child) {
            fill: #A1ACB3;
          }

          rect {
            fill: #A1ACB3;
          }
        }
      }
    }

    &.confident {
      @media (pointer: fine) {
        &:hover {
          background: #C6CED4;
          box-shadow: none;

          svg {
            path:first-child {
              stroke: #3EBF8F;
            }

            path:not(:first-child) {
              fill: #3EBF8F;
            }
          }
        }
      }

      &:active {
        background: #3EBF8F;
        box-shadow: 0px 1px 3px #3EBF8F;

        svg {
          path:first-child {
            stroke: #3EBF8F;
          }

          path:not(:first-child) {
            fill: #3EBF8F;
          }
        }
      }
    }
  }

  &.svg-color {
    &.unconfident {
      @media (pointer: fine) {
        &:hover {
          background: #C6CED4;
          box-shadow: none;
        }
      }

      &:active {
        background: $light-orange;
        box-shadow: 0px 1px 3px $light-orange;

        svg {
          fill: $white;

          path {
            fill: $light-orange !important;
          }
          path:first-child {
            stroke: $light-orange !important;
            fill: $white  !important;
          }
        }
      }
    }

    &.neither {
      @media (pointer: fine) {
        &:hover {
          background: $hover-grey;
          box-shadow: none;
        }
      }

      &:active {
        background: $light-grey2;
        box-shadow: 0px 1px 3px $light-grey2;

        svg {
          rect {
            fill: $light-grey2;
          }

          fill: $white;

          path {
            fill: $light-grey2 !important;
          }
          path:first-child {
            stroke: $light-grey2 !important;
            fill: $white  !important;
          }
        }
      }
    }

    &.confident {
      @media (pointer: fine) {
        &:hover {
          background: $hover-grey;
          box-shadow: none;
        }
      }

      &:active {
        background: $dark-green2;
        box-shadow: 0px 1px 3px $dark-green2;

        svg {

          fill: $white;

          path {
            fill: $dark-green2 !important;
          }
          path:first-child {
            stroke: $dark-green2 !important;
            fill: $white  !important;
          }
        }
      }
    }
  }
}


.answer-statistics-content{
  display: none !important;
  position: absolute;
  top: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .flashcard-nr{
    min-width: 43px;
    background: #FFFFFF;
    border-radius: 18px;
    margin-right: 8px;
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    color: #2D3838;
    padding: 5px 16px;
  }
  &.viewport-mobile{
    display: block !important;
    position: absolute;
    bottom: 12px;
    top: unset;
    @media(orientation: landscape){
      height: 48px;
      display: flex !important;
      justify-content: flex-end;
      flex-direction: column;
    }
    .flashcard-nr{
      background: none;
      margin-right: 0px;
      font-weight: 600;
      font-size: 17px;
      text-align: center;
      justify-content: center;
      @media(orientation: landscape){
        padding: 0;
        margin-top: 5px;
        color: #2D3838;
        font-size: 15px;
        line-height: 19px;
        font-weight: bold;
        
      }
      &.video-text-white{
        color: #FFFFFF;
      }
    }
  }
}