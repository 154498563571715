.back-button {
  cursor: pointer;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  color: #378EF8;
  transition: all .2s;
  align-items: center;
  // margin-left: -8px;
  //display: inline-flex;
  
  .back-title {
    font-weight: normal;
    font-size: 13px;
    //line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: "Open Sans";
    
    @media (pointer: fine) {
      &:hover {
        color: #64A8FA;
      }
    }
    &:active {
      color: #2170CF; 
    }
    &.inverted {
      color: #FFFFFF;
      @media (pointer: fine) {
        &:hover {
          opacity: 0.75;
        }
      }
      &:active {
        color: #2170CF; 
      }
    }
    &.no-padding {
      padding-left: 0;
      font-weight: normal;
    }
  }
  @media (pointer: fine) {
    &:hover {
      color: #64A8FA;
      
      path#arrow-back-path-2{
        stroke: #64A8FA;
      }
      path#arrow-back-path-1 {
        fill: #64A8FA;
      }
    }
  }
  &:active { 
      color: #2170CF;
      .back-title {
        color: #2170CF; 
      }
      path#arrow-back-path-2{
        stroke: #2170CF;
      }
      path#arrow-back-path-1 {
        fill: #2170CF;
      }
  }
  &.inverted {
    color: #FFFFFF;
    @media (pointer: fine) {
      &:hover {
        opacity: 0.75;
      }
    }
    &:active {
      .back-title {
        color: #2170CF; 
      }
      svg polyline{
        stroke: #2170CF;
      }
    }
  }
}
