@import "styles/variables";
@import "styles/mixins";

.note-editor-container {
  height: 100%;
  padding-top: $navigation-header-height;
}

.delete-icon-note {
  cursor: pointer;
}

.done-button {
  margin-right: 14px;
  color: $orange;
  &.focused {
    > .simple-button {
      color: $orange;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;

      text-align: right;
      letter-spacing: 2px;
      text-transform: uppercase;
      @media (pointer: fine) {
        &:hover {
          color: $orange-hover;
        }
      }
      &:active {
        color: $orange-active;
      }

    }
  }
}

.edit-note-buttons-container {
  display: flex;
  align-items: center;

  .delete-icon-note {
    padding: 6px;
    border-radius: 50%;
    user-select: none;
    cursor: pointer;
    margin-right: -6px;
    @media (pointer: fine) {
      &:hover {
        background: $grey-light-8;
        svg {
          path {
            opacity: 1 !important;
          }
        }
      }
    }

    &:active {
      border: none;
      background: $grey-light-9;
      svg {
        path {
          opacity: 1 !important;
        }
      }
    }
  }
}
.note-editor {
  //border-top: 1px solid #E1E6E6;
  height: 100%;
  padding: 12px 20px;
  line-height: normal;
  background: $white;
  overflow: overlay;
  position: relative;
  @include media-orientation(landscape) {
    @include media(xs sm) {
      border-top: none;
    }
  }
  .editor-focused-marker {
    position: absolute;
    left: -4px;
    background: $orange;
    border-radius: 20px;
    top: 12px;
    bottom: 12px;
    width: 8px;
  }

  textarea {
    height: 100%;
    background: unset;
    border: none;
    width: 100%;
    resize: none;
    padding: 0;
    font-family: "Open Sans";
    font-size: 15px;
    color: $grey-light-11;
    text-shadow: 0 -0.2px 0.2px rgba(75, 77, 77, 0.39), 0 -0.1px 0 $white;
    outline: none;
    -webkit-appearance: none;
    border-radius: 0;
    @supports (-webkit-overflow-scrolling: touch) {
      /* CSS specific to iOS devices */
      margin-left: -3px;
    }

    @supports not (-webkit-overflow-scrolling: touch) {
      /* CSS for other than iOS devices */
    }
  }

  textarea::placeholder {
    font-size: 15px;
    line-height: 1.27;
    text-align: left;
    color: $grey-light-10;
    text-shadow: 0 -0.2px 0.2px rgba(75, 77, 77, 0.39), 0 -0.1px 0 $white;
  }
}
