@import "styles/mixins";

#desk-header {
  background: white;
  padding: 0 2px;
  .align-icons {
    display: flex;
    align-items: center;
  }

  .edit-icon {
    margin-left: 18px;
  }

  .note-icon {
    @include media(xs sm) {
      display: none;
    }
  }

  .title-container {
    padding: 65px 0 14px;
    display: flex;
    justify-content: space-between;
  }
  .profile-picture {
    width: 40px;
    height: 40px;
    font-weight: bold;
    font-size: 20px;
  }

  .menu-item {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 12px 20px 13px 14px;
    z-index: 1;
    background-color: white;
    &.align-baseline {
      align-items: baseline;
    }
    @include media(md lg xl) {
      width: 375px;
    }
  }
  .cancel-title {
    font-size: 17px;
    line-height: 20px;
    font-family: "Open Sans";
    color: #2ebcb4;
    cursor: pointer;
  }
  .cursor-pointer {
    cursor: pointer;
  }
}
