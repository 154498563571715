.flickity-enabled {
    outline: none;
    position: relative;
    min-height: 97px;
}
.flickity-viewport {
    position: relative;
    height: 100%;
  }
  
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
   
  }
.flickity-button {
    position: absolute;
    border: none;
    color: #333;
    outline: none;
    padding: 5px 12px 7px 11px;
    border: 1px solid #DDE3E3;
    &.previous{
        // transform: translate(-63%);
    }
    &.next{
      // transform: translate(63%);
    }
    &:hover{
        cursor: pointer;
    }
    &:disabled{
        display: none!important;
    }
    &:active:hover {
      & svg{
        background: #378EF8;
        fill: #ffffff;
      }
      background: #378EF8;
      border: 1px solid #378EF8;
    }
    @media (pointer: fine) {	
      &:hover{
        border: 1px solid #BEC8C6;
      }
    }
  }
  
  .flickity-button-icon {
    fill: #468ef8;
  }
  
  /* ---- previous/next buttons ---- */
  
  .flickity-prev-next-button {
    top: 16px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.3);
    background: #ffffff;
    &.previous {
        left: -19px;
    }
    &.next {
        right: -18px;
    }
    &.flickity-button-icon {
        width: 7px;
        height: 12px;
      }
  }
  .flickity-page-dots {
    width: 100%;
    position: absolute;
    padding: 10px 0 0 0;
    margin: 0;
    list-style: none;
    text-align: center;
    .dot{
        display: inline-block;
        width: 10px;
        height: 10px;
        &:first-child{
          margin-left: 0px;
        }
        margin-left: 8px;
        background: #C6CED4;
        border-radius: 50%;
        cursor: pointer;
        &.is-selected {
            background: #378EF8;
        }
    }
}

  
.single-slider-container{
  padding-top: 16px;
}