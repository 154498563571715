@import 'styles/variables';

.practice-button {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 2px;
}

.fixedpagespacing {
    padding-bottom: 150px;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    overflow: overlay;
}

.exam-teaching-level {
    padding: 15px 0;
}

.exam-text {
    font-size: 25px;
    line-height: 34px;
}

.exam-list-header {
    display: flex;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.exam-preview-list-item {
    @media (pointer: fine) {
        &:hover {
            background: $grey-light-8;
        }
    }

    .exams-container {
        word-break: break-word;
    }

    .id-number {
        font-size: 15px;
        line-height: 19px;
    }

    .source-icon {
        margin-right: 8.06px;
        padding-bottom: 1px;

        svg {
            display: block;
            width: 18px;
            height: 18px;
        }
    }

    .exam-marks-info {
        font-size: 13px;
        color: $exam-q-info-color;
        line-height: 16px;
    }

    .exams-section {
        margin-top: 9px;
    }

    .mark-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        webkit-box-orient: vertical;
        -webkit-box-align: center;
        .exam-mark {
            font-size: 15px;
            font-family: "Open Sans";
            line-height: 19px;

            color: $exam-mark-text-color;
            word-break: break-word;
            max-width: 262px;
        }
    }

    .right-section {
        padding-left: 13px;
    }
}

.paper-card-wrapper {
    margin-bottom: 93px;

    .paper-card {
        background: $gradient-top-bottom;
    }

    .paperbar-option {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 18px;

        .paperbar-button {
            border-radius: 18px;
            padding: 9px 18px;
            line-height: 15px;

            &.active {
                box-shadow: 0px 6px 20px rgba(30, 76, 172, 0.514423);
            }
        }
    }
}

.paper-desc-wrapper {
    width: calc(100% - 40px);

    .style-metainfo-tile {
        background: $white;
        box-shadow: rgb(62 87 85 / 20%) 0px 6px 20px;
        border-radius: 10px;
        .microfilter-container {
            width: fit-content;
        }
        .exams-filter {
            border-radius: 18px;
            box-shadow: 0 0 0 2px $blue;
            background: $light-blue;
            width: fit-content;

            @media (pointer: fine) {
                &:hover {
                    box-shadow: 0 0 0 2px $hover-blue;
                }
            }
            color: $charcoal;
            .teaching-lvl-wrapper {
                padding-top: 9px;
                padding-bottom: 9px;
            }
            .teaching-lvl-active {
                padding: 9px 4px 9px 16px;
            }
            svg {
                margin: 8px 6px 8px 0px;
            }

            &.tl-exams-overlay-visible {
                box-shadow: 0 0 0 2px $blue;
                background: $light-blue;
                
                .micro-arrow {
                    margin: 8px 12px 8px 6px !important;
                    transform: rotate(180deg);
                    transition-duration: 0.3s;
                }

                @media (pointer: fine) {
                    &:hover {
                        box-shadow: 0 0 0 2px $hover-blue;
                    }
                }
            }

            &.tl-exams-overlay-not-visible {
                box-shadow: 0 0 0 1px $bx-grey;
                background: $white;

                .micro-arrow {
                    margin: 8px 12px 8px 6px !important;
                    transform: rotate(360deg);
                    transition-duration: 0.3s;
                }

                @media (pointer: fine) {
                    &:hover {
                        box-shadow: 0 0 0 1px $bx-hover-grey;
                    }
                }
            }

            &:active {
                background: $blue;
                color: $white;
                box-shadow: 0 0 0 2px $blue !important;

                svg {
                    circle {
                        fill: $white;
                    }

                    path {
                        &:first-child {
                            fill: $white !important;
                        }

                        fill: $blue !important;
                    }
                }

                .closeButton1 {
                    height: 36px;
                    width: 36px;
                    display: flex;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    z-index: 999;
                    box-shadow: none;
                    background: $blue;

                    svg {
                        margin: 0;
                    }

                    circle {
                        fill: $white;
                    }

                    path {
                        fill: $blue !important;
                    }
                }
            }

            .closeButton1 {
                height: 36px;
                width: 36px;
                display: flex;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                z-index: 7;

                &:active {
                    z-index: 999;
                    box-shadow: none;
                    background: $blue;

                    circle {
                        fill: $white;
                    }

                    path {
                        fill: $blue !important;
                    }
                }
            }
        }

        .filter-clear {
            svg {
                margin: 8px !important;
                cursor: unset;

                circle {
                    stroke: unset !important;
                }
            }

            &:active {
                box-shadow: none;
                background: $blue;

                circle {
                    fill: $white;
                }

                path {
                    fill: $blue !important;
                }
            }
        }
    }
}

.inner-skeleton-exam {
    background-size: 400px 100% !important;
    margin-bottom: 4px;
    border-radius: 10px !important;
    height: 54px;
    animation: animation-bzdot9 2s linear infinite !important;
}

.inner-skeleton-d-exam {
    background-size: 400px 100% !important;
    margin-top: 12px;
    border-radius: 10px !important;
    height: 24px;
    animation: animation-bzdot9 2s linear infinite !important;
}