@import 'styles/variables';
@import 'styles/_mixins';

.teaching-header-wrapper{
    border-radius: 5px;
    user-select: none;
    color: white;
    font-weight: normal;
    font-size: 13px; 
    line-height: 18px;
    min-width: 30px;
    text-transform: initial;
    @include media(smallPhone) { 
        font-size: 10px;
        width: 23px;
    }
}
.teaching-header-exam{
    width: 22px;
    min-width: 21px !important;
    height: 16px;
    border-radius: 4px;
    font-size: 10px !important; 
    line-height: 14px !important;
}
.teaching-level-active-topic{
    max-width: 70px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 20px;
      background: linear-gradient(to right,rgba(255,255,255,0.0001) 0%,#EFF4F9 162.71%);
      height:22px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
}