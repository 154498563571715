@import 'styles/_mixins';
@import 'styles/variables';

.key-name{
    background-image: $gradient-top-bottom;
    box-shadow: 0 3.5px 0 0 $color-dark-blue;
    border-radius: 8px;
    font-size: 13px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
}