@import 'styles/mixins';
@import 'styles/variables';

@include media(lg xl) {
    .study-location-info-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@include media(xs sm) {
    @media(orientation: landscape){
        .study-location-info-wrapper {
            margin: 0 !important;
            padding: 0 !important;
            height: calc(100vh - 16px);
            box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
            width: 48px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 0px 10px 10px 0px;
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}
.study-location-info-wrapper {
    margin: 5.5px 0;
    padding: 0 14px 0 20px;
    justify-content: space-between;
    align-items: center;

    .items-wrapper {
        @media (max-width: 991px) {
            @media(orientation: landscape) {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: flex-start;
                height: auto;
                margin: 0;
                position: relative;
                justify-content: space-between;
                
            }

            @media(orientation: landscape) {
                width: calc(100% - 148px);
                justify-content: space-between;
            }
        }
    }

    .title-section {
        @media (min-width: 600px) and (max-width: 1111px) and (orientation: portrait) {
            width: unset !important;
        }

        @media (max-width: 991px) {
            @media(orientation: landscape) {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
            }

            @media(orientation: portrait) {
                max-width: 100%;
                display: inline-flex;
                flex-direction: column;
                justify-content: space-between;
                overflow: auto;
            }
        }

        &.is-exercise {
            @media (orientation: portrait) {
                width: 100%;
            }
        }

        &.filter {
            @media (max-width: 991px) {
                @media (orientation: landscape) {
                    height: calc(100vh - 142px);
                }
            }
        }

        .ex-location-wrapper {
            @media (max-width: 991px) {
                @media(orientation: landscape) {
                    white-space: nowrap;
                    height: 100%;
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 8px;
                    flex-direction: column;
                }

                @media(orientation: portrait) {
                    max-width: 100%;
                    justify-content: center;
                    overflow: auto;
                }
            }
        }
    }

    .location-left-section {
        @media (max-width: 991px) {
            position: relative;

            @media(orientation: landscape) {
                margin-right: 0;
                margin-bottom: 4px;
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        .hidden-info {
            @media (pointer: fine) {
            &:hover {
                    opacity: 0.75;
                }
            }
        }

        .filter-box {
            position: static;
            @media (pointer: fine) {
                &:hover {
                    // background: $grey-light-8;
                }
            }
            .clear-icon-wrapper {
                @media (pointer: fine) {
                    &:hover {
                        background: $light-blue1;
                    }
                } 
            }
            
            &.filter {
                svg {
                    cursor: pointer;
                    // margin: 0px 6px 0px 17px;

                    // circle {
                    //     stroke: $blue;
                    // }

                    // path {
                    //     fill: $blue;
                    // }
                }
                @media (max-width: 991px) {
                    @media screen and (orientation:landscape) {
                        margin: 10px 5px;
                    }
                }
            }

            @media (max-width: 991px) {
                @media(orientation: landscape) {
                    flex-direction: column;
                    width: 36px;
                    height: fit-content;
                    margin-top: 8px;
                }
            }

            .loc-filter-clear {
                svg {
                    margin: 8px 6px 8px 6px;

                    circle {
                        stroke: unset !important;
                    }

                    path {
                        fill: $white !important;
                    }
                }

                @media (pointer: fine) {
                    &:hover {
                        background: $light-blue1;
                    }
                }

                &:active {
                    background: $blue;

                    circle {
                        fill: $white;
                    }

                    path {
                        fill: $blue !important;
                    }
                }
            }
            @media (pointer: fine)
            {
                &:hover {
                    background: $grey-light-8;
                }
            }
        }
    }
}