@import 'styles/_mixins';
@import 'styles/variables';

.profile-avatar{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  border-radius: 50%;
}
.account-portal-avatar{
  background-image: linear-gradient(to bottom, #859bbb, #5b6c86) !important;
  color: $white !important;
  font-size:12px !important;
  line-height: 1.428571429;
}