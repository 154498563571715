@import 'styles/variables';
@import 'styles/mixins';
.page-overlay{
    background: white;
    position: fixed;
    bottom: 0%;
    right: 0;
    z-index: 101;
    left: 0;
    width: 100%;
    @include slideFromBottom(200%);
    &.support-animation {
        display: none;
        top: 0;
        left: unset;
        width: 375px;
        //transform: none;
        //@include slideFromRight(100%);
    }
}
.device-container{
    top: 0 !important;
}