.watched {
    @media(max-width: 991px) {
        @media(orientation: landscape) {
            flex-direction: column;
            bottom: 72px;
            position: absolute;

            svg {
                transform: rotate(90deg);
                margin-bottom: 6px;
            }
        }
    }

    .text {
        @media(max-width: 991px) {
            @media(orientation: landscape) {
                margin-left: 0;
                text-orientation: mixed;
                writing-mode: vertical-rl;
            }
        }
    }
}