.folders-section {
  margin-bottom: 16px;

  .folders-list {
    transform: translateY(0);
  }

  .section-header {
    font-size: 13px;
    font-weight: bold;
    color: #A2A3A3;
    letter-spacing: 2px;
    padding: 7px 20px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  #flashcard-empty-screen-container{
    margin-bottom: 8px;
  }
}

.add-folder-button{	
  border: 1px solid #E3E6E6;	
  box-sizing: border-box;	
  border-radius: 22px;	
  .add-folder-inner-section{	
    display: flex;	
    align-items: center;	
    justify-content: center;	
    padding: 7px 0;	
  }	
  .add-folder-title{	
    font-style: normal;	
    font-weight: bold;	
    font-size: 17px;	
    line-height: 23px;	
    color: #2EBCB4;	
    margin-left: 12px;	
  }	 
}
