@import 'styles/variables';
@import 'styles/mixins';

.app-layout {
  width: 1440px;
  height: 100%;
  position: relative;
  overflow: hidden;
  .flashcard-section {
    position: fixed;
    box-shadow: 0 10px 32px #3e575542;
    left: calc(calc(max(calc(100% - 1440px), 0px) / 2) + 64px);
    overflow: hidden;
    bottom: 0;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    background-color: #FFFFFF;

    max-width: calc(100% - calc(max(calc(100% - 1440px), 0px) + 439px));
    &.flashcard-section-collapsed {
      margin: 0;
      width: 100%;
      max-width: 1376px;
      left: calc(calc(calc(100% - 1440px) / 2) + 64px);
    }
    &.flashcard-link-section-collapsed {
      margin: 0;
    }
    &.no-flashcard {
      background: #FFFFFF;
      width: calc(100% - 375px);
      transform: translateX(375px);

    }
    &.mobile {
      left: 0;
      max-width: 100%;
      width: 100%;
    }
  }
  .os-home{
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #E3E6E6;
    position: relative;
    display: flex;
    z-index: 999;
  }
  .app-contain-wrapper{

  }
  @include media(iPad){
    @include media-orientation(portrait) {
      .flashcard-section {
        &.no-flashcard {
          // width: calc(100% - 439px) !important;
          // transform: translateX(439px) !important;

          width: calc(100% - 375px) !important;
          transform: translateX(375px) !important;
        } 
        &.flashcard-section-collapsed {
          transform: translateX(0) !important;
          width: 100% !important;
        }
       
      }
      .no-card-overlay{
        display: none ;
      }
    }
    @include media-orientation(landscape) {
      .no-card-overlay{
        display: none ;
      }
    }
  }
  
  @include media(iPadAir){
      .navigation-section {
        &.open{
          width: 375px !important; 
          box-shadow: 1px 0 0 0 #E1E6E6;
          
        }
        &.sliding {
          width: 375px !important; 
        }
      }
      .link-navigation-section {
        &.open{
          width: 375px !important; 
          box-shadow: 1px 0 0 0 #E1E6E6;
         
        }
      }
      .flashcard-section {
        transform: none;
        width: 100%;
        &.flashcard-section-collapsed {
          left: 0 !important;
          max-width: 100% !important;
        }
        &.mobile{
          left: 0;
          max-width: 100%;
        }
      }
      .flashcard-left-section{
        transform: none !important;
        width: 100% !important;
      }
      .flashcard-right-section{
        transform: none;
        width: 100% !important;
      }
      .navigation-section-wrapper {
        width: 375px !important;
        &.navigation-section-visible {
          width: 0 !important;
        }
      }
  }
  .left-menu-navigation{
    box-shadow: 1px 0px 0px #E3E6E6;
    left: calc(max(calc(100% - 1440px), 0px) / 2);
    z-index: 3;
    height: calc(100% - 36px);
  }
  
  .navigation-section {
    overflow: hidden;
    width: 375px;
    background-color: #ffffff;
    height: 100%;
    box-shadow: 1px 0 0 0 #E1E6E6;
    z-index: 2;
    left: calc(calc(calc(100% - 1440px) / 2) + 64px);
    top: 56px;
    @include slideFromLeft(439px);
    @include media(xs sm) {
      @include media-orientation(portrait) {
        @include slideFromLeft(439px);
      }
    }
    &.sliding {
      position: fixed;
      @include slideFromLeft(439px);
    }

    &.edit-exercise-section{
      height: 100%;
      left: calc(max(calc(100% - 1440px), 0px) / 2);
    }
  }
  .flashcard-left-section{
    width: calc(100% - 375px);
    transform: translateX(375px);
  }
  .flashcard-right-section{
    width: calc(100% - 439px);
    transform: translateZ(-439px);
  }
  .flashcard-section {  
    &:not(.no-flashcard) {
      transition: all 0.35s cubic-bezier(.25, .75, .5, 1);
    }
    margin: 0;
    background-color: #EFF4F9;
    @include media(xs sm) {
      @include media-orientation(portrait) {
        width: 100%;
        transform: translateX(0);
        &.no-flashcard {
          transform: translateX(0);
          width: 100%;
        }
      }
      @include media-orientation(landscape) {
        &.no-flashcard {
          transform: translateX(0);
          width: 100%;
        }
      }
    }
    &.flashcard-section-collapsed {

      &.no-flashcard {
        // transform: unset;
        // left: calc(calc(calc(100% - 1440px) / 2) + 64px);
        transform: translateX(0);
        width: 100%;
        transition: transform 0.22s cubic-bezier(0.25, 0.75, 0.5, 1);
      }
    }
    &.flashcard-link-section-collapsed {
      transform: translateX(0);
      width: 100%;
    }

    .no-flashcard-section {
      position: fixed;
      top: 0;
      bottom: 0;
      height: 100%;
      -webkit-overflow-scrolling: touch;
      mix-blend-mode: normal;
      //opacity: 0.75;
      width: 100%;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  @include media(xs sm) {
    @include media-orientation(portrait) {
      .navigation-section {
        left: 0;
        box-shadow: unset;
        &.open{
          width: 100%;
        }
      }
      .link-navigation-section   {
        box-shadow: unset;
        &.open{
          width: 100%;
        }
      }
    }

    @include media-orientation(landscape) {
      .flashcard-section {
        transform: none;
        width: 100%;
      }
      .navigation-section {
        &.open{
         margin-left: 0;
        }
        &.sliding {
          width: 100%;
          display: block;
        }
        height: 100%;
      }
      .no-card-overlay{
        display: none ;
      }
    }
  }
}

.link-navigation-section {
  position: fixed;
  width: 375px;
  background-color: #ffffff;
  height: 100%;
  box-shadow: 0 0 0 1px #E1E6E6;
  z-index: 3;
  right: 0;
  @include slideFromRight(375px);
  
    @include media(xs sm) {
      @include media-orientation(portrait) {
        @include slideFromRight(375px);
      }
    }
    &.sliding {
      position: fixed;
      height: 100%;
      @include slideFromRight(375px);
    }
  @include media(xs sm) {
    @include media-orientation(landscape) {
      display: none;
    }
  }
}
.back-card-button{
  background: #D9DFE4;
  border-radius: 26px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  margin-right: 8px;
  cursor: pointer;
  @include media(xs sm) {
    @include media-orientation(landscape) {
      padding: 2px 8px;
      margin-right: 0;
      z-index: 10;
    }
  }
  .back-card-number{
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #A2A3A3;
    margin-left: 2px;
  }
  @media (pointer: fine) {
    &:hover {
      background: #C6CED4;
      box-shadow: 0px 0px 0px rgba(62, 87, 85, 0.22);
    }
  } 
  &:active {
    background: #378EF8;
    box-shadow: none;
    .back-card-number{
      color: #FFFFFF;
    }
    .back-button-svg{
      circle{
        fill:  #FFFFFF
      }
      path{
        fill:  #2EBCB4
      }
    }
  }
}
.button-left-overlay{
  position: absolute;
  left: 391px;
  z-index: 5;
  top: 54px;
  display: none;
  @include media(xs sm) {
    display: block !important;
    @include media-orientation(landscape) {
      top: 8px;
      left: 439px;
    }
  }
  @include media(vsm) {
    @include media-orientation(portrait) {
      display: none !important;
    }
  }
}
.button-right-overlay{
  position: absolute;
  right: 391px;
  z-index: 5;
  top: 54px;
  display: none;
  @include media(xs sm) {
    display: block !important;
    @include media-orientation(landscape) {
      top: 8px;
      right: 383px;
    }
  }
  @include media(vsm) {
    @include media-orientation(portrait) {
      display: none !important;
    }
  }
}
.card-info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-study-overlay{
  display: block;
  
}
.study-overlay{
  @include media(xs sm) {
    display: none;
  }
}
.learn-icon{
  rect{
    fill: url(#linearGradient-learn-1) #FC2808;
  }
}
.repeat-icon{
  rect{
    fill: url(#linearGradient-repeat-1) #FA7D00;
  }
}
.memorize-icon{
  rect{
    fill: url(#linearGradient-mem-1) #50D466;
  }
}
#gradient-learn {
  --color-stop:#FC2808;
  --color-bot:#FC5108;
}

#gradient-repeat {
  --color-stop:#FA7D00;
  --color-bot:#FA9A00;
}

#gradient-memorize {
  --color-stop:#50D466;
  --color-bot:#50D4A8
}

/* feature Popup style */
.feature-popup-box {
  position: fixed;
  //background: #00000050;
  width: 100%;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(26,109,172,0.6);
}

.navigation-section-wrapper {
  position: fixed;
  overflow: hidden;
  background-color: #ffffff;
  height: calc(100% - 56px);
  box-shadow: 1px 0 0 0 #E1E6E6;
  z-index: 2;
  left: calc(calc(max(calc(100% - 1440px), 0px) / 2) + 64px);
  top: 56px;
  box-sizing: content-box;
  object-fit: cover;
  transition: width 0.35s cubic-bezier(.25, .75, .5, 1);
  &.navigation-section-visible {
    width: 0;
    left: calc(calc(calc(100% - 1440px) / 2) + 64px);
    &.device-mobile {
      transition: all 0.25s cubic-bezier(.25, .75, .5, 1);
      left: -101%;
    }
  }
  &.edit-exercise-section{
    left: calc(calc(100% - 1440px) / 2);
  }
  @include media-orientation(portrait) {
    &.device-mobile {
      transition: all 0.25s cubic-bezier(.25, .75, .5, 1);
      width: 100%;
      left: 0;
      height: calc(100% - 121px);
    }
  }
  &.iPad {
    width: 48.8%;
    height: calc(100% - 121px);
  }
}

@media only screen and (max-width: 1439px) and (min-width: 821px) {
  .navigation-section-wrapper {
    left: 64px;
  }
  .flashcard-section {

    &.flashcard-section-collapsed {
      max-width: 100% !important;
      left: 0px !important;
    }
    
    @include media-orientation(landscape) {
      .flashcard-left-section{
        max-width: 100% !important;
        left: 0 !important;
      }
    }
    &.no-flashcard {
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 1024px) {
  .navigation-section {
    transform: none !important;
  }
  .navigation-section-wrapper {
    
    &.navigation-section-visible {
      left: 0 !important;

      &.device-mobile {
        width: 0;
      }
    }

    &.device-mobile {
      width: unset;
    }
  }
  .flashcard-section {
    left: 0px !important;
    width: 100% !important;
    max-width: calc(100% - 375px) !important;


    //&.flashcard-left-section {
      //max-width: calc(100% - 439px) !important;
      //width: calc(100% - 439px) !important;
     // transform: translateX(439px);
    //}


    &.flashcard-section-collapsed {
      max-width: 100% !important;
    }
    &.no-flashcard{
      transform: translateX(375px) !important;
      max-width: calc(100% - 375px) !important;
    }
  }
}
@media only screen and (max-width: 1023px) and (min-width: 992px){
  .flashcard-section {
    &.flashcard-left-section {
      max-width: 100% !important;
      width: 100% !important;
      left: 0 !important;
    }
  }
}

.left-active-tile {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}