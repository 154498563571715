@import 'styles/variables';
@import "styles/mixins";

#search-page {
  &.page {
    padding: 0;
  }

  #coming-soon {
    margin-top: 59px;
  }

  .header {
    background: $gradient-left-search-right;
    padding: 0 20px 12px;
    margin-top: -4px;
  }

  .title {
    opacity: 0.5;
    color: $white;
    font-size: 30px;
    font-weight: bold;
  }

  .title-container {
    align-items: baseline;
    padding-top: 65px;
    padding-bottom: 14px;
    display: flex;
    justify-content: space-between;
  }

  .colors-box {
    background-color: $white;
    opacity: 0.3;
    width: 100%;
    height: 34px;
    border-radius: 6px;
    box-shadow: 0 0.5px 5px 0 rgba(62, 87, 85, 0.22);
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .color-container {
    width: 20px;
    height: 20px;
    border: solid 2px $white;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px rgba(117, 117, 117, 1);
  }

  .color-green {
    background-color: $green
  }

  .color-orange {
    background-color: $orange
  }

  .color-red {
    background-color: $red
  }

  .color-yellow {
    background-color: $yellow
  }

  .color-blue {
    background-color: $blue
  }

  .color-purple {
    background-color: $purple
  }

  .scrollable-content {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background: $white;
    position: relative;

    &.prevent-scroll {
      overflow: hidden;
    }

    @include media(xs sm) {
      //overflow: hidden;
      height: 100%;
    }
  }
}

.search-overlay {
  overflow: overlay;
}

.search-clear {
  line-height: 18px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: all .2s;
  color: $blue;
  cursor: pointer;

  @media (pointer: fine) {
    &:hover {
      color: $hover-blue;
    }
  }

  &:active {
    color:$active-blue;
  }
}

.search-list-item {
  height: 50px;
  position: relative;
  cursor: pointer;
  display: flex;

  &:not(:first-child):after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    margin: 0 20px;
    background: #e4e6e6;
  }

  &:hover {
    @media (pointer: fine) {
      background-color: #F2F2F2;
    }

    .search-delete-button {
      display: block;
    }
  }
}

.search-delete-button {
  width: 18px;
  height: 18px;
  border-radius: 10px;
  color: $white;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  align-self: center;
  justify-content: center;
  display: none;
  line-height: 1.1;

  @include media(xs sm) {
    display: block;
  }

  @media (hover: none) {
    display: block;
  }
}

.search-from {
  width: 100%;

  .form-input-wrapper {
    width: 100%;

    .textarea {
      resize: vertical;
      box-sizing: border-box;
      padding-right: 28px;
      border-radius: 0;
    }
  }

  .search-input {
    background: transparent;
    border: none;
    font-family: "Open Sans";
    font-size: 25px;
    font-weight: bold;
    outline: none;
    color: $rgba-light-grey;
    box-sizing: border-box;
    width: 100%;
    margin-right: 16px;
    border-radius: 0;
    padding: 0;
    height: 34px;

    &::-webkit-input-placeholder {
      color: $rgba-light-grey;
    }

    &:focus,
    &:valid,
    &:not(:placeholder-shown) {
      color: $white;
    }
  }

  .search-close {
    font-family: Open Sans;
    font-size: 13px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $white;
    transition: all .2s;

    @media (pointer: fine) {
      &:hover {
        opacity: 0.75;
      }
    }

    &:active {
      color: #0A7670;
    }

    cursor: pointer;
  }
}

.search-close.cancel-btn {
    transition: all .2s;

    @media (pointer: fine) {
      &:hover {
        opacity: 0.75;
      }
    }

    &:active {
      color: #0A7670;
    }  
}