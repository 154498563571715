@import 'styles/variables';
.folders-section {
  margin-bottom: 16px;

  .folders-list {
    transform: translateY(0);
  }

  .section-header {
    font-size: 13px;
    font-weight: bold;
    color: #A2A3A3;
    letter-spacing: 2px;
    padding: 7px 20px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  .flashcard-empty-screen-container{
    margin-bottom: 8px;
  }
}
.folder-list-item {
  &.deleted {
    animation: slideItem .3s linear forwards, downsize .2s linear forwards;
    animation-delay: 0s, .3s;
  }

  @media (pointer: fine) {
    &:hover {
      background-color: $grey-light-8;
    }
  }

  &.active {
    background-color: $grey-light-9;
  }
  
  svg {
    display: block;
  }

  &.deleted {
    animation: slideItem .3s linear forwards, downsize .2s linear forwards;
    animation-delay: 0s, .3s;
  }
}

.add-button {
  &:active{
    svg path {
      stroke: $grey-light-2 !important;
    }
  }
}