@import 'styles/variables';
@import 'styles/mixins';

.profile-details-page {
  height: 100%;
  overflow-y: auto;
  //padding-top: 49px;
  -webkit-overflow-scrolling: touch;

  .form-input-wrapper:first-child {
    margin-top: 0 !important;
  }

  .page-title {
    color: black;
    font-weight: bold;
    width: 40%;
    text-align: center;
  }

  .profile-details-form {
    padding: 20px;
    margin-top: 52px;
  }

  .profile-details-subtitle {
    font-size: 13px;
    color: $grey-light-2;
    letter-spacing: 1.6px;
    font-weight: 800;
  }

  .subtitle-padding {
    padding-top: 40px;
  }

  .back-button {
    margin-left: 0px;
  }

  // .input{
  //   height: 49px;
  // }
  .form-label {
    vertical-align: top !important;
  }

  .form-input-wrapper {
    margin-top: 16px !important;
  }

  .form-input {
    margin-top: -3px !important;
  }
  .form-icon{
    height: 23px;
  }
}



.form-input-wrapper {
  margin-top: 16px;

  .react-datepicker-popper{
    z-index: 2;
  }

  .input {
    position: relative;
  }

  .form-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .form-label {
    font-size: 13px;
    font-weight: 600;
    color: #a2a3a3;

    &.active {
      color: $blue;
    }

    &.error {
      color: $error-color;
    }
  }

  .form-input {
    display: block;
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0;
    white-space: normal;

    border-bottom: 1px solid #e3e6e6;;
    line-height: 35px;
    font-size: 16px;
    //margin-top: 4px;
    font-family: "Open Sans", sans-serif;

    @include placeholder-color {
      color: #c8cccb;
      font-weight: 100;
      opacity: 1;
    }

    &.active {
      border-bottom: 1px solid $blue;
    }

    &.error {
      border-bottom: 1px solid $error-color;
    }
  }

  textarea.form-input {
    line-height: 19px;
    padding: 8px 0 7px 0;
  }

  .form-input[type='number'] {
    width: 25px;
    text-align: center;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-input-placeholder {
      text-align: center;
    }

    &:-moz-placeholder { /* Firefox 18- */
      text-align: center;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      text-align: center;
    }

    &:-ms-input-placeholder {
      text-align: center;
    }
  }

  .date-placeholder {
    background-color: white;
    position: absolute;
    bottom: 1px;
    font-size: 16px;
    padding: 10px 0;
    color: #c8cccb;
    font-weight: 100;
    font-family: "Open Sans", sans-serif;
    left: 0;
    right: 0;
  }

  select.time {
    text-align: center;
    text-align-last: center;
    min-width: 50px;
    font-size: 16px;

    &.small{
      min-width: 20px;
    }
  }
  input[type=date]{
    height: 40px;
  }
}
