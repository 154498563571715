@import 'styles/variables';
@import 'styles/mixins';

#more-page {
  &.standalone {
    z-index: unset;
  }

  .more-page-header {
    color: $turquoise;
    font-size: 17px;
    padding: 14px;
  }

  .back-icon-container {
    display: inline-flex;
    cursor: pointer;
  }

  .back-subject {
    padding-left: 7px;
    line-height: 20px;
    font-family: Helvetica;
  }

  .profile-picture {
    width: 40px;
    height: 40px;
    font-weight: bold;
    font-size: 20px;
  }

  .items-separator {
    background-color: $grey-light;
    width: 100%;
  }

  .more-page-items-container {
    color: #2D3838;
    font-weight: bold;
    font-size: 17px;
    line-height: 23px;
  }

  .more-page-item {
    display: block;
    padding: 13px 0;
    width: 100%;
  }

  .more-page-item-container {
    position: relative;
    padding: 0 20px;
    display: flex;
    align-items: center;
    &:hover{
        background: #EFF4F9;
        //border-radius: 8px;
    }
    @media (hover: none) and (pointer: coarse){
      &:hover {
        background: none;
      }
      &:active {
        background: none;
      }
    }
  }

  .forward-arrow-icon {
    position: inherit;
    right: 0;
    top: calc(50% - 8px);
    padding-right: 0;
  }
}
.setting-page{
  .forward-arrow-icon {
    position: absolute;
    right: 0;
    top: calc(50% - 8px);
  }
}

.more-page-fixed-spacing {
  padding-top: 0;
  padding-bottom: 150px;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: overlay;
  .back-button{
    margin-left:0px;
  }
}