.page {
  // padding: 14px 20px;

  // &.flashcard {
  //   padding: 0 20px 14px;
  // }

  .title {
    font-size: 33px;
	  font-weight: bold;
	  color: #2d3838;
  }
}
