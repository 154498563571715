@import 'styles/variables';

.joyride-tooltip__footer {
  .joyride-tooltip__button--primary {
    background-color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #2ebcb4;
    box-shadow: 0 1px 3px 0 rgba(93, 116, 114, 0.35);
    border-radius: 15px;
    &:hover, &:active, &:focus {
      background-color: white;
      color: #2ebcb4;
    }
  }
  .joyride-tooltip__button--skip, .joyride-tooltip__button--secondary {
    font-size: 15px;
    line-height: 1.33;
    text-align: left;
    color: #ffffff;
  }
}

.modal-tutorial-content {
  text-align: center;
}
.modal-tutorial-text {
  color: white;
  border-radius: 8px;
  border: solid 1px #ffffff;
  padding: 18px 14px;
  font-size: 15px;
  line-height: 1.2;
  margin-top: 10px;
  .title {
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 16px;
  }
}
.container {
  position: relative;
}

.container:after,
.container:before {
  content: '';
  display: block;
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
}
.container:after {
  top: -20px;
  left: calc(50% - 5px);
  border-color: transparent transparent #2ebcb4 transparent;
  border-width: 10px;
}

.container:before {
  top: -24px;
  left: calc(50% - 7px);
  border-color: transparent transparent #fff transparent;
  border-width: 12px;
}
.helper-with-image {
  display: flex;
  align-items: center;
}
.thin-text { 
  font-weight: 400;
}
