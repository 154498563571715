@import 'styles/_mixins';
@import 'styles/variables';

.onboaring-wrapper{
    position: relative;
    padding: 0;
    min-width: 616px;
    max-width: 616px;
    height: 676px;
    overflow-x: hidden;
    background: $white;
    border: 1px solid #E1E6E6;
    border-radius: 16px;
    img {
        width: 100%;
        height: 100%;
    }
    @include media(phone) {
        min-width: auto;
        max-width: initial;
        margin-top: 0 !important;
        width: 100%;
        height: 100%;
    }
    @include media(phone) {
        height: 100%;
        min-width: auto;
        max-width: initial;
        margin-top: 0 !important;
        width: 100%;
    }
    .full-height{
        width: 616px !important;
        @include media(phone) {
            height: 100%;
            width: 100% !important;
        }
        @include media(tablet) {
           width: auto !important;
        }
    }
    & > .onboarding-slider {
        position: unset;
        min-height: unset;
    }

    & > .onboarding-slider .flickity-viewport {
        position: unset;
    }

    & > .onboarding-slider .flickity-viewport .flickity-slider {
        position: unset;
        width: 100%;
        height: 100%;
    }
}
.onboarding-footer{
    position: absolute;
    left: 0; 
    bottom:0; 
    right:0;
    padding: 0 40px 32px;
    @include media(phone) {
        padding: 0 20px 24px;
    }
    @media(orientation: landscape){
        @include media(phone) { 
                bottom: 0px; 
        }
    }
}
.onboarding-skin-btn{
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 2px;
    width: 34px;
    text-transform: uppercase;
    visibility: initial;
    color: $grey-light-2;
    @media (pointer: fine) {
        &:hover {
          //${props => !props.inverted  && 'color: #64A8FA;'}
        }
      } 
    &:active {
        //${props => !props.inverted  && 'color: #2170CF;'}
    }
}
.onboarding-nav-btn{
    width: 36px;
    height: 36px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.3);
    border: 1px solid $grey-light-5;
    @media (pointer: fine) {
        &:hover {	
          border: 1px solid $grey-light-6;
        }
    }
    &:active {
        background: $blue; 
        border: 1px solid $blue;
        color: $white;	
        .btn-onboarding{
          path{
            fill: $white;
          }
        } 
    }  
}
.onboaring-img-wrapper{
    &:hover{
        cursor: pointer;
    }
    &:active{
        cursor: grabbing;
    }
}


.onbaording-content-wrapper{
    padding: 24px 40px 32px 40px;
}
.onboarding-title{
    font-size: 33px;
    font-weight: bold;
    text-align: center;
    line-height: 45px;
    color: $blue;
}
.onboarding-subtitle{
    padding-top: 24px;
    text-align: center;
    .onboarding-subtitle-text{
        flex: 1;
        text-align: left;
        font-size: 15px;
        color: $charcoal;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 20px;
    }
}
.onboarding-bullet-icon{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $bx-grey;
  margin: 0 6px;
  cursor: pointer;
  @include media(smallPhone) { 
    margin: 0 4px;
  }
  &.active{
    background: $blue !important;
  }

}
.onboarding-start-btn{
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $white;
    visibility: initial;
    background: $blue;
    padding: 9px 12px;
    border-radius: 22px;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.2);
    //margin-left: ${props => !props.smalliPhone5 ? 32 : 10}px;
    width: 73px;
    
    @media (pointer: fine) {
        &:hover {
        background: $hover-blue;
        }
    } 
    &:active {
        background: $active-blue;
    }
}