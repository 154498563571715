@import "styles/mixins";
@import "styles/variables";

.exam-wrapper {
  position: absolute;

  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  max-width: 754px; //709px;

  @include media(xs sm) {
    @include media-orientation(landscape) {
      top: 0;
      width: $flashcard-width-mobile;
    }
  }

  .exam-contain {
    //background: #FFFFFF;
    display: flex;
    position: absolute;
    width: 100%;
    height: calc(100% - 54px);
    // top: 104px;
    // bottom: 158px;
    padding-top: 128px;
    padding-bottom: 0;
    top: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    justify-content: center;
    left: 0;

    @include media(xs sm) {
      @include media-orientation(landscape) {
        top: 0;
        padding-top: 0;
        bottom: 59px;
        height: calc(100% - 59px);
        overflow-x: hidden;
      }
    }
  }

  .exams-bottom-controls {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    background: #ffffff;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
    border-radius: 10px;

    position: absolute;
    bottom: 62px;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 28px);
    padding: 0 20px;

    // box-shadow: 0px -1px 0px #E3E6E6;
    // background-color: #EFF4F9;
    @include media(xs sm) {
      @include media-orientation(landscape) {
        padding: 0;
        bottom: 8px;
        max-width: calc(100% - 24px);
      }
    }
    @media (min-width: 821px) and (max-width: 1024px) {
      &.left-menu-visible {
        bottom: 126px;
      }
    }
  }

  .exam-iframe {
    &.no-pdf {
      height: calc(100% - 215px);
      // bottom: 0px !important;
    }
    // height: calc(100% - 67px);
    position: absolute;
    bottom: 67px;
    overflow: scroll;
    background: white;
    user-select: none;
    @include media(xs sm) {
      @include media-orientation(landscape) {
        height: calc(100% - 8px);
      }
    }
    // @include media(xs sm) {
    //   @include media-orientation(landscape) {
    //     height: calc(100% - 8px);
    //   }
    // }
    &.half {
      height: calc(100% - 67px - 50%);
      box-shadow: 0px -13px 20px rgba(62, 87, 85, 0.11);
      @include media(phone) {
        height: calc(100% - 202px);
        box-shadow: none;
      }
      @include media(iPadAir) {
        height: calc(100% - 50px - 49%);
      }
      @media only screen and (width: 768px) and (height: 1024px) {
        height: calc(100% - 60px - 50%);
      }
      @include media(iPadPro) {
        height: calc(100% - 11px - 50%);
      }
    }
  }
}

.exam-content {
  // height: calc(100% - 450px);
  // // 670-
  // @media(orientation: landscape){
  //   height: calc(100% - 450px);
  // }
  padding-top: 20px;
  height: 100%;
  z-index: 999;
  @include media(phone) {
    @include media-orientation(portrait) {
      display: none;
    }
  }
}

.exam-renderer-wrapper {
  @include media(xs) {
    padding: 6px 0;
    width: 76.2%;

    @media (orientation: portrait) {
      width: 100%;
      padding: 0 0;
    }

    @media (orientation: landscape) {
      width: 86.5%;
      padding: 6px 0;
    }
  }

  @media only screen and (min-width: 650px) and (max-width: 1200px) and (orientation: portrait) {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.main-exam {
  width: 100%;
  padding: 0px 0;
  .btn-wrapper {
    background: rgba(32, 61, 60, 0.75);
  }
}

.pdf-container {
  position: absolute;
  overflow: scroll !important;
  height: 50%;
  top: 128px;
  /* width */
  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aaa;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.pdf-half-height {
  animation: change-height-up 1s ease-in;
  height: 35%;

  @include media(phone) {
    @include media-orientation(portrait) {
      height: calc(100% - 64px);
      display: none;
    }
  }
}

.pdf-full-height {
  animation: change-height 1s ease-in;
  height: calc(100% - 202px);

  @include media(phone) {
    @include media-orientation(portrait) {
      top: 128px;
      height: calc(100% - 202px) !important;
    }
  }
}

@keyframes change-height {
  from {
    height: 35%;
  }

  to {
    height: calc(100% - 202px);
  }
}

@keyframes change-height-up {
  from {
    height: calc(100% - 202px);
  }

  to {
    height: 35%;
  }
}

.exam-iframe-show {
  display: block;
  animation: fade_in_show 1s ease-in;

  @include media(phone) {
    @include media-orientation(portrait) {
      animation: none;
    }
  }
}

.exam-iframe-hidden {
  display: none;
}

@keyframes fade_in_show {
  0% {
    display: none;
  }

  99.99% {
    display: none;
  }

  100% {
    display: block;
  }
}
