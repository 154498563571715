@import 'styles/_mixins';
@import 'styles/variables';

.save-button {
  color: $blue;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;

  &:disabled {
    color: $bx-hover-grey;
    pointer-events: none;
  }

  &:focus {
    outline: 0;
  }

  @media (pointer: fine) {
    &:hover {
      color: $hover-blue;

      &:disabled {
        color: $bx-hover-grey;
      }
    }
  }

  &:active {
    color: $active-blue;

    &:disabled {
      color: $bx-hover-grey;
    }
  }
}
