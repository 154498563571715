@import 'styles/_mixins';
@import 'styles/variables';

.progress-wrapper{
    border-radius: 2px;
    height:4px;
    margin-right:5px;
    position:relative;
    justify-content: center;
    align-items: center;
    display: flex;
    &:last-child {
        margin-right:0px !important;
    }
}
.progress-active{
    &:after {        
        left: auto;
        right: auto;
        border-right:solid 8px rgb(55 142 248);
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        display: block;
        position: absolute;
        top: 4px;
        width: 0px;
        height: 0px;
        content: "";
        border-top: solid 5px transparent;
        border-bottom: solid 5px transparent;
        border-radius: 0.5px;
    }
}