@import 'styles/_mixins';
@import 'styles/variables';
.translate-empty-cc{
  position: relative;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate3d(-50%, -50%, 0);
  
}
.initial-flashcard-selector{
  max-width: 335px;
  @include media(xs) {
    @include media-orientation(landscape) {
      min-width: 357px;
      padding: 0;
      position: absolute;
    }
    @include media-orientation(portrait){
      position: static;
      top: auto;
      left: auto;
      transform: none;
      margin: 0 14px 19px;
      padding: 0;
    }
  }
  .topics-container{
    display: none;
    @include media(xs) {
      display: block;
      max-width: 100%;
    }
  }
}

.shadow-flashcard{
  box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.3);
  border-radius: 50%;
  margin-bottom: 8px;
  div {
    font-size: 33px !important;
  }
}

.shadow-flashcard-title{
  padding-bottom: 8px;
  @include media(xs){
    padding: 0;
  }
}