@import 'styles/_mixins';
@import 'styles/variables';

.status-bar-content{
    display: flex;
    max-height: 70px;
    img {
        width: 41px;
        height: 40px;
    }
}