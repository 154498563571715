@import 'styles/mixins';

.store-item-details-wrapper {
  .back-to-shop-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 16px 20px 17px 13px;
    background-color: white;
    z-index: 1;

    @include media(md lg xl) {
      width: 375px;
    }
  }

  .back-to-shop {
    display: flex;
    align-items: center;
  }

  .back-to-shop-title {
    padding-left: 7px;
    line-height: 20px;
    font-size: 17px;
    color: #2EBCB4;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    padding: 17px 20px 0 0;
  }
  .header {
    background: white;
    padding-top: 43px;
    position: static;
  }

  .picture-container {
    width: 40px;
    .profile-picture {
      width: 40px;
      height: 40px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .review-container {
    padding-top: 10px;
  }

  .star-ratings {
    unicode-bidi: bidi-override;
    color: #c5c5c5;
    font-size: 18px;
    height: 18px;
    width: 90px;
    position: relative;
    padding: 0;
    display: inline-block;

    .star-ratings-top {
      color: #F5A623;
      padding: 0;
      position: absolute;
      z-index: 1;
      display: block;
      left: 0;
      overflow: hidden;
    }

    .star-ratings-bottom {
      z-index: 0;
    }
  }

  .review-number {
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    color: #a2a3a3;
    padding-left: 4px;
    line-height: 18px;
    vertical-align: top;
  }

  .menu-container {
    padding-top: 15px;
    display: flex;
  }

  .menu-title {
    font-size: 19px;
    font-weight: bold;
    color: $grey-light-2;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    padding-bottom: 10px;
    margin-right: 24px;
    &.active {
      border-bottom: 2px solid $turquoise;
      color: $turquoise;
    }
  }

  .menu-separator {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background-color: #e4e6e6;
  }
}
