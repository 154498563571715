@import 'styles/mixins';
@import 'styles/variables';

.teaching-level-bg {
  position: fixed;
  max-width: 1440px;
  //   left: 372px;
  left: calc(max(calc(100% - 1440px), 0px) / 2);
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(26, 109, 172, 0.6);
  z-index: 5;

  .controls-wrapper {
    position: fixed;
    bottom: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 1440px;

    .teaching-popup-section {
      width: 347px;
      // height: 454px;
      background-color: rgba(255, 255, 255, 1.0);
      box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
      border-radius: 10px;
      position: relative;
      padding: 0 20px;
    }
  }

  .close-button {
    height: 20px;
    width: 20px;
    background: $blue;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .box-378EF8 {
    box-shadow: 0 0 0 2px $blue inset;
  }

  .box-E3E6E6 {
    box-shadow: 0 0 0 1px $bx-grey inset;
  }

  .mathematics-sl {
    border-radius: 10px 0px 0px 10px;
  }

  .tlvl-hl {
    border-radius: 0px 10px 10px 0px;
  }

  .tlvl-text {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-transform: initial;
    color: $white;
    height: 22px;
    min-width: 30px;
  }

  .teaching-tag-heading {
    // margin-top: 12px;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: #2D3838;
  }

  .tvl-cancel-button {
    background: $white;
    box-shadow: 0 0 0 1px $bx-grey;
    box-sizing: border-box;
    border-radius: 22px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $blue;
    width: 146px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 0 0 1px $bx-hover-grey;
      }
    }

    &:active {
      box-shadow: none !important;
      background: $blue;
      color: $white;
    }
  }

  .tvl-ok-button {
    background: $white;
    box-shadow: 0 0 0 1px $bx-grey;
    box-sizing: border-box;
    border-radius: 22px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $blue;
    width: 307px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 0 0 1px $bx-hover-grey;
      }
    }

    &:active {
      box-shadow: none;
      background: $blue;
      color: $white;
    }
  }

  .tvl-save-button {
    border-radius: 22px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $white;
    width: 146px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    @media (pointer: fine) {
      &:hover {
        background: $hover-blue;
      }
    }

    &:active {
      background: $active-blue;

    }
  }
}

.year-button {
  box-shadow: 0 0 0 1px $bx-grey2 inset;

  &.active {
    box-shadow: 0 0 0 2px $blue inset;
  }
  &:nth-child(1) {
    border-radius: 8px 0px 0px 8px;
  }
  &:nth-child(2) {
    border-radius: 0px 8px 8px 0px;
  }
}

.cookie-active-bar {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}