@import 'styles/variables';

.subject {
  flex-basis: 14%;
  
  @media only screen and (min-width: 280px) and (max-width: 359px){
    flex-basis: 50%;
  }  
  @media only screen and (min-width: 360px) and (max-width: 530px){
    flex-basis: 33%;
  }  
  @media only screen and (min-width: 531px) and (max-width: 768px){
    flex-basis: 26%;
  } 
}
.subject {
  &:active {
    .icon-wrapper{
      box-shadow: 0 0 0 3px $blue;
      .title-text{
        color: $blue;
      }
    }
  }
}
.icon-wrapper {
  box-shadow: 0 0 0 1px $grey-light-4;
  height: 134px;
  transition: all .2s;
  img{
    display: flex;
    margin:0 10px;
  }
  &.isActive{
    box-shadow: 0 0 0 3px $blue;
  }
  @media (pointer: fine) {
    &:hover {

      &.isActive{
        box-shadow: 0 0 0 3px $blue;
        .title-text{
          color: $blue;
        }
      }

      box-shadow: 0 0 0 1px $bx-hover-grey;
      .title-text{
        color: $bg-dark-grey;
      }
    }
  }
}

// .new-tag {
//   box-shadow: 0 0 0 1px $dark-orange;
// }

.year {
  background: $bg-light-grey;
  letter-spacing: 0;
}
