@import 'styles/_mixins';
@import 'styles/variables';

.menu-page {
  position: relative;
  background: #FFFFFF;
  top: 0;
  bottom: 0;
  z-index: 4;
  height: 100%;
  width: 375px;

  @include media(xs) {
    width: 100%;
  }

  @media (min-width: 600px) and (orientation: portrait) {
    width: 375px !important;
  }
}

.menu-page-close {
  max-width: min-content;
  border-radius: 20px;
  cursor: pointer;

  @media (pointer: fine) {
    &:hover {
      background-color: #EFF4F9;
    }
  }

  &:active {
    background-color: #E5ECF2;
  }

  div {
    align-self: center;
    cursor: pointer;
    color: #FFFFFF;
    width: 20px;
    height: 20px;
    background: #378EF8;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

svg{
  vertical-align:inherit;
}
.logout-active{
  &:active{
    box-shadow: none !important;
    background: #378EF8;
    .logout-icon{
      path{
        stroke: #FFFFFF;
      }
    }
  }
}

.menu-link-item {
  @media (pointer: fine) {
    &:hover {
      background: $grey-light-8;
    }
  } 
}