@import 'styles/mixins';
@import 'styles/variables';

.more-control-overlay {
  .bottom-controls {
    left: calc(calc(max(calc(100% - 1440px), 0px) / 2) + 10px);
    height: calc(100% - 34px);
  }

  .prev-5-btn {
    background: rgba(255, 255, 255, 0.4);
  }
}