@import 'styles/mixins';
@import 'styles/variables';
.bottom-menu{
    transition: background .5s ease-in;
    @include media(phone) {
        @media(orientation: landscape) {
            min-width: auto;
            max-width: initial;
            margin-top: 0 !important;
            width: 100%;
            height: 100%;
        }
    }
    .bottom-menu-inner{
        @include media(phone) {
            @media(orientation: landscape){
              height: 100%;
              box-shadow: none;
              justify-content: center;
            }
        }
    }
    .bottom-menu-content{
        @include media(phone) {
            @media(orientation: landscape){
                display: none;
                // flex-direction: column;
                // bottom: 0;
                // box-shadow: none;
                // border: none;
                // height: 40px;
            }
        }
    }
}
.menu-active{
    background: $blue;  
    svg{
      cursor: unset;
      rect{
          stroke: $white !important;
        }
      path{
      //   fill:#FFFFFF !important;
        stroke: $white !important;
      }
      circle {
          stroke: $white;
      }
    }
}
.bottom-menu-item {
    @media (pointer: fine) {
        &:hover{
            background-color: #378EF8;

            svg{
                cursor: unset;
                rect{
                    stroke: $white !important;
                  }
                path{
                //   fill:#FFFFFF !important;
                  stroke: $white !important;
                }
                circle {
                    stroke: $white;
                }
              }
        }
    }
    &:active{
        background-color: #378EF8;
    }
}