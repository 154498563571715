@import 'styles/variables';
@import 'styles/mixins';

.filter-wrapper{
  width: 347px;
  z-index: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: $white;
  box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
  border-radius: 10px;
  user-select: none;
  cursor: default;
  overflow: scroll;
  max-height: 100%;
}
.filter-close-icon-wrapper {
    @media (pointer: fine) {
        &:hover {
            background-color: $grey-light-8;
        }
    }
    &:active {
        background: $grey-light-9;
    }
}
.filter-cancel-btn{
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    background: $white;
    width: 146px;
    height: 36px;
    box-shadow: 0 0 0 1px $bx-grey;
    box-sizing: border-box;
    border-radius: 22px;
    user-select:none;
    svg{
      margin-right: 12px;
    }
    .cancel-btn-text{
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 2px;
        color: $blue;
        text-transform: uppercase;
    }
    &:active {
        box-shadow: none !important;
        background: $blue;
        .filter-btn-text{
            color: $white;
            margin: 0;
        }
    }

    @media (pointer: fine) {
        &:hover {
            box-shadow: 0 0 0 1px $bx-hover-grey;
        }
    } 
}
.filter-apply-btn{
    height: 36px;
    width: 146px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    user-select:none;
    cursor: pointer;
    background: $blue;
    &.disabled-btn{
        cursor:default;
        pointer-events:none;
        background: $grey-light-8 !important;
    }
    .apply-btn-text{
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 2px;
        color: $white;
        text-transform: uppercase;
    }
    @media (pointer: fine) {
        &:hover {
            &.enable-btn{
                background: $hover-blue !important;
            }
        }
    } 
    &:active {
        &.enable-btn{
            background: $active-blue !important;
        } 
    }
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $grey-light-13;
    border-radius: 15.5px;
    transition: .4s;
}
.slider{

&:before {
    position: absolute;
    content: "";
    height: 28px !important;
    width: 28px !important;
    bottom: -3px;
    left: 0;
    transition: .4s;
    background: $white;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.0510643), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
}}
input:checked + .slider {
    background: $green;
}

input:checked + .slider:before {
    background: $dark-green;
    left: -2px;
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.0510643), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
}

input:focus + .slider {
    box-shadow: 0 0 1px $green;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.slider.round:before {
    border-radius: 50%;
}
.filter-category-section{
    font-size: 15px;
    line-height: 19px;
    color:$charcoal;
    display: flex;
    align-items: center;
    font-weight: 400;
    svg{
        margin-right: 6px;
        width: 18px !important;
        height: 18px !important;
    }
}
.filter-sub-category {
    display: -webkit-box;
  // -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.unorder-list{
    & > li:before {
        content: "-";
    };
}
.continue-btn{
    background: $white;
    box-shadow: 0 0 0 1px $bx-grey;
    border-radius: 22px;    
    padding: 8px 0;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    position: relative;
    svg{
        margin-right: 8px;
    }
    svg{
      margin-right: 12px;
    }
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 0 0 1px $bx-hover-grey;
      }
    } 
    &:active {
      box-shadow: none;
      background: $blue;
      .continue-txt{
        color: $white !important;
      }
      svg{
        circle{
          fill: $white;
        }
        path{
          stroke: $white;
        }
        path.path-number {
            fill: $white;
        }
      }
    }
}
.paper-filter-content{
    color: $dark-orange;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0px 0px 0px 1px $dark-orange;
    border-radius: 6px;
    width: fit-content;
    padding: 4px 8px 5px 8px;
    margin: 10px 0 5px 0;
}
.filter-active{
    position: absolute;
    left: -20px;
    background: $orange;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    top: 0px;
    bottom: 0px;
    width: 4px;
}
.topic-wrapper{
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 0px 0px 1px $bx-grey;
    height:50px;
    margin-top:14px;
    padding:14px;
    display:flex;
    cursor: pointer;
    @media (pointer: fine) {
        &:hover {
            box-shadow: 0 0 0 1px $bx-hover-grey;
        }
    } 
    &:active {
        box-shadow: none;
        background: $blue;
        
        .topic-title{
            color: $white !important;
        }
        svg{  
            path{
                fill: $white;
            }
        }
    }

    .topic-number{
        background: $white;
        box-shadow: 0px 2px 5px rgb(128 128 128 / 34%);
        border-radius: 11px;
        padding: 2px 9px;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $blue;
        margin-left: 12px;
    }
}

.topic-wrapper .topic-title{
    margin-left: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: $charcoal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
}
.group-exam-filter{
    background: $white;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
    border-radius: 10px;
    padding:  16px 14px 12px;
    margin:8px 16px 0;
}
.paper-large-btn{
    box-shadow: 0 0 0 1px $bx-grey;
    background: $white;
    border-radius: 22px;
    padding: 8px 0;
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    svg{
      margin-right: 12px;
    }
    user-select:none;
    .paper-large-btn-txt{
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 2px;
        color: $blue;
    }
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 0 0 1px $bx-hover-grey;
      }
    } 
    &:active {
      box-shadow: none;
      background: $blue;
      .paper-large-btn-txt{
        color: $white !important;
      }
      svg{
        circle{
          stroke: $white;
        }
        path{
          stroke: $white;
          fill: $white;
        }
        rect{
            &:first-child{
              fill: $white;
            }
        }
      }
    }
}
.mcq-filter-layout{
    display: flex;
    border-radius: 18px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width:fit-content;
    height: 36px;
    box-shadow: 0 0 0 2px $blue;
    @media (pointer: fine) {
        &:hover{
          box-shadow: 0 0 0 1px $bx-hover-grey;
        }
    }
    &:active{
        background: $blue;
        color: $white;
        box-shadow: 0 0 0 2px $blue;
        .mcq-text-hover{
            color: $white !important;
        }
        svg{
          circle{
              fill: $white !important;
          }
          
          path{
              &:first-child{
                  fill: $white !important;
              }
              fill: $blue !important;
          }
        }
    }
    svg{
        margin:  8px 6px 8px 0px;  
    }
    .micro-arrow{
        margin: 8px 12px 8px 6px !important;
        transform:  rotate(360deg) !important;
        transition-duration: 0.3s;
    }
    .mcq-close-btn{
        height: 36px; 
        width: 36px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        z-index: 999;
        &:active {
          z-index: 999;
          box-shadow: none;
          background: $blue;
          circle{
            fill: $white;
          }
          path { 
            fill: $blue !important;
          } 
        }
    }
}
.mcq-filter-layout{
    display: flex;
    border-radius: 18px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width:fit-content;
    height: 36px;
    box-shadow: 0 0 0 1px $bx-grey;

    &.active {
        box-shadow: 0 0 0 2px $blue;

        @media (pointer: fine) {
            &:hover{
              box-shadow:0 0 0 2px $blue !important;
            }
        }
    }
}
.mcq-filter-clear-btn{
    height: 36px; 
    width: 36px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 9;
    right: 0;
    top: 0;
    cursor: pointer;
    margin-left:4px;
    position: absolute;
    svg{
        margin: 8px !important;
        cursor: unset;
            circle{
            stroke: unset !important;
            }
        path{
            fill:$white !important;
        }
        }
    @media (pointer: fine) {
        &:hover {
        background: #B2D4FC;
    }
    }
    &:active {
        box-shadow: none;
        background: $blue;
        circle{
        fill: $white;
        }
        path { 
        fill: $blue !important;
        } 
  }
}
.cookie-active-bar {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}
.disabled-checkbox {
    width: 20px;
    height: 20px;
    background: #E3E6E6;
    border-radius: 50%;
}