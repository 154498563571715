@font-face {
  font-family: 'Latin Roman';
  src: url('../assets/fonts/lmroman10-regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Latin Roman';
  src: url('../assets/fonts/lmroman10-italic.otf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Latin Roman';
  src: url('../assets/fonts/lmroman10-bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Latin Roman';
  src: url('../assets/fonts/lmroman10-bolditalic.otf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'List Numbered Circle';
  src: url('../assets/fonts/35DDA9_0_0.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CMU Serif Roman';
  src: url('../assets/fonts/CMU-Serif-Roman.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CMUSerif-Roman';
  src: url('../assets/fonts/CMU-Serif-Roman.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CMU Serif Roman';
  src: url('../assets/fonts/CMU-Serif-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CMUSerif-RomanSlanted';
  src: url('../assets/fonts/cmunsl.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CMUSerif-BoldNonextended';
  src: url('../assets/fonts/cmunrb.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CMU Serif Roman';
  src: url('../assets/fonts/CMU-Serif-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CMU Serif Roman';
  src: url('../assets/fonts/CMU-Serif-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'cmmi5';
  src: url('../assets/fonts/cmmi5.ttf');
}

@font-face {
  font-family: 'cmr5';
  src: url('../assets/fonts/cmr5.ttf');
}

@font-face {
  font-family: 'cmsy5';
  src: url('../assets/fonts/cmsy5.ttf');
}

@font-face {
  font-family: 'cmmi7';
  src: url('../assets/fonts/cmmi7.ttf');
}

@font-face {
  font-family: 'cmr7';
  src: url('../assets/fonts/cmr7.ttf');
}

@font-face {
  font-family: 'cmsy7';
  src: url('../assets/fonts/cmsy7.ttf');
}

@font-face {
  font-family: 'cmmi9';
  src: url('../assets/fonts/cmmi9.ttf');
}

@font-face {
  font-family: 'cmsy9';
  src: url('../assets/fonts/cmsy9.ttf');
}

@font-face {
  font-family: 'cmr10';
  src: url('../assets/fonts/cmr10.ttf');
}

@font-face {
  font-family: 'cmex10';
  src: url('../assets/fonts/cmex10.ttf');
}

@font-face {
  font-family: 'cmmi10';
  src: url('../assets/fonts/cmmi10.ttf');
}

@font-face {
  font-family: 'cmsy10';
  src: url('../assets/fonts/cmsy10.ttf');
}

@font-face {
  font-family: 'msam10';
  src: url('../assets/fonts/msam10.ttf');
}

@font-face {
  font-family: 'swtimes';
  src: url('../assets/fonts/swtimesn.ttf');
}

@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhvKKSTjw.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'OpenSans-Italic';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(https://fonts.gstatic.com/s/opensans/v15/mem6YaGs126MiZpBA-UFUK0ZdctGAK6b.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(https://fonts.gstatic.com/s/opensans/v15/memnYaGs126MiZpBA-UFUKWiUNhrIqWxjaPX.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
