@import 'styles/variables';
@import 'styles/mixins';

#desk-page {
  padding-bottom: 80px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;

  @include media(xs sm) {
    overflow: unset;
    -webkit-overflow-scrolling: unset;
    height: unset;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    height: 100%;
    padding-bottom: 120px;

    @include media(xs sm) {
      padding-bottom: 80px;
    }
  }

  @supports not (-webkit-overflow-scrolling: touch) {
    /* CSS for other than iOS devices */
  }

  .menu-container {
    display: flex;
  }
  .menu-title {
    font-size: 19px;
    font-weight: bold;
    color: $grey-light-2;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    padding-bottom: 10px;
    margin-right: 24px;
    &.active {
      border-bottom: 2px solid $turquoise;
      color: $turquoise;
    }
    &.edit-mode {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  .menu-separator {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background-color: #e4e6e6;
  }
}

.full-height {
  height: 100%;
}
