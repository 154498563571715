.exam-filter {
  display: flex;
  box-shadow: 0 0 0 1px #E3E6E6;
  background: #FFFFFF;
  border-radius: 18px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: fit-content;
  height: 36px;

  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 0 0 1px #C8CCCC;
    }
  }
  .filter-text{
    padding: 9px 0px 9px 16px;
  }

  .micro-arrow {
    margin: 8px 12px 8px 6px !important;
    // transform:  ${props => (props.filter && props.isMarging) ? "rotate(180deg)" : "rotate(360deg)"};
    transition-duration: 0.3s;
  }


  &:active {
    background: #378EF8;
    background-color: #378EF8 !important;
    box-shadow: none !important;
    .filter-text{
    color: #FFFFFF;
    }
    .active-filter-text{
      color: #FFFFFF;
    }

    svg {
      circle {
        fill: #FFFFFF;
      }

      path {
        &:first-child {
          fill: #FFFFFF !important;
        }

        fill: #378EF8 !important;
      }
    }

    .closeButton1 {
      height: 36px;
      width: 36px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      z-index: 999;
      box-shadow: none;
      background: #378EF8;

      svg {
        margin: 0;
      }

      circle {
        fill: #FFFFFF;
      }

      path {
        fill: #378EF8 !important;
      }
    }
  }

  .closeButton1 {
    height: 36px;
    width: 36px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 7;

    &:active {
      z-index: 999;
      box-shadow: none;
      background: #378EF8;

      circle {
        fill: #FFFFFF;
      }

      path {
        fill: #378EF8 !important;
      }
    }
  }

  .closeButton {
    height: 36px;
    width: 36px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 7;

    @media (pointer: fine) {
      z-index: 999;

      &:hover {
        background: #B2D4FC;
      }
    }

  }

  @media (max-width: 350px) {
    .filter-text{
      font-size:10px;
    }
  }
}


.filter-clear {
  height: 36px;
  width: 36px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 9;
  right: 0;
  top: 0;
  cursor: pointer;
  margin-left: 4px;
  position: absolute;

  // ${props => props.isEditMode && css`
  //   opacity: 0.5 !important;
  //   pointer-events: none !important;
  //   user-select: none !important;
  // `};
  svg {
    margin: 8px !important;
    cursor: unset;

    circle {
      stroke: unset !important;
    }

    path {
      fill: #FFFFFF !important;
    }
  }

  @media (pointer: fine) {
    &:hover {
      background: #B2D4FC;
    }
  }

  &:active {
    box-shadow: none;
    background: #378EF8;

    circle {
      fill: #FFFFFF;
    }

    path {
      fill: #378EF8 !important;
    }
  }
}
