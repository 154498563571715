@import "styles/mixins";
@import "styles/variables";

.edit-icon-wrapper {
  @media (hover:none) and (pointer:coarse) { 
    display: block;
    visibility: visible !important;
  }
}

.bookmark-icon-wrapper {
  @media (pointer: fine) {
    &:hover {
      background: $grey-light-8;
    }
  }
}

.empty-folder-screen{
  padding: 0 20px;
}