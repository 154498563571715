@import "styles/_mixins";
@import "styles/animations";

.store-page {
  padding: 0;
  position: relative;

  .overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    @include overlayFadeIn;
    z-index: 3;

    @include media(md lg xl) {
      width: 375px;
    }

    &.contained {
      position: absolute;
      width: auto;
    }
  }

  @include media(md lg xl) {
    height: 100%;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px 0 20px;
  }
  .header-container {
    transform: translate3d(0, 0, 0);
    &.collapsed {
      @include slideInAnimation;
      position: absolute;
      top: 0;
      width: 100%;
      background: #FFFFFF;
      z-index: 2;
      box-shadow: 0 0.5px 5px 0 rgba(62, 87, 85, 0.22);
    }
  }
  .header {
    background: white;
    padding-top: 49px;
    position: static;
    width: 375px;

    &.full-width {
      width: auto;
    }

    &.collapsed {
      padding: 0;

      .title {
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        color: #2d3838;
        width: 100%;
      }
    }
  }
  .picture-container {
    width: 40px;
    .profile-picture {
      width: 40px;
      height: 40px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .back-icon-container {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  .back-subject {
    padding-left: 7px;
    line-height: 20px;
    font-family: Helvetica, serif;
    font-size: 17px;
    color: #2EBCB4;
  }
  .page-header {
    color: #2EBCB4;
    font-size: 17px;
    padding: 16px 20px 14px 13px;
  }
  .store-items {
    padding: 20px 20px 70px;
    font-size: 15px;
    background: white;
  }
  .store-item {
    padding-bottom: 14px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 8px;
  }
  .store-item-title-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .store-item-title {
    display: inline-block;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.53;
    text-align: left;
    color: #2d3838;
    padding-bottom: 5px;
  }
  .store-item-purchased {
    display: inline-block;
    position: relative;
    height: 23px;

    & > svg {
      position: absolute;
      height: 100%;
    }
  }
  .item-flex {
    display: flex;

    .price-container {
      margin-left: 20px;
      min-width: 170px;
      flex-direction: column;
      justify-content: flex-start;
    }

    .price-item {
      width: 100%;
      margin-bottom: 5px;
    }
  }
  .store-item-subtitle {
    line-height: 1.27;
    text-align: left;
    color: #2d3838;
    padding-bottom: 3px;
  }
  .store-item-description-container {
    position: relative;
    line-height: 19.00px;
    margin-bottom: 10px;
  }
  .store-title-description {
    min-height: 2.54em;
    line-height: inherit;
    text-align: left;
    color: #919191;
    margin-bottom: 0;

    &.display-more {
      overflow-y: unset;
    }

    &.display-less {
      height: 2.54em;
      overflow-y: hidden;
    }
  }
  .store-overflow-button-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    font: inherit;
    color: #2EBCB4;
    cursor: pointer;
    z-index: 1;
    line-height: inherit;
    padding-left: 40px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 1) 100%);

    &.no-fade {
      background: white;
      padding: 0;
    }

    &.display-less {
      bottom: 0.1px;
    }
  }
  .price-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .price-item {
    width: 48%;
    height: 30px;
    border-radius: 15px;
    background-color: #ffffff;
    border: 1px solid #2EBCB4;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 7px 12px;
    color: #2EBCB4;
    cursor: pointer;
    transition: all .3s;
    box-shadow: 0 1px 3px 0 rgba(93, 116, 114, 0.35);
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 3px 5px 0 rgba(93, 116, 114, 0.35);
      }
    }
    &:active {
      background-image: linear-gradient(100deg, #48cea8, #4abad1);
      color: #FFFFFF;
    }

    &.button-added-to-card {
      box-shadow: none;
      background-image: linear-gradient(100deg, #48cea8, #4abad1);
      color: white;
      padding: 0;
      border: none;
    }
  }
  .added-to-card {
    margin: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .added-to-card-label {
    margin: auto;
    font-size: 14px;
    font-weight: bold;
  }
  .links-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 16px 20px 17px 13px;

    background-color: white;
    z-index: 1;

    &.shadow {
      box-shadow: 0 0.5px 5px 0 rgba(62, 87, 85, .22);
    }

    @include media-orientation(landscape) {
      @include media(xs sm) {
        display: none;
      }
    }

    @include media(md lg xl) {
      width: 375px;
    }
  }
  .menu-item {
    display: flex;
    align-items: center;
  }
  .small-title {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    display: none;

    &.visible {
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  .store-container {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background: white;
    transform: translate3d(0, 0, 0);

    @include media(md lg xl) {
      height: 100%;
    }

    @include media-orientation(landscape) {
      @include media(xs sm) {
        padding: 14px;
        overflow: hidden;
        height: auto;
      }
    }

    @include media(xs sm) {
      overflow: hidden;
      height: 100%;
    }

    &.bigger-topics-container {
      padding-top: 131px;
      @include media(xs sm) {
        padding-top: 14px;
      }
    }
  }
  .store-basket {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 -4px 12px 0 rgba(62, 87, 84, 0.29);
    max-height: 90%;
    overflow: auto;

    @include slideFromBottom(calc(100% - 60px), 'expanded');

    @include media(md lg xl) {
      width: 375px;
    }

    &.contained {
      width: 100%;
      position: absolute;
    }
  }
  .arrow-container {
    text-align: center;
    padding: 5px 5px 0 5px;
    cursor: pointer;
  }
  .basket-details {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    box-sizing: content-box;
    padding: 0 20px 9px;
    height: 30px;

    #store-icon {
      margin-left: 0;
    }
  }
  .number-of-items-container, .payment-container {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 1.27;
  }
  .payment-container {
    font-weight: bold;
  }
  .payment-button {
    height: 30px;
    width: 84px;
    border-radius: 15px;
    background-image: linear-gradient(109deg, #48cea8, #4abad1);
    padding: 7px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 15.7px;
    color: #fbfbfb;
    cursor: pointer;
    margin-left: 10px;
    box-shadow: 0 1px 3px 0 rgba(93, 116, 114, 0.35);
    transition: all .3s;
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 3px 5px 0 rgba(93, 116, 114, 0.35);
      }
    }

    &:active {
      color: #2EBCB4;
      background: #FFFFFF;
    }
  }
  .number-of-items {
    margin-left: 10px;
  }
  .expanded-basket {
    margin: 0 20px 20px;
    border-top: 1px solid #e3e6e6;
    font-size: 15px;

    &.hide-top-border {
      border-top: unset;
    }
  }
  .price-detail {
    border-bottom: 1px solid #e3e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 0;
  }
  .items-in-basket-container {
    border-bottom: 1px solid #e3e6e6;
    padding: 17px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .basket-info {
    font-size: 13px;
    line-height: 1.38;
    text-align: left;
    color: #a2a3a3;
    padding-top: 12px;
  }
  .select-duration-container {
    display: flex;
    align-items: center;
  }
  .number-selection {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
  .decrement {
    border: solid 1px #e5e5e5;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .increment {
    border: solid 1px #e5e5e5;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .incremention-button {
    padding: 10px;
    cursor: pointer;

    &:active {
      box-shadow: inset 0 0 3px 0 rgba(93, 116, 114, 0.35);
    }
  }
  .duration-selected {
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
    padding: 10px;
  }
  .subjects-container {
    .subject:first-child {
      margin-left: 10px;
    }
  }
}

.legal-page-highlight {
  color: $turquoise;
  font-weight: bold;
}
