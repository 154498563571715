@import 'styles/variables';
@import 'styles/mixins';

.profile-page {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .profile-page-header {
    background: $gradient-top-bottom;
    padding: 14px;
    color: white;
    font-size: 17px;
    height: 140px;
    position: relative;
  }

  .back-icon-container {
    display: inline-block;
    cursor: pointer;
  }

  .back-title {
    padding-left: 7px;
    line-height: 20px;
    font-family: Helvetica;
    vertical-align: top;
  }

  .profile-picture-container {
    position: absolute;
    bottom: -20px;
    left: calc(50% - 60px);
  }

  .profile-picture {
    width: 120px;
    height: 120px;
  }

  .edit-button {
    position: absolute;
    right: 0;
    top: 10px;
    background-color: white;
    border-radius: 50%;
    padding: 3px 4px 3px 3px;
  }

  .profile-page-content {
    padding: 34px 20px;
    font-size: 15px;
  }

  .items-separator {
    background-color: $grey-light;
    width: 100%;
  }

  .profile-page-subtitle {
    font-size: 13px;
    color: $grey-light-2;
    letter-spacing: 2px;
    font-weight: bold;
    line-height: 32px;
    align-items:center;
    justify-content:space-between;
  }

  .profile-page-item-container {
    position: relative;
    cursor: pointer;
  }

  .profile-page-item {
    font-size: 15px;
    line-height: 19px;
    color: #2D3838;
    padding: 16px 0;
    z-index: 3;
    position: relative;
  }

  .detailed-item {
    position: absolute;
    color: $grey-light-3;
    right: 0;
    top: 16px;
    z-index: 1;
    line-height: 19px;
  }

  .white-background {
    z-index: 2;
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 140px;
    bottom: 1px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 3%, #ffffff 21%, #ffffff 100%);
    pointer-events: none;
  }

  .profile-data {
    margin-right: 8px;
  }

  .extra-info {
    font-size: 13px;
    color: $grey-light-2;
    padding: 10px 0 33px 0;
    line-height: 16px;
  }

  .shadow {
    box-shadow: 0 6px 10px 0 rgba(62, 87, 85, 0.32);
    border-radius: 50%;

    .profile-avatar {
      font-size: 40px !important;
    }
  }

  .back-button {
    margin-left: 0;
  }

  .profile-notification {
    background: $light-blue;
    color: $white;
    font-size: 12px;
    padding: 0 10px 0 4px;
    font-weight: normal;
    letter-spacing: 0;
    border-radius: 13px;
    line-height: normal;
    height: 26px;
  }

  .login-with-mb {
    background: $white;
    font-size: 13px;
    color: #378EF8;
    letter-spacing: 0;
    text-align: center;
    border-radius: 22px;
    height: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    outline: none;
    justify-content: center;

    box-shadow: 0 0 0 1px #e3e6e6;

    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 0 0 1px #c8cccc;
      }
    }

    &:active {
      background: $blue;
      box-shadow: none;

      .login-with-mb-text {
        color: #FFFFFF;
      }
    }
  }

  .login-with-mb-text {
    color: $blue;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
  }

  .mb-china-logo {
    margin-left: 8px;
    display: flex;
    @include media(smalliPhone5) {
      display:none;
    }
  }
}
