@import 'styles/variables';

.close-btn {
    @media (pointer: fine) {
        &:hover {
            background-color: $grey-light-8;
        }
    }
    &:active {
        background-color: $grey-light-9;
    }
}
.subject-manager {
    @media (max-width: 991px){
        border: none;
        border-radius: 0;
        padding: 0 16px;
        max-width: initial;
        align-self: flex-start;
    }
}
.freetrialnotification-wrapper {
    & > div {
        @media (max-width: 480px){
            margin: 40px auto 0;
        }
        max-width: 347px;
        margin: 83px auto 0;
    }
}
@media (max-width: 1024px){
    .ribbon-wrapper {
        padding: 12px 18px 15px;
    }
}