@import "../pages.scss";
@import "../../styles/variables";

.login-page {
  @extend .page;
  padding: 14px 20px;

  .form-title {
    padding-top: 32px;
    font-size: 32px;
    color: #2C3838;
    font-weight: bolder;
  }

  .form-error-message {
    color: $error-color;
    padding: 12px 0;
    text-align: center;
    font-size: 12px;
  }

  .primary-button {
    margin-top: 22px;
    border-radius: 27px;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 2px;
    box-shadow: 0 1px 3px 0 rgba(93, 116, 114, 0.35);
    height: 54px;
    display: inline-flex;
    justify-content: center;
    transition: all .3s;
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 3px 5px 0 rgba(93, 116, 114, 0.35);
      }
    }
    &:active {
      background-image: linear-gradient(99deg, #3dad8d, #3d9bad);
      box-shadow: none;
    }
  }

  .forgot-container {
    margin-top: 20px;
    text-align: center;
    color: $grey-light-2;
    font-size: 13px;
    font-weight: 600;
  }

  .forgot-link {
    color: $turquoise;
    cursor: pointer;
  }
}
.login-first{
  font-size: 15px;
    color: #2D3838;
    -webkit-letter-spacing: 0px;
    -moz-letter-spacing: 0px;
    -ms-letter-spacing: 0px;
    letter-spacing: 0px;
    text-align: center;
    line-height: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.center-section{
  background: #FFFFFF;
  box-shadow: 0px 6px 20px rgb(62 87 85 / 20%);
  
  min-width: 347px;
  margin-top: 16px;
  border-radius: 10px;
  max-height: 183px;
  transition: max-height 0.3s ease-out;
}
.login-with-mb-button-wrapper{
  background: #FFFFFF;
    font-size: 13px;
    color: #378EF8;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    text-align: center;
    border-radius: 22px;
    height: 44px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    outline: none;
    box-shadow: 0 0 0 1px #e3e6e6;
}
.login-with-mb-btn-text{
  font-size: 13px;
   
    font-weight: bold;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    text-align: center;
    margin-left: 12px;
}
.login-with-mb-btn-text-inner{
  font-size: 13px;
   
    font-weight: bold;
    -webkit-letter-spacing: 0px;
    -moz-letter-spacing: 0px;
    -ms-letter-spacing: 0px;
    letter-spacing: 0px;
    text-align: center;
    text-transform:capitalize;
}
.login-bg{
  z-index: 0;
   
   background-repeat: no-repeat;
   width: 100%;
   height: 150px;
   background-size: cover;
   background-position: bottom center;
   
  @media (min-width: 1200px){
    height: 285px;
  }
  
  @media (min-width: 900px) and (max-width: 1200px){
    height: 200px;
  }
  
  @media (min-width: 700px) and (max-width: 900px){
    height: 165px;
  }
  
  @media (max-width: 700px){
    height: 150px;
  }
}
.center-section-wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    min-height: 100vh;
    margin: 0 auto;
}
.center-col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    flex-grow: 1;
}
.center-section-outer{
  // height: 287px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.landing-page-link-wrapper{
  margin: 0 auto;
    margin-top: 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 80px;
}
.landing-page-link-text{
  font-size: 15px;
    color: white;
    -webkit-letter-spacing: 0px;
    -moz-letter-spacing: 0px;
    -ms-letter-spacing: 0px;
    letter-spacing: 0px;
    text-align: center;
    line-height: 20px;
}
.padding-top-12{
  padding-top: 12px;
}
.landign-page-logo{
  margin: 0 auto;
    margin-top: 48px;
  
    display: flex;
}
.auth-login-text{
  font-size: 33px;
    color: #2D3838;
    font-weight: 700;
    -webkit-letter-spacing: 0px;
    -moz-letter-spacing: 0px;
    -ms-letter-spacing: 0px;
    letter-spacing: 0px;
    text-align: center;
    line-height: 45px;
}
.login-footer{
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
}
.login-footer-text{
  padding: 15px 0;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.login-footer-inner-text{
  font-size: 13px;
    color: #FFFFFF;
    text-align: center;
    line-height: 18px;
}
.auth-login{
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.mb-btn-wrapper{
  display: none;
}
.mb-btn-animation{
  display: block; 
   //transition: all .4s ease .15s;
}
.login-transition-height-259{
  max-height: 259px !important;
  transition: max-height 0.3s ease-out;
}
.login-transition-height-239{
  max-height: 239px !important;
  transition: max-height 0.3s ease-out;
}
.login-transition-height-283{
  max-height: 283px !important;
  transition: max-height 0.3s ease-out;
}
.auth-header-login-text{
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
 
}
.auth-header-login-active{
  color: $blue;
  pointer-events: unset !important;
}
.form-input-wrapper{
  margin-top: 13px !important;
}
.login-forgot-password{
    font-size: 13px;
    color: $blue;
    font-weight: 600;
    line-height: 18px;
    @media (pointer: fine) {
      &:hover {
        color: $hover-blue;
        pointer-events: unset !important;
      }
    } 
    &:active {
      color: $active-blue;
      pointer-events: unset !important;
    }
}
.active-login-btn{
    color: $blue;
    @media (pointer: fine) {
      &:hover {
        color: $hover-blue;
        pointer-events: unset !important;
      }
    } 
    &:active {
      color: $active-blue;
      pointer-events: unset !important;
    }
}
.inactive-login-btn{
    color: $bx-hover-grey;
    pointer-events: none;
}