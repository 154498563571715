@import "styles/variables";

.swal-overlay {
  background-color: rgba(#133956, .75);
}

.swal-modal {
  width: 280px;
  border-radius: 8px;
}

.swal-content {
  margin-top: 0;
  padding: 0;
  font-size: 100%;
}

.swal-button-container {
  width: 100%;
}

.swal-button {
  background-color: transparent;
  color: $turquoise;
  outline: 0;
  box-shadow: none;
  padding: 13px 0 17px 0;
  font-weight: 600;
  font-size: 17px;
  width: 100%;

  &:active {
    background-color: transparent;
  }

  &:focus {
    box-shadow: none;
  }
}

.swal-footer {
  border-top: 1px solid $grey-light;
  text-align: center;
  font-size: 17px;
  font-weight: 100;
  padding: 0;
  margin: 0;
}

.swal-button-container {
  margin: 0;
}

.swal-button {
  padding: 10px 0 17px 0;
  font-size: 17px;
  line-height: 19px;
}

.swal-notification {
  font-family: "Open Sans", sans-serif;
  padding: 0 13px;

  .swal-notification-image {
    padding: 20px 0 18px 0;
  }

  .swal-notification-title {
    font-size: 17px;
    line-height: normal;
    text-align: center;
    color: #2D3838;
    font-weight: 700;

    .highlighted {
      color: $turquoise;
      word-break: break-all;
      display: inline-block;

      .sentence-end-dot {
        color: #2D3838;
      }
    }
  }

  .swal-notification-text {
    font-family: "Open Sans", sans-serif;
    padding: 16px 0 20px 0;
    text-align: center;
    color: $grey-light-2;
    line-height: 18px;
    font-weight: 400;
    font-size: 15px;

    .swal-link {
      font-weight: 600;
    }

    .add-to-home-bottom-text {
      font-size: 13px;
      font-weight: 700;
      padding-top: 20px;
      margin: 0 -6px;
    }

    .add-to-home-icon {
      padding: 0 3px;
      vertical-align: bottom;
    }

    a {
      color: $turquoise;
    }
  }
}

.modal-tutorial-content {
  text-align: center;
}

.image-text-container {
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  .text {
    margin-left: 14px;
  }
}

.modal-tutorial-text {
  color: white;
  border-radius: 8px;
  border: solid 1px #ffffff;
  padding: 18px 14px;
  font-size: 15px;
  line-height: 1.2;
  margin-top: 10px;
  text-align: left;
  .title {
    padding-bottom: 16px;
    text-align: center;
  }
  &.less-padding {
    padding: 14px 8px;
  }
  &.swal-container {
    text-align: center;
    color: #a2a3a3;
    border: solid 1px #e3e6e6;
    margin: 13px;
    .title {
      color: black;
      font-weight: bold;
      font-size: 17px;
    }
  }
}

.notification-text {
  color: #a2a3a3;
  font-size: 13px;
  line-height: normal;
  padding: 0 25px 14px;
}

.swal-container {
  position: relative;
}

.swal-container:after,
.swal-container:before {
  content: '';
  display: block;
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  transform: translate(-50%, 0);
}

.swal-container:after {
  top: -20px;
  left: 50%;
  border-color: transparent transparent #fff transparent;
  border-width: 10px;
}

.swal-container:before {
  top: -24px;
  left: 50%;
  border-color: transparent transparent #e3e6e6 transparent;
  border-width: 12px;
}

.start-tutorial-button, .notification-button {
  font-size: 17px;
  text-align: center;
  color: #2ebcb4;
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
}

.notification-button {
  border-top: solid 1px #e6e6e6;
  font-weight: bold;

  &.thin-text {
    font-weight: 400;
  }
}

.swal-notification {
  &.start-tutorial-container {
    padding: 15px 0 0 0;
  }

  &.clear-margin {
    margin-bottom: -20px;
    padding: 0;
  }
}

.notification-content {
  padding: 18px 20px;
}

.notification-icon {
  margin-bottom: 18px;
}

.accept-button {
  border-top: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
}

.later-button {
  padding-bottom: 0;
}

.thin-text {
  font-weight: 400;
}

.extra-paragraph {
  color: black;
  font-weight: bold;
  &.add-space {
    padding-top: 10px;
  }
}

.single-button {
  border-bottom: none;
  padding-bottom: 0;
}

.swal-email-text {
  color: black;
  padding-top: 5px;
  font-weight: bold;
}
