#add-voucher-page {

  // margin-top: 49px;
  // padding: 14px 20px;
  .page-title {
    font: inherit;
    font-weight: inherit;
    color: #2d3838;
  }

  .form-input-wrapper {
    margin-top: 0 !important;
  }

  form>input {
    padding-top: 6px;
  }

  .profile-details-form {
    padding: 20px;
    padding-top: 52px;
  }

  .back-button {
    margin-left: 0;
  }

  .form-label {
    vertical-align: top !important;
  }

  .form-input {
    margin-top: -3px !important;
  }


}
