$viewport-extra-large: 1441px;
$viewport-large: 992px;
$viewport-medium: 893px;
$viewport-small: 450px;
$viewport-vsmall: 320px;
$viewport-vmediam: 568px;
$viewport-ixs: 650px;
$viewport-smedia6: 380px;

$turquoise-green: #48CFA8;
$turquoise-blue: #4ABAD1;
$turquoise-light: #EDF8FA;
$light-green1: #51D378;
$light-green2: #51D4B3;
$light-green3: #37D59A;
$light-green4: #80DCBA;
$light-green5: #3DA898;
$turquoise: #2EBCB4;


$grey-light: #e4e6e6;
$grey-light-2: #a2a3a3;
$grey-light-3: #919191;
$grey-light-4: #dfe2e2;
$grey-light-5: #DDE3E3;
$grey-light-6: #bec8c6;
$grey-light-7: #f3f3f3;
$grey-light-8: #EFF4F9;
$grey-light-9: #E5ECF2;
$grey-light-10: #b1b6b5;
$grey-light-11: #4b4d4d;
$grey-light-12: #e6e6e6;
$grey-light-13: #C4C7C7;
$grey-light-14: #898989;
$color-light-blue: #4A677E;
$color-overlay: #203d3c;
$color-dark-blue: #12547E;
$gradient-top-bottom: linear-gradient(225deg,#43DAF0 0%,#395BF8 100%);
$gradient-left-right: linear-gradient(225deg,#43DAF0 0%,#395BF8 100%);
$gradient-left-search-right: linear-gradient(225deg,#43DAF0 -2%,#395BF8 104%);
$gradient-search-left-right: linear-gradient(225deg, #43DAF0 10%, #395BF8 150%);
$left-right-gradient: linear-gradient(225deg,#43DAF0 0%,#395BF8 90%);
$gradient-bg-red-color: linear-gradient(135deg,#FC4B07 0%,#FC2666 100%);
$error-color: #FA2326;
$green: #82e52b;
$oranges: #f2a539;
$red: #f23e38;
$yellow: #f2e43c;
$purple: #9013fe;
$charcoal: #2d3838;
$dark-orange : #FC4B07;
$light-orange: #FA9000;
$light-orange2: #FBAE45;
$light-orange3: #D8820D;
$dark-pink : #FC2666;
$dark-pink2 : #FC1691;

$navigation-header-height: 52px;

$flashcard-max-width: 600px;
$flashcard-max-width-tablet: 550px;
$flashcard-width-mobile:86.5%;

$blue: #378EF8;
$hover-blue: #64A8FA;
$active-blue: #2170CF;
$white: #FFFFFF;
$light-blue: #6398E6;
$light-blue1: #B2D4FC;
$blue-x: #4B8FFA;

$bx-grey: #E3E6E6;
$bx-grey2: #E3E5E5;
$bx-hover-grey: #C8CCCC;


$default-grey: #D9DFE4;
$hover-grey: #C6CED4;
$light-grey: #E1E6E6;
$light-grey2: #A1ACB3;
$light-grey3: #B5BFC5;
$light-grey4: #8B969D;
$light-black: #585858;

$light-blue: #E6EEF7;

$exam-q-info-color: #979999;
$exam-mark-text-color: #727373;
$bx-key-short: 0 3.5px 0 0 #12547E;
$dark-orange: #FF8000;
$bg-light-grey: #F1F3F2;
$bg-dark-grey: #7E7E7E;
$light-black: #585858;

$orange: #FF9600;
$orange-hover: #FFB000;
$orange-active: #DF8B12;
$linear-gray: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #C6CED4 100%);
$linear-blue: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #378EF8 100%);
$linear-light-gray:linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #EFF4F9 100%);
$linear-notes:linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #E5ECF2 100%);
$gray-overlay: #122334;
$green: #95DBB0;
$dark-green: #51D484;
$dark-green2: #3EBF8F;
$dark-green3: #21AA77;


$rgba-light-grey: rgba(255, 255, 255, 0.5);
