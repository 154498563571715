@import 'styles/mixins';
@import 'styles/variables';

.reset-3c756112--container-960c7c26 {
  >div {
    overflow-wrap: unset !important;
    white-space: unset !important;
  }
}

.reset-3c756112--listItemContent-756c9114 {
  white-space: unset !important;
}

.blockParagraph-544a408c {
  white-space: pre-wrap !important;
}

.image-52799b3c {
  &:hover {
    cursor: zoom-in !important;
  }
}

.list-20526648 {
  list-style: revert !important;
}

/* The Modal (background) */
.img-modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #FFFF;
  z-index: 888;
  align-items: center;
  justify-content: center;
  // padding-bottom: 84px;
}

.image-modal-zoom {
  display: flex;
}

// Image zoom close button
.zoom-close-section {
  position: fixed;
  z-index: 4;
  top: 74px;
  right: 20px;
  display: flex;

  .pin-close {
    border-radius: 22px;
    align-self: center;
    cursor: pointer;
    background: rgba(32, 61, 60, 0.75);
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;

    &:active {
      background: #378EF8;

      .zoom-close-icon {
        opacity: 1 !important;

        path {
          fill: white;
        }
      }
    }

    @media (pointer: fine) {
      &:hover {
        .zoom-close-icon {
          opacity: 0.75;
        }
      }
    }
  }
}

// Image zoom with zoom slider
.zoom-section {
  position: fixed;
  z-index: 4;
  right: 20px;
  bottom: 84px;
  display: flex;

  // margin: 0 8px 8px 0;
  .pin-zoom {
    background: rgba(32, 61, 60, 0.75);
    mix-blend-mode: normal;
    //opacity: 0.75;
    border-radius: 22px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    cursor: pointer;

    &:active {
      background: #378EF8;

      .pinch-zoom-icon {
        opacity: 1 !important;

        path {
          fill: white;
        }
      }
    }

    @media (pointer: fine) {
      &:hover {
        .pinch-zoom-icon {
          opacity: 0.75;
        }
      }
    }
  }

  .mobile-pin-zoom {
    margin-right: 0 !important;
  }

  .zoom-factor {
    height: 36px;
    background: rgba(32, 61, 60, 0.75);
    mix-blend-mode: normal;
    //opacity: 0.75;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;

    .main-progress-zoom {
      .input-range {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 22px;
        height: 4px;
        width: 77px;
        display: flex;
        flex-direction: column;
        margin: 0 7px;
        justify-content: center;
        z-index: 3;
        -webkit-appearance: none;
        outline: none;

        &::-webkit-slider-thumb {
          appearance: none;
          background: #FFFFFF;
          width: 16px;
          height: 16px;
          box-shadow: 0px 2px 4px rgba(51, 61, 61, 0.992925);
          border-radius: 50%;
          cursor: pointer;
        }
      }

      .progress-zoom {
        // display: grid;
        // grid-template-columns: repeat(auto-fit,minmax(0,1fr));
        display: flex;
        align-items: unset;
        justify-content: space-between;

        .increse-zoom-button {
          transition-property: all;
          transition-duration: .15s;
          transition-timing-function: linear;
          transition-delay: 0s;
          position: relative;
          /* color: rgba(108,117,125,.7); */
          /* text-indent: -9999px; */
          border: none;
          background-color: transparent;
          align-items: center;
          justify-content: center;
          display: inline-flex;
          /* outline: none!important; */
          cursor: pointer;

          &:first-child:after {
            display: none
          }

          &:before {
            //position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            content: '';
            border-radius: 50%;
            background-color: #fff;
            box-sizing: border-box;
            z-index: 3;
          }
        }

        .active-zoom {
          &:before {
            background: #FFFFFF;
            width: 16px;
            height: 16px;
            box-shadow: 0px 2px 4px rgba(51, 61, 61, 0.992925);
          }
        }
      }
    }

    .decrease-zoom {
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      padding: 5px;

      &:active {
        background: #378EF8;
        border-radius: 22px;

        .minus-zoom-icon {
          opacity: 1 !important;

          path {
            fill: white;
          }
        }

        //opacity: 1;
      }

      @media (pointer: fine) {
        &:hover {
          .minus-zoom-icon {
            opacity: 0.75;
          }
        }
      }
    }

    .increase-zoom {
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      padding: 5px;

      &:active {
        background: #378EF8;
        border-radius: 22px;

        .plus-zoom-icon {
          opacity: 1 !important;

          path {
            fill: white;
          }
        }

        //opacity: 1;
      }

      @media (pointer: fine) {
        &:hover {
          .plus-zoom-icon {
            opacity: 0.75;
          }
        }
      }
    }

    .remove-increase-click {
      background: none !important;
      border-radius: 0;
      cursor: auto;
      user-select: none;

      &:hover {
        .plus-zoom-icon {
          opacity: 1;
        }
      }
    }

    .remove-decrease-click {
      background: none !important;
      border-radius: 0;
      cursor: auto;
      user-select: none;

      &:hover {
        .minus-zoom-icon {
          opacity: 1;
        }
      }
    }
  }
}

.buttons-container {
  position: absolute;
  height: 100%;
  width: 100%;
  //display: none;
  touch-action: manipulation;
  justify-content: space-between;
  display: flex;
  //width: calc(#{$flashcard-width-mobile} + 44px);
  //max-width:  720px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;

  @include media(xs sm) {
    touch-action: manipulation;
    justify-content: space-between;
    display: flex;
    //width: calc(70% + 44px);
    //max-width: 600px + 44px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}

/* Modal Content (image) */
.modal-contents {
  margin: auto;
  display: block;
  width: 75%;
  //max-width: 75%;
}

/* Caption of Modal Image */


/* Add Animation */
.modal-contents {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

.out {
  animation-name: zoom-out;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1)
  }

  to {
    -webkit-transform: scale(2)
  }
}

@keyframes zoom {
  from {
    transform: scale(0.4)
  }

  to {
    transform: scale(1)
  }
}

@keyframes zoom-out {
  from {
    transform: scale(1)
  }

  to {
    transform: scale(0)
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;

  &:hover {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-contents {
    width: 100%;
  }
}

.reset-3c756112--pageContainer-544d6e9c, .gitbook-root {
  margin: 0px auto;
  padding-bottom: 123px !important;

  @media screen and (max-width: 1023px) {
    padding-bottom: 83px !important;
  }
}

.carousel-wrapperw {
  display: flex;
}

.revision-title {
  font-weight: normal;
  font-size: 25px;
  line-height: 34px;
  color: #2D3838;
  padding-bottom: 14px;
  max-width: 750px;

  @media (min-width: 320px) and (max-width: 1023px) and (orientation: portrait) {
    padding-left: 20px !important;
    margin: 0 !important;
  }

  @media screen and (max-width: 1023px) {
    margin: 0px auto;
    padding: 0px 24px 24px;
  }

  @media (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
    padding-left: 20px !important;
  }

  @media screen and (min-width: 1024px) {
    margin: 0 86px;
  }

  @media screen and (max-width: 1023px) {
    margin: 0px auto;
    margin: 0 86px;
  }

  @media screen and (max-width: 913px) and (min-width: 500px) {
    margin: 0px auto;
    // padding-right: 8%;	
    // padding-left: 8%; 	
  }
}


// font-weight: normal;
//     font-size: 25px;
//     line-height: 34px;
//     color: #2D3838;
//     padding-bottom: 14px;
//     max-width: 750px; 
//     ${props => props.isMobilePortrait && css`
//         @media (min-width: 320px) and (max-width: 1023px) and (orientation: portrait){
//             padding-left: 20px !important ;  
//             margin: 0 !important;
//         }
//     `}
//     ${'' /* @media screen and (max-width: 880px) and (min-width: 500px){
//         margin: 0px auto;
//         padding-right: 8%;
//         padding-left: 4.5% !important; 
//     } */}
//     @media screen and (max-width: 1023px){
//         margin: 0px auto;
//         padding: 0px 24px 24px;
//     }
//     ${props => props.isLandscape && css`
//         @media (min-width: 320px) and (max-width: 900px) and (orientation: landscape){
//             padding-left: 20px !important;  
//         }
//     `}

.inner-skeleton-h {
  background-size: 400px 100% !important;
  // background-image: linear-gradient(270deg, #EFF4F9 0%, #EFF4F9 61.29%, #E0E9F0 72.52%, #DFE8F0 75.78%, #E1EAF1 79.6%, #EFF4F9 88.08%, #EFF4F9 100%) !important;
  margin-bottom: 4px;
  border-radius: 10px !important;
  height: 54px;
  // animation: animation-bzdot9 2s linear infinite !important;
}

.inner-skeleton-d {
  background-size: 400px 100% !important;
  // background-image: linear-gradient(270deg, #EFF4F9 0%, #EFF4F9 61.29%, #E0E9F0 72.52%, #DFE8F0 75.78%, #E1EAF1 79.6%, #EFF4F9 88.08%, #EFF4F9 100%) !important;
  margin-top: 12px;
  border-radius: 10px !important;
  height: 24px;
  // animation: animation-bzdot9 2s linear infinite !important;
}

.rg-react-loading-skeleton::after {
  background-image: linear-gradient(270deg, #EFF4F9 0%, #EFF4F9 61.29%, #E0E9F0 72.52%, #DFE8F0 75.78%, #E1EAF1 79.6%, #EFF4F9 88.08%, #EFF4F9 100%) !important;
  animation-timing-function: linear;
  animation-duration: 2s;
}

.revision-wrapper .renderer-wrapper {
  // @media only screen and (min-width: 650px) and (max-width: 1200px) and (orientation:portrait) {
  //   width: 100%;
  //   align-items: center;
  //   justify-content: center;
  //   display: flex;
  // }

  @media only screen and (max-width: 991px) {
    padding: 6px 0;
    width: 76.2%;

    @media(orientation: portrait) {
      width: 100%;
      padding: 0 0;
    }

    @media(orientation: landscape) {
      width: 86.5%;
      padding: 6px 0;
    }
  }
}

.revision-main-skeleton {
  padding: 16px 0px;
  max-width: 754px;

  @media (min-width: 1112px) and (max-width: 1320px) {
    padding: 16px 0px !important;
  }

  @media (min-width: 1024px) and (max-width: 1112px) {
    padding: 16px 0px !important;
  }

  @media (min-width: 320px) and (max-width: 1024px) {
    padding: 16px 14px !important;

  }

}

.revision-main {
  // padding: 24px 17px;
  width: 100%;
  @media (min-width: 1024px) and (max-width: 1112px)
  {
      padding: 24px 17px;
      max-width: 754px;
  }
}

.revision-main-navigation {
  @media (min-width: 1112px) and (max-width: 1320px) {
    padding: 24px 0 !important;
  }

  @media (min-width: 1024px) and (max-width: 1112px) {
    padding: 24px 0 !important;
  }
}
.revision-wrapper {
  .revision-bottom-controls {
    max-width: 754px; //709px;
    left: 0;
    right: 0;
    background: $white;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
    bottom: 62px;
    height: 59px;
    width: calc(100% - 28px);
    @include media(xs sm) {
      @include media-orientation(landscape) {
        padding: 0;
        bottom: 8px;
        max-width: calc(100% - 24px);
      }
    }
    @media (min-width: 821px) and (max-width: 1024px) {
      &.left-menu-visible {
        bottom: 126px
      }
    }
  }
}
