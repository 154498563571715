@import "styles/animations";

.modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10000;
  display: flex;
  align-items: center;
  @include fadeInAnimationComplete;

  > .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(32, 61, 60, 0.5);
    z-index: -1;
  }

  > .modal-content {
    width: 70%;
    max-width: 665px;
    height: 70%;
    max-height: 1000px;
    margin: 0 auto;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 12px 4px rgba(62, 87, 84, 0.29);
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }

  > .modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}
