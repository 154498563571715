@import 'styles/_mixins';
@import 'styles/variables';

.loader-wrapper{
    animation: spin 1.25s linear infinite;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    svg{
        width: 100%;
        height: 100%;
    }
}
.vsmall{
    width: 26px;
    height: 26px;
}
.small{
    width: 28px;
    height: 28px;
}
.mediumsmall{
    width: 31px;
    height: 31px;
}
.large{
    width: 54px;
    height: 54px;
}