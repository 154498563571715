@import 'styles/variables';

.esm-description {
    border-radius: 10px;
}

.page-title {
    font-size: 33px;
    line-height: 45px;
}

.toolbarbutton {
    font-size: 13px;
    line-height: 15px;
    border-radius: 18px;
    &.isActive {
        background: $gradient-top-bottom;
        box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
    }
}

.experience-realistic {
    font-size: 15px;
    line-height: 19px;
}

.fixedpagespacing {
    padding-bottom: 150px;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    overflow: overlay;
}

.left-active-tile {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    // width: 3px;
    // &.exam-bar {
    //     width: 4px;
    // }
}

.left-menu-exams {
    box-shadow: 0px 0px 0px 1px $light-grey;
    border-radius: 10px;
    &.paper-tile-click {
        box-shadow: 0px 0px 0px 1px $grey-light-6 !important;
    }
    @media (pointer: fine) {	
        &:hover {
            box-shadow: 0px 0px 0px 1px $grey-light-6;
        }
    }
    &:active:not(:has(.paper-tile:active)) {
        transition: all .2s;
        box-shadow: 0px 0px 0px 2px $blue;
    }
}

.exams-topic-title {
    font-size: 25px;
    line-height: 34px;
}

.paper-tile {
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1px $bx-grey;
    &.isActiveTile {
        box-shadow: 0px 10px 32px rgb(62 87 85 / 30%);
    }
    @media (pointer: fine) {
        &:hover {
            box-shadow: 0px 0px 0px 1px $grey-light-6;
        }
        &:hover.isActiveTile {
            box-shadow: 0px 0px 0px 1px $grey-light-6, 0px 10px 32px rgb(62 87 85 / 30%);
        }
    }
    &:active{
        transition: all .2s;
        box-shadow:  0px 0px 0px 2px $blue; 
    }
    &:active.isActiveTile {
        box-shadow: 0px 0px 0px 2px $blue, 0px 10px 32px rgb(62 87 85 / 30%);
    }
}

.paper-tile-text {
    font-size: 17px;
    line-height: 19.92px;
}

.paper-tile-meta-info {
    max-height: 70px;
    .heading  {
        width: 63px;
        margin-right:11px;
        .number {
            font-size: 17px;
            line-height: 23px;
            color: $charcoal;
        }
        .icon {
            width:17px;
            height:17px;
        }
        .title {
            font-size: 10px;
            line-height: 11px;
        }
    }    
}