@import 'styles/_mixins';
@import 'styles/variables';

.device-logout-btn{
    background: $white;
    box-shadow: 0 0 0 1px $bx-grey;
    border-radius: 26px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $blue;
    padding: 3px 7px 3px 10px;
    transition: all .2s;
    cursor: pointer;
    @media (pointer: fine) {
        &:hover {
          box-shadow: 0 0 0 1px $bx-hover-grey;
        }
    }
    &:active {
        background: $error-color; 
        box-shadow: none;	
        color: $white;	
    }
}