@import 'styles/mixins';

.notes-tooltip-container {
  width: 375px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 8px;
  background: white;
  padding-bottom: 150px;

  @include media(xs sm) {
    min-height: 100%;
  }

  .note-editor-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
}

.list-flashcards-preview-container {
  margin-top: 49px;
}

.notes-title-container {
  display: flex;
  align-items: center;
  .notes-title {
    margin-left: 10px;
  }
}
