@import "styles/_mixins";

.payment-view-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 -4px 12px 0 rgba(62, 87, 84, 0.29);
  min-height: 300px;
  padding: 0 12px;

  @include media(md lg xl) {
    width: 375px;
  }

  &.contained {
    width: auto;
    position: absolute;
  }

  .back-to-cart-container {
    cursor: pointer;
    display: inline-block;
  }

  .back-to-cart {
    padding-left: 7px;
    line-height: 20px;
    font-size: 17px;
    color: #2EBCB4;
    vertical-align: super;
  }

  .payment-options {
    padding: 0 8px;
  }

  .pay-with-title {
    padding-top: 30px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 106px;
    text-align: left;
    color: #a2a3a3;
  }

  .payment-option {
    height: 48px;
    border-radius: 6px;
    border: solid 1px #c8cccc;
    margin: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .payment-credit-card-title {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #b1b6b5;
    padding-right: 5px;
  }

  .credit-card-image {
    background: url(../../assets/images/store/CreditCard.png) no-repeat;
    height: 30px;
    width: 135px;
  }

  .paypal-image {
    background: url(../../assets/images/store/PayPal.png) no-repeat;
    height: 30px;
    width: 88px;
  }
}
