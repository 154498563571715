@import 'styles/variables';
@import 'styles/mixins';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.public-layout {
  position: relative;

  &.white-bg{
    background: white;
    display: flex;
    justify-content: center;
  }

  @include media(xs sm) {
    max-width: unset;
  }
}
