@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'styles/reset';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/fonts';



@keyframes slideItem {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes downsize {
  0% {}

  100% {
    padding: 0;
    max-height: 0;
    min-height: 0;
    height: 0;
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}


html,
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  //font-size: 10px;
  @include media(xs sm) {
    min-height: 100vh; //101vh
    -webkit-overflow-scrolling: touch;
  }

  .hidden {
    visibility: hidden;
  }

  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {

  &.white {
    background: #FFFFFF;
  }

  &.gradient,
  &.gradient-bg {
    background-image: linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
  }

  &.swal-no-overlay {
    .swal-overlay {
      background: transparent;
    }

    .swal-modal {
      width: auto;
      margin: auto;
      background-color: unset;
    }

    .swal-overlay--show-modal {
      pointer-events: none;
    }
  }
}

.text-center {
  text-align: center;
}

button {
  cursor: pointer;
  touch-action: manipulation;
}

.inline-block {
  display: inline-block;
}

a {
  cursor: pointer;
  color: inherit;
}

.ratio-1-1 {
  @include aspect-ratio(1, 1);
}

.vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;

}

.flex,
.flex-column,
.flex-center {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

input[type=date] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  padding-top: 4px !important;
  padding-left: 0 !important;
  line-height: 35px;
  font-size: 16px;
  margin: 0 !important;
  padding-left: 0 !important;
  ;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  background-color: white;
  outline: none;
  border: 0;
  border-radius: 0;
  color: black;
  height: 35px;
}

input[type="date"] {
  &::-webkit-datetime-edit {
    padding: 0;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    color: $turquoise;
  }
}

.bold {
  font-weight: bold;
}

input::-webkit-caps-lock-indicator {
  display: none;
  visibility: hidden;
  position: absolute;
  right: 0;
}

input::-webkit-credentials-auto-fill-button {
  display: none;
  visibility: hidden;
  position: absolute;
  background-color: red;
  color: red;
  right: 0;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input::-ms-clear {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input::-ms-reveal {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.no-scroll {
  @include media(xs sm) {
    overflow: hidden;
    width: 100%;
  }
}

.hidden {
  display: none;
}

.prevent-scroll {
  overflow: hidden !important;
}

.react-datepicker {
  font-family: "Open Sans", sans-serif !important;
  font-size: 12px !important;
}

.react-datepicker__header {
  padding-top: 0 !important;
  font-family: "Open Sans", sans-serif !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  top: 3px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  padding: 10px !important;
  font-size: 12px !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
  padding-top: 10px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 5px !important;
}

input[type="date"][required] {
  box-shadow: none;
}

.font-weight-bold {
  font-weight: 700;
}

.font-color {
  color: #919191;
}

.font-weight-light {
  font-weight: 300;
}

select[data-selected-placeholder='true'] {
  color: #c8cccb;
  font-weight: 100;
}

.text-with-icon .text {
  margin-left: 12px;
}

.flex-pull-right {
  margin-left: auto;

}

.transparent {
  background-color: transparent !important;
}

.full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.no-overflow {
  overflow: hidden;
}

.capitalize {
  text-transform: capitalize;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.active-epllise {
  @include media(xs sm) {
    @media(orientation: landscape) {
      &:after {
        content: '';
        width: 100%;
        height: 56px;
        // width:100%;
        // height:100%;    
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, #FFFFFF 70.71%); //
      }
    }

    @media(orientation: portrait) {
      &:after {
        content: '';
        width: 56px;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 0%, #FFFFFF 107.71%); //
      }
    }

    &.video-cc {
      @media(orientation: portrait) {
        &:after {
          content: '';
          width: 100%;
          height: 56px;
          // width:100%;
          // height:100%;    
          position: absolute;
          left: 0;
          bottom: 0;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 0%, #4A677E 70.71%) !important; //
        }
      }

      @media(orientation: landscape) {
        &:after {
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, #4A677E 70.71%) !important; //
        }
      }
    }
  }
}

mark.text-highlighting-mark {
  background: transparent;
  color: $blue;
  font-weight: bold;
}

.text-highlighting-span {
  background: rgba($blue, .3);
  z-index: 0;
  border-radius: 3px;
  //box-shadow: 0px 0px 0px 1px $blue;
  line-height: 0.7;
  display: inline-flex;
  padding-bottom: 3px;

  &.active {
    box-shadow: 0px 0px 0px 1px $blue,
      0px 2px 4px rgba(0, 0, 0, 0.5);
  }

  &.line-hieght-mark {
    line-height: 1;
  }
}

.text-highlighting-rect {
  fill: rgba($blue, .3);
  rx: 3;
  ry: 3;
  //stroke: $turquoise;

  &.active {
    stroke: $blue;
    stroke-Width: 0.8px;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(55, 142, 248, 0.8));
    filter: drop-shadow(0px 2px 4px rgba(55, 142, 248, 0.8));
  }
}

.active-epllises {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, #D9DFE4 100%);
    pointer-events: none;
  }
}

.exercise-comment {
  margin: 0 20px !important;

  @include media(vsm) {
    @media(orientation: portrait) {
      margin: 0 20px 10px 20px !important;
    }
  }
}

.exercise-select-tab {
  @include media(vsm) {
    @media(orientation: portrait) {
      top: 94px !important;
    }
  }
}

.exercise-header-all-tab {

  //Responsive fixed
  @include media(sm6) {
    @media(orientation: portrait) {
      padding: 5px 10px !important;
    }
  }
}

.exercise-header-select-tab {

  //Responsive fixed
  @include media(sm6) {
    @media(orientation: portrait) {
      padding: 5px 5px 5px 10px !important;
    }
  }
}

.execrise-filter-box {
  @include media(sm6) {
    @media(orientation: portrait) {
      svg {
        margin: 5px 6px !important;
      }
    }
  }
}

.execrise-filter-box {
  @include media(sm6) {
    @media(orientation: portrait) {
      height: 34px !important;

      svg {
        margin: 5px 0 5px 5px !important;
      }

      .execrise-filter-clear {
        svg {
          margin: 5px 5px 5px 5px !important;
          width: 30px !important;
          height: 30px !important;
        }
      }
    }
  }
}

.list-animation {
  animation: topPoistionAnimation 0.5s forwards !important;
}

@keyframes topPoistionAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-34px);
  }
}

@media screen and (max-width: 651px) {
  @keyframes topPoistionAnimation {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-52px);
    }
  }
}

@media screen and (max-width: 379px) {
  @keyframes topPoistionAnimation {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-80px);
    }
  }
}

.exams-video {
  .rc-slider-track {
    z-index: 9 !important;
    border-radius: 2px !important;
    margin-left: -11px !important;
  }

  .rc-slider-handle {
    margin-top: 0 !important;
    cursor: pointer !important;
    z-index: 9 !important;
  }


}

.toggle-menu-icon {
  @media (pointer: fine) {
    // &:hover{
    //     path, rect{
    //       stroke: #64A8FA;
    //     }
    //   }
  }

  &:active {
    // path, rect{
    //   stroke: #2170CF;
    // }
  }
}


.tv {
  color: white;
}

svg {
  display: inline;
  vertical-align: unset;
}


.studing-teaching-tag-mobile {
  display: flex;

  @include media(xs sm) {
    @media(orientation: landscape) {
      display: none;
    }
  }
}

.desktop-answer-assesstment {
  display: block;

  @include media(xs sm) {
    @media(orientation: landscape) {
      display: none;
    }
  }
}

.close-btn-wrapper {
  position: absolute;
  box-sizing: content-box;
  top: 14px;
  right: 14px;
  height: 20px;
  width: 20px;
  padding: 6px;
  border-radius: 20px;
  cursor: pointer;

  @media (pointer: fine) {
    &:hover {
      background-color: #EFF4F9;
    }
  }

  &:active {
    background-color: #E5ECF2;
  }
}

.quick-loader-wrapper {
  position: absolute;
  right: 10px;
}

// .close-btn-support {
//   position: absolute;
//   top: 8px;
//   right: 14px;
//   height: 32px;
//   width: 32px;
//   border-radius: 15px;
//   cursor: pointer;
//   padding: 6px;
//   box-sizing: border-box;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @media (pointer: fine) {
//     &:hover {
//       background-color: #EFF4F9 !important;
//     }
//   }

//   &:active {
//     background-color: #E5ECF2 !important;
//   }
// }

.subject-manager-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #378EF8;
  box-shadow: 0 0 0 1px #E3E6E6;
  border-radius: 26px;
  padding: 3px 8px 3px 10px;
  margin-left: 42px;
  user-select: none;
  cursor: pointer;

  @media (pointer: fine) {
    &:hover {
      background: #FFFFFF;
      box-shadow: 0 0 0 1px #C8CCCC;
    }
  }

  &:active {
    color: #FFFFFF;
    box-shadow: none;
    background: #378EF8;

    svg {
      circle {
        fill: #FFFFFF;
      }

      path {
        fill: #378EF8;
      }
    }
  }
}

.exams-tiles-new-tag {
  box-shadow: 0 0 0 1px #ff8000;
  border-radius: 6px;
  font-size: 12px;
  color: #FF8000;
  line-height: 16px;
  padding: 3px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-title {
  font-weight: bold;
  font-size: 33px;
  color: #2D3838;
  letter-spacing: 0;
  line-height: 45px;
}
.overlay {
  position: fixed;
  top: 156px;
  bottom: 0;
  width: 375px;
  background: rgba(27, 87, 137, 0.17854);
  z-index: 4;

  @include media(xs) {
    left: 0;
    right: 0;
    width: auto;
  }
}

.round-button {
  border-radius: 50%;
  width: 44px;
  min-width: 44px;
  height: 44px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:5px;
  box-shadow: 0 1px 3px 0 rgba(62,87,85,0.22);
  outline: none;
  pointer-events: all;
  &:focus {
    outline: none;
  }
  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 2px 6px 0 rgba(62,87,85,0.22);
    }
  }
  &:active {
    background-color: #C8CCCC;
    box-shadow: 0 0 0 0 rgba(62,87,85,0.22);
    outline: none;
  }
}
.tutorial-step-header-icons{
  .back-button{
    margin-left: 0px;
  }
}
.exercise-tab-wrapper.active{
  background: $gradient-top-bottom;
}

.media-type-tab-container {
  &.bg-gradient {
    background: linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
  }
}
.media-tab {
  &.after-content:after {
    content: '';
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(30, 76, 172, 0.514423);
    border-radius: 14px;
    height: 6px;
    min-width: 83px;
    margin-top: 6px;
    align-items: center;
    display: flex;
  }
}


.edit-btn-exercise-page {
  @media (pointer: fine) {
    &:hover {
      color: $hover-blue;
    }
  } 
  &:active {
    color: $active-blue;
  }
}

.int-ex-tile {
  @media (pointer: fine) {
    &:hover {
      box-shadow: 0px 0px 0px 1px #BEC8C6;
      .bar-line-bg{
        background: white;
      }
    }
  }
}

.esm-btn {
  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 0 0 1px #C8CCCC;
    }
  }
  &:active {
    box-shadow: none;
    background: #378EF8;
    .text-content{
      color: #FFFFFF;
    }
  }
}
.spacing-overlay{
  -webkit-overflow-scrolling: touch;
  overflow: overlay;
}

.load-more-btn {
  @media (pointer: fine) {
    &:hover {
      background-color: $hover-blue;
    }
  }
  &:active {
    background-color: $active-blue;
  }
}

.options-header{
  // margin-top: 14px;
  display: flex;
  align-items: center;
  padding: 5px 20px;

}
.options-headlight{
  background: #97A5B3;
  border-radius: 5px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
}
.options-text{
  margin-left: 12px;
  color: #2D3838;
  font-size: 15px;
  line-height: 19px;
  font-weight: 700;
  opacity: 0.9;
}
.question-info-icon-wrapper {
  @media (pointer: fine) {
    &:hover {
      background: $grey-light-8;
    }
  }

  &:active {
    background: $grey-light-9;
  }
}

.free-trial-notification {
  background-image: linear-gradient(225deg, #F0A143 0%, #F86839 100%);
  .button {
    border: 1px solid #FFCAA8;
    .free-trial-icon{
      margin-left: 10px;
    }
    @media (pointer: fine) {
      &:hover {
        border: 1px solid #FFFFFF;
      }
    }
    &:active {
      background: #FFFFFF;
      color: #F5973D;
      .free-trial-icon{
        rect{
          stroke: #F5973D;
        }
        path:nth-of-type(1){
          stroke: #F5973D;
        }
        path:nth-of-type(2){
          fill: #F5973D;
        }
      }
    }
  }

  .cont-btn {
    @media (pointer: fine) {
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 1;
        color:#FFFFFF;
        background:transparent;
        border: 1px solid #FFCAA8;
      }
    }
  }
}