@import 'styles/mixins';

.tool-tip-manager-wrapper {
  background: #ffffff;
  position: fixed;
  z-index: 0;
  border-radius: 6px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3));

  .navigation-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  @include media(xs sm) {
    top: 6px !important;
    bottom: 55px;

    @include media-orientation(portrait) {
      display: none;
    }
  }

  &.left {
    top: auto;
    bottom: auto;
  }

  .tool-tip-triangle {
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border-top: solid 14px #ffffff;
    border-left: solid 14px transparent;
    border-right: solid 14px transparent;
  }
}
