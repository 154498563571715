@import "styles/_mixins";
@import "styles/_variables";

.bar-line{
    height: 14px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: 1s ease-in width;
}
.bar-line-border{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.bar-line-bg-active{
    background: '#EFF4F9';
}
.bar-line-bg-active{
    background: '#EFF4F9';
}
.bar-border-active{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.bar-line-absolute{
    position: absolute;
    top:  0;
}