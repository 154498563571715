@import "styles/_mixins";
@import "styles/_variables";

.styledPreviewListHeader {
  @media (pointer: fine) {
    &:hover {
      background: $grey-light-8;
    }
  }
}

.sub-topic-tile {
  margin-left: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: $charcoal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.delete-animation {
  animation: slideItem .3s linear forwards, downsize .2s linear forwards;
  animation-delay: 0s, .3s;
}

.revision-preview-list {
  :not(:last-child):after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    margin: 0 20px;
    background: $grey-light-10;
  }

  @media (pointer: fine) {
    &:hover {
      background: $grey-light-8;
      .exam-other-info {
        &::before {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $grey-light-8 100%);
        }
      }
    }    
  }
}

.list-hover {
  @media (pointer: fine) {
    &:hover {
      background: $grey-light-8;
    }
  }
}

.active-list-hover {
  @media (pointer: fine) {
    &:hover {
      background: $grey-light-9;
    }
  }
}

.left-overlay-section {
  position: absolute;
  height: 76px;
  width: 7px;
  background: $white;
  border-radius: 10px 0 0 10px;
}

.right-overlay-section {
  position: absolute;
  height: 76px;
  width: 8px;
  background: $white;
  border-radius: 0 10px 10px 0;
  right: 0px;
}
.video-progress-bar{
  background: linear-gradient(135deg, #50D466 0%, #50D4A8 100%);
  height: 8px;
  margin-top:-12.5px;
  border-bottom-left-radius: 10px;
  position:relative;
}
.video-text{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  width: 140px;  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: $charcoal;
}

.exam-textellipse-active{
  .exam-other-info-textellipse{   
    &:before{ 
        background: $linear-notes;
    } 
  }
  .exam-search-textellipse {
    &:before{ 
      background: $linear-notes;
    } 
  }
  .exam-editMode-other-info-textellipse{
    &:before{ 
      background: $linear-notes;
    } 
  }

    
  @media (pointer: fine) {
    &:hover {
      background: $grey-light-9;
      .exam-other-info-textellipse{   
        &:before{ 
            background: $linear-notes;
        } 
      }
      .exam-search-textellipse {
        &:before{ 
          background: $linear-notes;
        } 
      }
      .exam-editMode-other-info-textellipse{
        &:before{ 
          background: $linear-notes;
        } 
      }
    }
  }
}
.exam-textellip {
  @media (pointer: fine) {
    &:hover {
      background: $grey-light-8;
      .exam-other-info-textellipse {
        &:before {
          background: $linear-light-gray;
        }
      }
      .exam-search-textellipse {
        &:before {
          background: $linear-light-gray;
        }
      }
      .exam-editMode-other-info-textellipse {
        &:before {
          background: $linear-light-gray;
        }
      }
    }
  }
}
.exam-mark-text{

  display: flex;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  align-items: center;
  font-family: "Open Sans";
}
.exam-other-info{
  font-size: 13px;
  color: $exam-q-info-color;
  line-height: 16px;
  .text-highlighting-mark{
    color: $exam-q-info-color;
    font-weight: normal;
  }
}
.more-item{
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all .2s;
  color: $blue;
    @media (pointer: fine) {
      &:hover {
        color: $hover-blue;
        .more-icon{
            path#Back-Chevron {
              fill: $hover-blue;
            }
        }
      }
    } 
    &:active {
      color: $active-blue;
      .more-icon{
        path#Back-Chevron {
          fill: $active-blue;
        }
      }
    }
}

.exam-preview-list-header {
  .small-exam-icon {
    box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
  }
  
  .title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &.not-subtopic {
    @media (pointer: fine) {
      &:hover {
        background: $grey-light-8;
      }
    }
  }
}