@import "./variables";

@mixin fadeInAnimation() {
  animation: fadeIn 0.35s
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@mixin fadeInAnimationComplete() {
  animation: fadeInComplete 0.35s
}

@keyframes fadeInComplete {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin fadeOutAnimationComplete() {
  animation: fadeOut 0.35s
}
@keyframes fadeOut {
  0% {
    display: initial!important;
    opacity: 1;
  }
  99%{
    display: initial!important;
  }
  100% {
    display: none!important;
    opacity: 0;
  }
}

@mixin shakeRtlAnimation() {
  animation: shakeRtl 0.8s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes shakeRtl {
  10%, 90% {
    transform: translate3d(1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(-2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(-4px, 0, 0);
  }
}

@mixin shakeLtrAnimation() {
  animation: shakeLtr 0.8s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes shakeLtr {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@mixin slideInAnimation() {
  animation: slideIn .3s;
}

@keyframes slideIn {
  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes overlayFadeIn {
  0% {
    background-color: rgba(27, 87, 137, 0.17854);;
  }

  100% {
    background-color: rgba(27, 87, 137, 0.17854);;
  }
};

@mixin overlayFadeIn() {
  animation: overlayFadeIn .35s;
  background-color: rgba(27, 87, 137, 0.17854);;
}
