@import 'styles/_mixins';
@import 'styles/variables';
.navigation-round-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    min-width: 44px;
    height: 44px;
    border-radius: 50%;
    margin: 5px;
    background: $default-grey;
    box-shadow: none;
    outline: none;
    pointer-events: all;
    z-index: 2;
    @media (pointer: fine) {	
        &:hover {	
          background: $hover-grey;	
          box-shadow: none;
        }	
    }	
    &:active {	
        background: $blue; 
        .triangle-left{	
          path{	
            stroke: $white;	
          }	
        }	
        .nex-5-icon{	
          path{	
            fill: $white;	
          }	
        }	
        .prev-5-icon{	
          path{	
            fill: $white;	
          }	
        }	
        .triangle-right{	
          path{	
            stroke: $white;	
          }	
        }	
    } 
}
.nav-small-btn{
    width: 34px;
    min-width: 34px;
    height: 34px;
    margin: 4px;
}

.prev-navigation-bottom {
  border-radius: 50%;
  width: 44px;
  min-width: 44px;
  height: 44px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: $default-grey;
  box-shadow: none;
  outline: none;
  z-index: 2;
  cursor: pointer;
  box-shadow: 0px 0px 0px $bx-grey;

  @media (pointer: fine) {
    &:hover {
      background: $hover-grey;
      box-shadow: 0px 0px 0px rgba(62, 87, 85, 0.22);
      .active-epllises {
        &:after {
          background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, $hover-grey 100%);
        }
      }
    }
  }

  &:active {
    background: $blue;
    color: $white;

    .active-epllises {
      &:after {
        background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, $blue 100%);
      }
    }

    svg {
      path {
        stroke: $white;
      }
    }
  }
  svg{
    vertical-align: inherit; 
  }
}

.next-navigation-bottom {
  border-radius: 50%;
  // width: 44px;
  min-width: 44px;
  // height: 44px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: $default-grey;;
  box-shadow: none;
  outline: none;
  pointer-events: all;
  z-index: 2;
  cursor: pointer;
  user-select: none;

  @media (pointer: fine) {
    &:hover {
      background: $hover-grey;
      box-shadow: 0px 0px 0px rgba(62, 87, 85, 0.22);

      .active-epllises {
        &:after {
          background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, $hover-grey 100%);
        }
      }
    }
  }

  &:active {
    background: $blue;
    color: $white;

    .exam-end-navigation {
      color: $white;
    }

    .active-epllises {
      &:after {
        background: linear-gradient(90deg, rgba(217, 223, 228, 0.0001) 0%, $blue 100%);
      }
    }

    svg {
      path {
        stroke: $white;
      }
    }
  }
  svg{
    vertical-align: inherit; 
  }
}

.exam-bottom-navigation {
  border-radius: 22px;
  box-shadow: 0px 0px 0px $bx-grey;
  justify-content: center;
  padding: 0px;
}

.exam-end-navigation {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: bold;
  color: $grey-light-2;
  padding: 9px 25px
}