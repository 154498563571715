@import 'styles/_mixins';
@import 'styles/variables';

.page-overlay{
    background: $white;
    position: fixed;
    bottom: 0%;
    right: 0;
    z-index: 101;
    left: 0;
    width: 100%;
    @include slideFromBottom(200%);
    &.support-animation {
        display: none;
        top: 0;
        left: unset;
        width: 375px;
        //transform: none;
        //@include slideFromRight(100%);
    }
}
.device-container{
    top: 0 !important;
}
.overlay-wrapper{
  height: 100%;
  @include media(phone) {
    padding: 0;
    overflow: auto;
  }
}
.overlay-close-btn{
    height: 20px;
    width: 20px;
    background: $blue;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
   
    @include media(phone) {
        box-shadow: none;
        top: 16px;
        right: 16px;
        border-radius: 100px;
        height: 20px;
        width: 20px;
        z-index: 3;
    }
}
.close-btn-support{
  position: absolute;
  top: 8px;
  right: 20px;
  height: 32px;
  width: 32px;
  border-radius: 15px;
  cursor: pointer;
  padding: 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (pointer: fine) {
    &:hover{
      background-color: $grey-light-8 !important;
    }
  }
  &:active{
    background-color: $grey-light-9 !important;
  }
}