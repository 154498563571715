@import 'styles/mixins';
@import 'styles/variables';
.carousel-wrapperw{
  display: flex;
}
.revision-wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  @include media(xs sm) {
    @include media-orientation(landscape) {
      top: 0;
      width: $flashcard-width-mobile;
    }
  }
  
  .revision-inner-wrapper{
    // position: absolute;
  }
  // background: #FFFFFF;
  // height: calc(100% - 158px);
  .revision-contain {
        background: #FFFFFF;
        display: flex;
        position: absolute;
        width: 100%;
        height: calc(100% - 54px);
        // top: 104px;
        // bottom: 158px;
        padding-top: 128px;
        padding-bottom: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        justify-content: center;

        .img-modal {
          max-width: calc(1440px - 64px);
          left: auto;
         } 
         .zoom-close-section {
          position: absolute;
         }
         .zoom-section {
          position: absolute;
         }

         
        @include media(xs sm) {
          @include media-orientation(landscape) {
            top: 0;
            padding-top: 0;
            bottom: 59px;
            height: calc(100% - 63px);
            overflow-x: hidden;
          }
        }

  }
  .revision-search {
    bottom: 139px;
    position: fixed;
    display: flex;
    align-items:center;
    justify-content: center;
    width: 100%;
    // @include media(xs sm) {
    //   @include media-orientation(landscape) {
        position: absolute !important;
        width:fit-content !important;
        right: 16px !important;
        bottom: 12px !important;
    //   }
    // }
  }
  .revision-bottom-controls {
    position: absolute;
    bottom: 62px;
    height: 59px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    background-color: #EFF4F9;
    box-shadow: 0px -1px 0px #e3e6e6;
    @include media(xs sm) {
      @include media-orientation(landscape) {
        padding: 0;
        bottom: 0;
      }
    }
  }
}
.video-wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  background: #122334;
  color: #FFFFFF;
  @include media(xs sm) {
    @include media-orientation(landscape) {
      top: 0;
      width: $flashcard-width-mobile;
    }
  }
  .video-contain {
        // background: #FFFFFF;
        display: flex;
        position: absolute;
        width: 100%;
        max-height: calc(100% - 265px);
        top: 104px;  
        overflow-x: auto;
        justify-content: center;
        @include media(xs sm) {
          @include media-orientation(landscape) {
            top: 0;
            bottom: 59px;
            max-height: calc(100% - 59px);
            overflow-x: hidden;
          }
        }
  }
  .video-search {
    bottom: 125px;
    position: fixed;
    display: flex;
    align-items:center;
    justify-content: center;
    width: 100%;
  }
  .vedio-player-section{
    //height: unset !important;
    //aspect-ratio: 3/2;
    position: relative;
    @media (min-width: 1200px){
      width: 100% !important;
    }
  }
  .rc-slider-handle{
      margin-top: 0 !important;
      //transform: translateX(0%) !important;
      cursor:  pointer !important;
      z-index: 9 !important;
      &:active{
        box-shadow: none !important;
      }
  }
  .rc-slider-rail{
    border-radius: 2px !important;
  }
  .rc-slider-track{
    z-index: 9 !important;
    border-radius: 2px !important;
    margin-left: -11px !important;
  }
  .video-bottom-controls {
    position: absolute;
    bottom: 62px;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    @include media(xs sm) {
      @include media-orientation(landscape) {
        padding: 0;
        bottom: 0;
        max-width: calc(100% - 24px);
      }
    }
  }
}
.video-play{
  @include media(xs sm) {
    @include media-orientation(landscape) {
      background-color: white;
    }
  }
 
}
.carousel-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;

  @include media(md lg xl) {
    overflow: visible;
  }

  @include media(xs sm) {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    //orientation css
    @media(orientation: portrait){
      overflow: visible;
      width: 100%;
      position: absolute;
      top: 126px;
      height: auto;
      //position: relative;
    }
    
    //end
  }
  @include media(vsm) {
    @media(orientation: portrait){
      top: 146px;
    }
  }
  .carousel {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    left: -100%;
    touch-action: none;
    
    @include media(xs sm) {
      height: 100%;
    }

    &.is-reversing {
      transform: translateX(-100%);
    }

    @media (min-height: 900px) and (min-width: 800px) {
      margin-top: 50px;
    }

    @media  (max-height: 900px)  {
      margin-top: 20px;
    }
    @media (max-height: 880px) {
      margin-top: 40px;
    }
    @media (max-height: 850px) {
      margin-top: 70px;
    }
    @media (max-height: 815px) {
      margin-top: 65px;
    }
    @media (max-height: 792px) {
      margin-top: 40px;
    }
    @media (max-height: 700px) {
      margin-top: 80px;
    }
    @media (max-height: 670px) {
      margin-top: 90px;
    }
    @media (max-height: 600px) {
      margin-top: 0px;
    }
    @media (max-width: 768px) {
      @media(orientation: portrait){
        margin-top: 50px;
      }
      @media(orientation: landscape){
        margin-top: 0px;
      }
    }
    @include media(vsm) {
      @media(orientation: portrait){
        margin-top: 0;
      }
    }

  }

  .carousel-seat {
    flex: 1 0 100%;
    order: 2;
    position: relative;
    // background: transparent;
    // transform-style: preserve-3d;
    @include media(xs sm) {
      //orientation css
      @media(orientation: portrait){
        //min-height: 400px;
      }
      //end
    }
    @include media(md lg xl) {
      
    }
  }
  .landscape-buttons-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: none;
    top: 0;
    @include media(xs sm) {
      touch-action: manipulation;
      justify-content: center;
      display: flex;
      width: calc(#{$flashcard-width-mobile} - 8px);
      //max-width: 600px + 44px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
    .landscape-flashcard-nav-button{
      z-index: 4;
      user-select: none;
    }
    .next-flashcard-button{
      right: 0;
      position: absolute;
      height: calc(100% - 128px);
      display: flex;
      align-items: center;
      background: #D9DFE4;
      border-radius: 100px;
      &:active{
        background: #378EF8;
        .triangle-right{
          path{
            stroke: #FFFFFF;
          }
        } 
      }
    }
    .prev-flashcard-button{
      left: 0;
      position: absolute;
      height: calc(100% - 128px);
      display: flex;
      align-items: center;
      background: #D9DFE4;
      border-radius: 100px;
      &:active{
        background: #378EF8;
        .triangle-left{
          path{
            stroke: #FFFFFF;
          }
        } 
      }
    }
  }
  .buttons-container {
    position: absolute;
    height: 100%;
    width: 100%;
    //display: none;
    touch-action: manipulation;
    justify-content: space-between;
    display: flex;
    //width: calc(#{$flashcard-width-mobile} + 44px);
    //max-width:  720px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    @include media(xs sm) {
      touch-action: manipulation;
      justify-content: space-between;
      display: flex;
      //width: calc(70% + 44px);
      //max-width: 600px + 44px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
  .flashcar-bottom-control{
    //position: absolute;
    width: 100%;
    touch-action: manipulation;
    justify-content: space-between;
    display: flex;
    //width: calc(#{$flashcard-width-mobile} + 44px);
    max-width:  720px;
    margin: 0 auto;
    left: 0;
    right: 0;
    flex-direction: column;
    .pre-next-button{
      width: 25%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .show-answer-button{
      z-index: 3;
    }
    .landscape-pre-next-button{
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    @include media(xs sm) {
      @media(orientation: portrait){
        padding: 0 6px;
      }
    }
    @media only screen and (min-width: 650px) and (max-width: 1200px) {
      max-width:  625px;
    }
    @media only screen and (min-height: 600px) and (max-height: 775px) {
      max-width:  515px !important;
    }
    @media only screen and (min-height: 775px) and (max-height: 792px) {
      max-width:  580px !important;
    }
  }
}
.hover{
  @media (pointer: fine) {
    background: #C6CED4 !important;
  }
}
.click{
  color: #FFFFFF !important;
  background: #378EF8 !important;
  .triangle-left{
    path{
      stroke: #FFFFFF;
    }
  }
  .nex-5-icon{
    path{
      fill: #FFFFFF;
    }
  }
  .prev-5-icon{
    path{
      fill: #FFFFFF;
    }
  }
  .triangle-right{
    path{
      stroke: #FFFFFF;
    }
  } 
  .flip-card{
    path{
      stroke: #FFFFFF;
    }
    .filp-path{
      fill: #FFFFFF;
    }
  } 
}
.search-section{
  @media (min-width: 600px) and (max-width: 1111px) and (orientation: portrait) {
    display: block !important;
  }
  @include media(xs sm) {
    display: none;
  }
}
.search-section-small{
  display: none;
  margin-top: 0;
  @media (min-width: 600px) and (max-width: 1111px) and (orientation: portrait) {
    display: none !important;
    margin-top: 0;
  }
  @include media(xs sm) {
    display: block;
    margin-top: -40px;
  }
  @include media(vvsm) {
    @media(orientation: portrait){
      margin-top: -40px;
    }
  }
}
.track {
  background-color: #FFFFFF;
  border-radius: 2px;
  height: 4px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 3;
  bottom: 0;
  /* margin: 0; */
  /* opacity: 0; */
  /* position: absolute; */
}
.thumb {
  appearance: none;
  background: #50D3A6;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  z-index: 8;
}
.activeControl{ 
  display: block;
}

.is-cardInfo-scroll-down{
  animation: smoothScroll 1s forwards !important;
  @media(orientation: portrait){
    animation: smoothScrollMobile 1s forwards !important;
  }
  @media only screen and (min-width: 450px) and (max-width: 892px) and (orientation: landscape){
    animation: unset !important;      
  }
}
.is-bottom-menu-scroll-down{
  animation: smoothScrollBottom 1s forwards;
  @media only screen and (min-width: 450px) and (max-width: 892px) and (orientation: landscape){
    animation: unset !important;      
  }
}
.is-cardInfo-scroll-up{
  animation: smoothScrollUp 1s forwards !important;
  @media(orientation: portrait){
    animation: smoothScrollUpMobile 1s forwards !important;
  }
}
.is-bottom-menu-scroll-up{
  animation: smoothScrollBottomUp 1s forwards;
}
@keyframes smoothScrollMobile {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-122px);
    
	}
}
@keyframes smoothScroll {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-134px);
    
	}
}
@keyframes smoothScrollBottom {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(98px);
	}
}
@keyframes smoothScrollUp {
	0% {
		transform: translateY(-104px);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes smoothScrollUpMobile {
	0% {
		transform: translateY(-122px);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes smoothScrollBottomUp {
	0% {
		transform: translateY(98px);
	}
	100% {
		transform: translateY(0);
	}
}
.exam-wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  max-width: 709px;
  @include media(xs sm) {
    @include media-orientation(landscape) {
      top: 0;
      width: $flashcard-width-mobile;
    }
  }
  
  .revision-inner-wrapper{
    // position: absolute;
  }
  // background: #FFFFFF;
  // height: calc(100% - 158px);
  .exam-contain {
        background: #FFFFFF;
        display: flex;
        position: absolute;
        width: 100%;
        height: calc(100% - 54px);
        // top: 104px;
        // bottom: 158px;
        padding-top: 128px;
        padding-bottom: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        justify-content: center;
        @include media(xs sm) {
          @include media-orientation(landscape) {
            top: 0;
            padding-top: 0;
            bottom: 59px;
            height: calc(100% - 59px);
            overflow-x: hidden;
          }
        }

  }
  .revision-search {
    bottom: 139px;
    position: fixed;
    display: flex;
    align-items:center;
    justify-content: center;
    width: 100%;
  }
  .revision-bottom-controls {
    position: absolute;
    bottom: 62px;
    height: 59px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    background-color: #EFF4F9;
    box-shadow: 0px -1px 0px #e3e6e6;
    @include media(xs sm) {
      @include media-orientation(landscape) {
        padding: 0;
        bottom: 0;
        left: 8px;
        right: 8px;
      }
    }
  }
}

.flashcard-answer-btn{
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $grey-light-2;
  cursor: pointer;
  background: $default-grey;
  border-radius: 22px;
  padding: 9px 25px;
  //transition: all .2s;
  user-select: none;
  @media (pointer: fine) {
    &:hover {
      background: $hover-grey;
    }
  }
  &:active {
    color: $white !important;
    background: $blue;
  }
  @include media(phone) {
    padding: 9px 22px;
  }
  @include media(smalliPhone5) {
    font-size: 10px;
    padding: 9px 20px;
  }
}

.flashcard-render-wrapper{
  // 991
  @media only screen and (max-width: 991px){
    padding: 6px 0;
    width: 76.2%;
    @media(orientation: portrait){
      width: 100%;
      padding: 0 6px;
    }
    @media(orientation: landscape){
      width: 86.5%;
      padding: 6px 0;
    }
  }
  @media only screen and (min-width: 650px) and (max-width: 1200px) and (orientation:portrait) {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

@media(orientation: landscape){
  @media (max-width: 1024px), (max-height: 819px) {
    .hidden-btn {
      display: none !important;
    }

    .search-navigation {
      justify-content: center;
    }
  }
}
