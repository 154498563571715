@import 'styles/variables';
@import 'styles/mixins';

.more-btn {
    @media (pointer: fine) {
        &:hover {
            path {
                stroke: $grey-light-14;
            }
        }
    }

    &:active {
        path {
            stroke: $light-green5;
        }
    }
}

.link-btn {
    @media (pointer: fine) {
        &:hover {
            path {
                stroke: $grey-light-14;
                fill: $grey-light-14;
            }
        }
    }

    &:active {
        path {
            stroke: $light-green5;
            fill: $light-green5;
        }
    }
}

.q-info {
    @media (pointer: fine) {
        &:hover {
            background: $grey-light-8;
        }
    }

    &:active {
        background: $grey-light-9;
    }
}

.filter-box {
    &.filter {
        svg {
            circle {
                stroke: '#378EF8';
            }

            path {
                fill: '#378EF8';
            }

            margin: '10px 5px 10px 5px';
            cursor: 'auto';
        }
    }

    .filter-clear {
        svg {
            margin: 5px 0 5px 0;
            cursor: unset;

            circle {
                stroke: unset !important;
            }

            path {
                fill: #FFFFFF !important;
            }
        }

        @media (pointer: fine) {
            &:hover {
                background: #B2D4FC;
            }
        }

        &:active {
            background: #378EF8;

            circle {
                fill: #FFFFFF;
            }

            path {
                fill: #378EF8 !important;
            }
        }
    }
}
.card-info-status{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;
    height: 44px;
    .card-info-assessment{
      display: flex;
      align-items: center;
      .flashcard-nr {
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        color: #2D3838;
        mix-blend-mode: normal;
        margin-right: 12px;

        &.text-white {
          color: #FFFFFF;
        }
      }
    }
    .card-info-bookmark{
      display: flex;
    }
}
.exam-status{
    height: 44px !important;
}
.desktop-answer-assesstment {
    display: block;
    @include media(xs sm) {
      @media(orientation: landscape) {
        display: none;
      }
    }
}
.qeustion-info{
    box-sizing: border-box;
    border-radius: 22px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: min-content;
    margin-right: 0px;
    cursor: pointer;
    padding: 8px;
    user-select: none;
    @media (pointer: fine) {
    &:hover {
        background: #EFF4F9;
    }
    }
    &:active {
        background: #E5ECF2;
    }
}
.study-more-btn{
    box-sizing: border-box;
    border-radius: 22px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 4px;
    cursor: pointer;
    user-select: none;
    padding: 6px;
    border-radius: 50%;
    @media (pointer: fine) {
        &:hover {
        background: #EFF4F9;
        }
    }
    &:active {
        border: none;
        background: #E5ECF2;
    }  
    @include media(phone) {
        @media(orientation: landscape) {
            right: 8px;
            left: auto;
            margin-top: 0;
            margin-left: 0;
            top: 0;
            position: absolute;
        }
    }  
}
.study-bookmark-btn{
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    @include media(phone) {
        @media(orientation: landscape) {
        right: 8px;
        left: auto;
        margin-top: 0;
        margin-left: 0;
        top: 0;
        position: absolute;
        }
    }
}
.studying-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    top: 54px;
    position: relative;
    &.exam-renderer {
      background-color: #FFFFFF;
    }
    @include media(xs sm) {
      @media(orientation: landscape){
        top: 0;
      }
    }
    @media(orientation: landscape){
      &.video {
        background-color: #122334;
      }
    }
    .card-info-section {
        width: calc(100% - 28px);
        margin-top: 8px;
        max-width: 754px; //709px;
        z-index: 2;
        position: absolute;
        top: 0;
        align-items: center;
        justify-content: center;
        height: 104px;
        //padding: 0 20px;
        background-color: #EFF4F9;
        @include media(xs sm) {
          @media(orientation: landscape){
            width: 0%;
            height: auto;
            padding: 0;
            position: relative;
          }
        }
        @include media(vsm) {
          @media(orientation: portrait){
            height: 122px;
            max-width: calc(100% - 16px);   
          }
        }
        
    }
    .flashcard {
      height: 120px !important;
      background: #FFFFFF;
      box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
      border-radius: 10px;
      @include media(vsm) {
        @media(orientation: portrait){
          // height: 104px !important;
          height: 120px !important;
        }
      }
    }
    .revision{
      height: 120px !important;
      background: #FFFFFF;
      box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
      border-radius: 10px;
      @include media(vsm) {
        @media(orientation: portrait){
          height: 113px !important;
        }
      }
    }
    .video{
      height: 120px !important;
      background: #4A677E;
      box-shadow: 0px 6px 20px rgba(6, 10, 26, 0.557173);
      border-radius: 10px;
      @include media(vsm) {
        @media(orientation: portrait){
          height: 113px !important;
        }
      }
    }
    .exams{
      height: 120px !important;
      // box-shadow: 0px 1px 0px #E3E6E6;
      box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
      background: #FFFFFF;
      border-radius: 10px;
  
      @include media(vsm) {
        @media(orientation: portrait){
          // height: 118px !important;
          // box-shadow: 0px 1px 0px #E3E6E6;
          box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
  
        }
      }
    }
    .assessment-height {
      @include media(vsm) {
        @media(orientation: portrait){
          // height: 132px !important;
          height: 142px !important;
        }
      }   
    }
    .assessment-dec-height{
      @include media(vsm) {
        @media(orientation: portrait){
          -webkit-transition: height 1s ease-in-out;
          -moz-transition: height 1s ease-in-out;
          -o-transition: height 1s ease-in-out;
          transition: height 1s ease-in-out;
          height: 118px !important;
        }
      }
    }
    .card-info-status{
      // height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 14px 0 20px;
      height: 44px;
      .card-info-assessment{
        display: flex;
        align-items: center;
        .flashcard-nr {
          font-weight: bold;
          font-size: 15px;
          line-height: 19px;
          color: #2D3838;
          mix-blend-mode: normal;
          margin-right: 12px;
  
          &.text-white {
            color: #FFFFFF;
          }
        }
      }
      .card-info-bookmark{
        display: flex;
      }
    }
    .exam-status{
      height: 44px !important;
    }
    .studying-wrapper-overlay-loader{
      background: #EFF4F9;
      position: fixed;
      left: 0;
      right: 0;
      top:0;
      bottom: 0;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .menu-animation{
      background-color: none;
    }
    
    .answer-statistics-content{
      display: none !important;
      position: absolute;
      top: 54px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .flashcard-nr{
        min-width: 43px;
        background: #FFFFFF;
        border-radius: 18px;
        margin-right: 8px;
        font-weight: bold;
        font-size: 19px;
        line-height: 26px;
        text-align: center;
        color: #2D3838;
        padding: 5px 16px;
      }
      &.viewport-mobile{
        display: block !important;
        position: absolute;
        bottom: 12px;
        top: unset;
        @media(orientation: landscape){
          height: 48px;
          display: flex !important;
          justify-content: flex-end;
          flex-direction: column;
        }
        .flashcard-nr{
          background: none;
          margin-right: 0px;
          font-weight: 600;
          font-size: 17px;
          text-align: center;
          justify-content: center;
          @media(orientation: landscape){
            padding: 0;
            margin-top: 5px;
            color: #2D3838;
            font-size: 15px;
            line-height: 19px;
            font-weight: bold;
            
          }
          &.video-text-white{
            color: #FFFFFF;
          }
        }
      }
    }
    .active-tools {
      margin-bottom: 10px;
    }
    // margin-bottom: 0px;
    @include media(xs sm) {
      //justify-content: space-between;
      @media(orientation: landscape){
        align-items: unset;
  
        .subjects-manager.hide{
          display: none;
        }
  
        .active-tools{
          display: none;
          margin: 10px auto;
        }
      }
    
    }
  
    .content {
      height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
  
      .title {
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        padding: 13px;
      }
  
      .subjects-changer {
        padding-top: 0;
      }
    }
  
    .right-side-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 58px;
      margin: 24px 0 24px auto;
      z-index: 3;
      // &.mobile-landscape{
      //   margin-bottom: 0;
      // }
      @include media(xs sm) {
        @media(orientation: landscape){
          touch-action: manipulation;
          position: relative;
          z-index: 2;
          margin: 8px 0 8px auto;
          background: #FFFFFF;
          box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.22);
          border-radius: 10px 0px 0px 10px;
          padding-top: 16px;
          width: 48px;
          &.carousel-video-wrapper{
            background-color: #4A677E;
          }
        }
        @media(orientation: portrait){
          touch-action: manipulation;
          position: relative;
          z-index: 0;
        }
      }
    }
  
    .subjects-manager {
      z-index: 3;
      background-color: white;
      width: 375px;
      border-radius: 8px;
      height: 100%;
      overflow: hidden;
  
      @include media(md lg xl) {
        display: none;
      }
  
      transition: all 0.35s cubic-bezier(.25, .75, .5, 1);
      webkit-backface-visibility: hidden;
  
      .last-card-studied-container {
        margin: 0;
      }
  
      .content {
        padding-top: 49px;
      }
    }
  
    .background-block {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3;
    //   @include overlayFadeIn
    }
  
    .close-menu-button {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 40px;
      width: 40px;
      cursor: pointer;
    }
  
    .links-button {
      min-height: 44px;
      margin-bottom: 10px;
  
      @include media(xs sm) {
        @media(orientation: landscape){
          min-height: 56px;
        }
      }
  
      .round-button {
        @include round-button-with-shadow;
        height: 44px;
        width: 44px;
        padding: 10px;
  
        @include media(xs sm) {
          @media(orientation: landscape){
            height: 56px;
            padding: 6px;
            width: 34px;
            border-radius: (34px / 2);
          }
        }
  
        img {
          width: 100%;
        }
      }
    }
  }

  @include media(lg xl) {
    .studying-wrapper {
      flex-direction: column;
  
      .active-tools {
        margin-bottom: 0;
        margin-right: 22px;
      }
      
      .right-side-wrapper {
        width: auto;
        position: absolute;
        justify-content: center;
        right: 1.2rem;
        margin: 0;
        //height: calc(50% + 14rem);
        bottom: 10rem;
        touch-action: manipulation;
        .answer-flashcard-wrapper {
          margin-top: auto;
        }
      }
  
      .answer-statistics {
        //max-height: 180px;
        flex-grow: unset;
        margin-bottom: 0;
        svg { 
          width: 26px;
          height: 26px;
        }
        .answer-inner-container{
          padding: 4px;
          width: 140px;
          height: 44px;
          flex-direction: row;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .answer-line {
          margin-bottom: 0;
          flex-grow: unset;
        }
        .answer-lines-container {
          margin-top: 0;
          padding: 0;
          flex-direction: row;
        }
      }
      
    }
  }

  .styled-search-prev-button{
    width: 36px;
    height: 36px;
    background: #FFFFFF;
    box-shadow: 0px 10px 32px rgba(62, 87, 85, 0.257293);
    margin: 0;
    @media (pointer: fine) {	
      &:hover {	
        background: #D8E2E2;	
        box-shadow: 0px 10px 32px rgba(62, 87, 85, 0.257293);
      }	
    }
    &:active {	
      background: #378EF8;	
      .prev-search-svg{
        circle{
          fill: #FFFFFF;
        }
        path{
          fill: #378EF8;
        }
      }
    }
  }
  .clear-search-button{
    width: 36px;
    height: 36px;
    margin: 0;
    box-shadow: 0px 10px 32px rgba(62, 87, 85, 0.257293);
    @media (pointer: fine) {	
      &:hover {	
        background: #D8E2E2;	
        box-shadow: 0px 10px 32px rgba(62, 87, 85, 0.257293);
      }	
    }
    &:active {
      background: #FC3C2C;	
      .clear-search-svg{
        circle{
          fill: #FFFFFF;
        }
        path{
          fill: #FFFFFF;
        }
      }
    } 
  }

  .styled-search-next-button{
    width: 36px;
    height: 36px;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 10px 32px rgba(62,87,85,0.257293);
    @media (pointer: fine) {	
      &:hover {	
        background: #D8E2E2;	
        box-shadow: 0px 10px 32px rgba(62, 87, 85, 0.257293);
      }	
    }
    &:active {	
      background: #378EF8;	
      .next-search-svg{
        circle{
          fill: #FFFFFF;
        }
        path{
          fill: #378EF8;
        }
      }
    } 
  }
  .full-height {
    height: 100%;
    @media only screen and (max-width: 767px) and (max-height: 844px) {
    // @media (max-width: 1024px), (max-height: 576px) {
      &.carousel-fc {
        margin-top: 80px;
      }
    }
  }