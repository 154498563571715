@import "styles/_mixins";

#topics-page {
  height: 100%;
  overflow-x: hidden;
  .topics-container {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    &:after {
      content: "";
      height: 80px;
      display: block;

      @supports (-webkit-overflow-scrolling: touch) {
        /* CSS specific to iOS devices */
        height: 120px;

        @include media(xs sm) {
          height: 80px;
        }
      }
    }

    @include media(md lg xl) {
      height: 100%;
    }

    @include media-orientation(landscape) {
      @include media(xs sm) {
        overflow: hidden;
        height: auto;
        padding-top: 0;
      }
    }

    @include media(xs sm) {
      overflow: hidden;
      height: 100%;
    }

    &.bigger-topics-container {
      padding-top: 131px;
      @include media(xs sm) {
        padding-top: 14px;
      }
    }
  }
}
.topic-fixed-space{
  padding-top: 56px;
  padding-bottom: 150px;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  scroll-behavior:smooth;
  overflow:overlay;
}
.media-tab [type=radio] {
  display: none;   
}
.media-tab input.flashcard:checked ~ .content .flashcard-content,
.media-tab input.revision:checked ~ .content .revision-content,
.media-tab input.vedio:checked ~ .content .vedio-content{
    z-index: 100;
    opacity: 1;
    transition: all ease-out 0.2s 0.1s;
}
// .carousel__slides {
//   position: relative;
//   overflow: hidden;
//   margin-top:8px;
//   .carousel-card {
//       width: calc(100% - 54px);
//       height: 226px;
//       cursor: move;
//       cursor: -webkit-grab;
//       cursor: grab;
//       touch-action: pan-y;
//       -webkit-overflow-scrolling:touch;
//       white-space: nowrap;
    
//   }
// }
// .carousel__slide { 
//   display: inline-flex;
//   box-shadow: rgb(62 87 85 / 20%) 0px 6px 20px;
//   border-radius: 10px;
//   background: #FFFFFF;
//   flex-direction: column;
//   img{
//     height: 40px;
//   }
// }
// .card-2 {
//   margin-left: 14px;
// }
.carousel__slides {
  position: relative;
  overflow: hidden;
  user-select: none;
 }
  .carousel__slides .carousel-card {
    margin-top: 8px;
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
    touch-action: pan-y;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap; }

.carousel__slide {
  width: calc(100% - 54px);
  height: 226px;
  display: inline-flex;
  border-radius: 10px;
  background: #FFFFFF; 
  flex-direction: column;
  box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.2);
  img{
    height: 40px;
  }
  
}
.card-2 {
  margin-left: 14px; 
}
.mediaTab-image{
  user-select: none;
}
.topic-number{
  background:#FFFFFF;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
  border-radius: 26px;
  padding: 3px 8px 3px 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #378EF8;
  margin-left: 12px;
}

.sub-topic-number {
  box-shadow: 0 0 0 1px #e3e6e6;
  cursor: pointer;
  @media (pointer: fine) {
    &:hover {
      background: #FFFFFF;
      box-shadow: 0 0 0 1px #c8cccc;
    }
  }

  &:active {
    background: #378EF8;
    color: #FFFFFF;
    box-shadow: none;
  }
}
.sub-topics-wrapper{
  text-transform: uppercase;
  background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
  border-radius: 97px;
  padding: 2px 8px 2px 10px;
  margin-left: 8px;
  user-select: none;
}