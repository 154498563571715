@import 'styles/variables';
@import 'styles/mixins';
.popup-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(26,109,172,0.60);
    z-index: 6;
    max-width: 1440px;
    margin: auto;
}