@import 'styles/variables';
@import 'styles/mixins';

.max-width-container {
  left: calc(max(calc(100% - 1440px), 0px) / 2);
}

.gray-background-hover {
  @media (pointer: fine) {
    &:hover {
      background: $grey-light-8;
    }
  }

  &:active {
    background: $grey-light-9;
  }
}

.toggle-menu-button {
  svg {

    rect,
    path {
      stroke: $grey-light-2;
    }
  }
}

.svg-rotate-0 {
  svg {
    transform: rotate(0deg);
  }
}

.bottom-line-border {
  box-shadow: 0px 1px 1px $bx-grey;
}

.osc-home-padding {
  padding: 12px 20px 12px 10px;
}

.lm-toggle-icon {
  padding: 6.5px 10px;
  height: 32px;
}



.subject-manager-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $blue;
  box-shadow: 0 0 0 1px $bx-grey;
  border-radius: 26px;
  padding: 3px 8px 3px 10px;
  margin-left: 42px;
  user-select: none;
  cursor: pointer;

  @media (pointer: fine) {
    &:hover {
      background: $white;
      box-shadow: 0 0 0 1px $bx-hover-grey;
    }
  }

  &:active {
    color: $white;
    box-shadow: none;
    background: $blue;

    svg {
      circle {
        fill: $white;
      }

      path {
        fill: $blue;
      }
    }

  }
}

.year-wrapper {
  border: 1px solid $bx-hover-grey;
  flex-shrink: 0;
}