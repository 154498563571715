@import 'styles/variables';

#coming-soon {
  text-align: center;
  color: $grey-light-2;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 18px 0;

  .coming-soon-icon {
    width: 72px;
    height: 72px;
  }

  &.tooltip-coming-soon {
    width: 375px;
    height: 306px;
  }

  .coming-soon-title {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .coming-soon-subtitle {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 7px;
  }
  .coming-soon-content {
    font-size: 15px;
    line-height: 17px;
  }
}
