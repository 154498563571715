@import 'styles/_mixins';
@import 'styles/_animations';


.filter-main-container {
  overflow: auto;
 }

::-webkit-scrollbar {
  display:none;
}


.filter-heading{
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  color: #2D3838;
}

.filter-close-icon-box {
  // position: absolute;
  // top: 15px;
  // right: 20px;
  cursor: pointer;
  padding: 6px;
  border-radius: 16px;

  @media (pointer: fine) {
    &:hover {
      background-color: #EFF4F9;
    }
  }
  &:active {
    background: #E5ECF2;
  }
}
 
.filter-close-icon{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #378EF8;
  // margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  // @media (pointer: fine) {
  //   &:hover {
  //     background: #64A8FA;
  //     }
  // } 
  // &:active {
  //     background: #2170CF;
  // }
}


.filter-toggle-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 10px 20px 10px 20px;
}

.filter-toggle-heading{
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #2D3838;
}

.filter-toggle-icon-box{

}
.filter-toggle-icon{

}
.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #C4C7C7;
  border-radius: 15.5px;
  transition: .4s;
}
.opacity-5{
  opacity:0.5;
  user-select: none;
  cursor: default;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  bottom: -3px;
  left: 0;
  transition: .4s;
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.0510643), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
}

input:checked + .slider {
  background: #95DBB0;
}

input:checked + .slider:before {
  background: #51D484;
  left: -2px;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.0510643), 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05);
}

input:focus + .slider {
  box-shadow: 0 0 1px #95DBB0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.toggle-active{
  transform: translateX(26px);
}

.toggle-inactive{
  transform: translateX(26px);
}
/* Rounded sliders */

.slider.round:before {
  border-radius: 50%;
}

.filter-checkbox-icon {
  padding-right: 16px;
}

.filter-options-container {
  display: flex;
  align-items: center;
}

.filter-title {
  padding-right: 8px;
  font-size: 15px;
  line-height: 19px;
  color: #2D3838;
}
