@import 'styles/variables';

.primary-button {
  width: 100%;
  background: $gradient-left-right;
  padding: 15px;
  font-size: 14px;
  color: white;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  outline: none;
  position: relative;

  &.empty {
    background: white;
    color: $turquoise;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
  }
  .arrow {
    margin-left: 15px;
  }
  .button-content{
    display: inline-flex;
  }
}
