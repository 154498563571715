@import 'styles/variables';
@import 'styles/mixins';

.flashcard-list-wrapper{
    outline: none;
    @include media(iFrameMobile) {
        padding-left: 4px;
        padding-right: 4px;
    }
}
.left-menu-spacing{
    position: fixed;
    left: calc(max(calc(100% - 1440px), 0px) / 2);
    transition: left 0.35s cubic-bezier(0.25, 0.75, 0.5, 1);
    right: 0;
    z-index: 1;
    top: 56px;
    max-width: 1440px;
}
.left-menu-has-spacing{
    max-width: 1064px;
    left: calc(calc(max(calc(100% - 1440px), 0px) / 2) + 376px);
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      left: 375px !important;
    }
}
.round-btn-shadow{
    box-shadow: rgba(62, 87, 85, 0.32) 0px 2px 6px 0px !important;
}
.tab-header-wrapper{
    display: flex;
    justify-content: center;
    padding: 12px 0 12px;
    background-color: #EFF4F9;
    transition: box-shadow .15s linear;
}
.tab-header-shadow{
    box-shadow: 0 0.5px 5px 0 rgba(62,87,85,0.22);
}
.tab-header{
    padding: 5px 17px 5px 17px;
    box-sizing: border-box;
    text-align: center;
    align-items: center;
    line-height: 18px;
    height: 28px;
    font-size: 13px;
    cursor: pointer;
    letter-spacing: 2px;
    background:linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
    border-radius: 14px;
}
.tab-header-active{
    box-shadow: 0px 1px 3px rgba(93, 116, 114, 0.35) !important;
}
.tab-header-inactive{
    background: white !important;
    color:#A2A3A3;
}
.exercise-header-all-tab {
    @include media(sm6) {
      @media(orientation: portrait) {
        padding: 5px 10px !important;
      }
    }
}
.tab-select{
    margin-left: 7px;
    border-radius: 10px;
    font-size: 13px;
    color: #FFFFFF;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    letter-spacing: 0px;
    min-width: 20px;
    height: 20px;
}
.execrise-filter-box {
    @include media(sm6) {
      @media(orientation: portrait) {
        height: 34px !important;
  
        svg {
          margin: 5px 0 5px 5px !important;
        }
  
        .execrise-filter-clear {
          svg {
            margin: 5px 5px 5px 5px !important;
            width: 30px !important;
            height: 30px !important;
          }
        }
      }
    }
}
.style-tabs{
    position: fixed;
    top: 60px;
    bottom: 0;
    // left: 0;
    left: calc(max(calc(100% - 1440px), 0px) / 2);
    max-width: 1440px;
    box-shadow: 0 10px 32px rgb(62 87 85 / 26%);
    right: 0;
    transition: left 0.35s cubic-bezier(0.25,0.75,0.5,1);
}
.style-tabs-spacing{
    max-width: 1064px;
    left: calc(calc(max(calc(100% - 1440px), 0px) / 2) + 376px);
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      left: 375px !important;
    }
    @include media(iFrameTablet) {
        left: 0;
    }
    @include media(phone) {
        left: 0;
    }
}
.tab-header-wrapper .filter-box{
    box-sizing: border-box;
    border-radius: 18px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    height: 36px;
    cursor: pointer;
    position: absolute;
    right: 0;
    svg{
        cursor: unset;
        margin:  8px 0 8px 0;
    }  
}
.fliter-box-active{
    background: #E6EEF7;
    border: 2px solid $blue;
    svg{
        cursor: unset;
          circle{
            stroke: $blue !important;
          }
        path{
          fill: $blue !important;
        }
        margin: 5px 6px 5px 17px !important;
    }  
}
.fliter-box-inactive{
    @media (pointer: fine) {
        &:hover {
          background: #ebeff3;
        }
    }
    &:active {
        border: none;
        background: #E5ECF2;
    }
    height:38px;
    padding:6px;
    border-radius: 50%;
}
.filter-clear-icon{
    width: 32px;
    height:32px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    svg{
        margin: 8px 6px 8px 6px !important;
        circle{
                stroke: unset !important;
            }
        path{
                fill:#FFFFFF !important;
            }
        }
    @media (pointer: fine) {
        &:hover {
            background: #B2D4FC;
        }
    }
    &:active {
        background: #378EF8;
        circle{
            fill: #FFFFFF;
        }
        path { 
            fill: #378EF8 !important;
        } 
    }
}
.execrise-filter-box {
    @include media(sm6) {
      @media(orientation: portrait) {
        height: 34px !important;
  
        svg {
          margin: 5px 0 5px 5px !important;
        }
  
        .execrise-filter-clear {
          svg {
            margin: 5px 5px 5px 5px !important;
            width: 30px !important;
            height: 30px !important;
          }
        }
      }
    }
  }
  .exercise-comment {
    margin: 0 20px !important;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #919191;
    padding: 8px 12px;
    -webkit-flex-flow: wrap;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    margin-bottom: 10px;
    visibility: block;
    @keyframes smoothScroll {
      0% {
        visibility: visible;
      }
      100% {
        visibility: hidden;
      }
    }
    @include media(vsm) {
      @media(orientation: portrait) {
        margin: 0 20px 10px 20px !important;
      }
    }
  }
  .exercise-comment-animation{
    animation: smoothScroll 0s forwards !important;
  }