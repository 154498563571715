@import 'styles/mixins';
@import 'styles/variables';

.card-wrapper {
    .renderer-wrapper {
        overflow: auto;
    }

    &.active {
        .renderer-wrapper {
            pointer-events: auto;
            overflow-x: hidden;

            @media (max-width: 550px) {
                overflow: hidden;
            }
        }

        .flipper {
            box-shadow: 0 1px 3px 1px rgba(128, 128, 128, 0.18), 0 0 0 2px $blue;
        }
    }

    .buttons-container {
        position: absolute;
        height: 100%;
        width: 100%;
        touch-action: manipulation;
        justify-content: space-between;
        display: flex;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 0;
        z-index: 2;
    }
}