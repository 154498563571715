@import 'styles/mixins';

.flip-container, .front, .back {
	width: 100%;
	height: 100%;
}

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px;
  position: relative;
  height: 100%;
  //TODO should be added from a different part of the app to allow the list selector to show the border proper
  //padding: .6rem 0;

  @include media(md lg xl) {
    width: 100%;
  }
}

.flip-container.flip .flipper {
  transform: rotateX(-180deg);
}

/* flip speed goes here */ 
.flipper {
  height: 100%;
	transition: 0.6s;
	transform-style: preserve-3d;
  transform-origin: 100% 50%;
  position: relative;
  border-radius: 10px;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	//z-index: 2;
	/* for firefox 31 */
	transform: rotateX(0deg);
}

/* back, initially hidden pane */
.back {
	transform: rotateX(180deg);
}
