@import 'styles/_mixins';
@import 'styles/variables';

.styled-topic {
  margin-bottom: 32px;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  .tile-header {
    overflow: hidden;
    border-radius: 10px;
  }

  .topics-shadow {
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.2);
  }

  .topics-header-shadow {
    box-shadow: 0px 0px 0px 1px #E1E6E6;
  }

  .hasSelection-shadow-hover {
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0px 0px 0px 1px #BEC8C6;
      }
    }

    &:active:not(:has(.inactive-topic-tile:active)) {
      transition: all .2s;
      box-shadow: 0px 0px 0px 2px $grey-light-6;

      &:active {
        box-shadow: 0px 0px 0px 3px $blue;
      }
    }
  }

  .selection-shadow-hover {
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0px 0px 0px 1px #BEC8C6, 0px 6px 20px rgba(62, 87, 85, 0.2);
      }
    }

    &:active:not(:has(.sub-topic-button:active)) {
      transition: all .2s;
      box-shadow: 0px 0px 0px 2px #bec8c6;

      &:active {
        box-shadow: 0px 0px 0px 3px $blue, 0px 6px 20px rgba(62, 87, 85, 0.2);
      }
    }
  }

  .left-active-tile {
    position: absolute;
    background: $blue;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    top: 0;
    bottom: 0;
    width: 4px;
  }

  .topic-number {
    background: $white;
    box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
    border-radius: 11px;
    padding: 2px 9px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $blue;
    margin-left: 12px;
  }

  .topic-header-title {
    padding-top: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;

    div {
      overflow: hidden;
    }
  }

  .topic-button {
    height: 36px;
    background: $blue;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;

    @media (pointer: fine) {
      &:hover {
        background: $hover-blue;
      }
    }

    &:active {
      background: $active-blue;
    }
  }

  .sub-topic-button {
    box-shadow: 0 0 0 1px $bx-grey;
    background: $white;
    border-radius: 22px;
    padding: 9px 0;
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;

    svg {
      margin-right: 12px;
    }

    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 0 0 1px $bx-hover-grey;
      }
    }

    &:active {
      box-shadow: none;
      background: $blue;

      div {
        color: $white;
      }

      svg {
        circle {
          fill: $white;
        }

        path {
          fill: $blue;
        }
      }
    }
  }

  .sub-topic-close-button {
    height: 36px;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.2);
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer;
    background: $white;
    position: relative;
    user-select: none;

    svg {
      margin-right: 12px;
      transform: rotate(180deg);
    }

    @media (pointer: fine) {
      &:hover {
        //box-shadow: 0 0 0 1px $bx-gre;
        border: 1px solid $bx-grey
      }
    }

    &:active {
      border: none;
      background: $blue;

      div {
        color: $white;
      }

      svg {
        circle {
          fill: $white;
        }

        path {
          fill: $blue;
        }
      }
    }
  }
}

// sub topics 
.styled-subtopic {
  box-shadow: 0px 10px 32px rgba(62, 87, 85, 0.257293);
  border-radius: 10px;
  margin-bottom: 12px;
  position: relative;
  background: $grey-light-8;
  display: flex;
  flex-direction: column;
  .sub-topic-number {
    text-transform: uppercase;
    background: $white;
    box-shadow: 0px 2px 5px rgba(128, 128, 128, 0.34);
    border-radius: 97px;
    padding: 2px 8px 2px 10px;
    margin-left: 8px;
    user-select: none;
  }
}


.selected-card-btn{
  background-image: $gradient-top-bottom;
  box-shadow: 0 2px 5px 1px rgba(62,87,85,0.22);
  font-weight: bold;
  font-size: 13px;
  color: $white;
  letter-spacing: 0;
  text-align: center;
  padding: 8px 11px;
  border-radius: 18px;
  height: 34px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.selected-items-wrapper{
  max-width: 375px;
  left: calc(calc(100% - 1176px) / 2);
}

.inner-selected-items-wrapper{
  @media (min-width: 600px) and (max-width: 1111px) and (orientation: portrait) {
    max-width: 375px !important;
    bottom: 90px;
  }
}