@import 'styles/_mixins';
@import 'styles/variables';

.prev-btn{
    border-radius: 50%;
    width: 44px;
    min-width: 44px;
    height: 44px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: $default-grey;
    box-shadow: none;
    outline: none;
    pointer-events: all;
    z-index: 2;
    cursor: pointer;
    box-shadow: 0px 0px 0px $bx-grey;
    //visibility: ${props => props.visibility ? 'visible' : 'hidden'};

    @media (pointer: fine) {
        &:hover {
            background: $hover-grey;
            box-shadow: 0px 0px 0px rgba(62, 87, 85, 0.22);
            .active-epllises{
                &:after {
                    background: $linear-gray;
                }
            }
        }
    } 
    &:active {
        background: $blue;
        color: $white;
        .active-epllises{
            &:after {
                background: $linear-blue;
            }
        }
        svg{
            path{
                stroke: $white;
            }
        }
    }
}
.next-btn{
    border-radius: 50%;
    width: 44px;
    min-width: 44px;
    height: 44px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: $default-grey;
    box-shadow: none;
    outline: none;
    pointer-events: all;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    
    // ${props => props.exam && css`
    //     width:${props => props.endPaper ? '98px': '44px'};
    //     height:${props => props.endPaper ? '36px': '44px'};
    //     border-radius: 22px;
    //     box-shadow: 0px 0px 0px #E3E6E6;
    //     justify-content: center;
    //     padding:0px;
    // ` }
    @media (pointer: fine) {
        &:hover {
            background: $hover-grey;
            box-shadow: 0px 0px 0px rgba(62, 87, 85, 0.22);
            .active-epllises{
                &:after {
                    background: $linear-gray;
                }
            }
        }
    } 
    
    &:active {
        background: $blue;
        color: $white;
        
        .active-epllises{
            &:after {
                background: $linear-blue;
            }
        }
        svg{
            path{
                stroke: $white;
            }
        }
    }
}