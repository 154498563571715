@import './_variables';

@mixin media($types) {
  @each $type in $types {
    @if $type == xs {
      @media only screen and (max-width: $viewport-large - 1) {
        @content;
      }
    }
    @if $type == vsm {
      @media only screen and (min-width: $viewport-vsmall) and (max-width: $viewport-vmediam) {
        @content;
      }
    }
    @if $type == sm {
      @media only screen and (min-width: $viewport-small) and (max-width: $viewport-medium - 1) {
        @content;
      }
    }

    @if $type == md {
      @media only screen and (min-width: $viewport-medium) and (max-width: $viewport-large - 1) {
        @content;
      }
    }

    @if $type == lg {
      @media only screen and (min-width: $viewport-large) and (max-width: $viewport-extra-large - 1) {
        @content;
      }
    }

    @if $type == xl {
      @media only screen and (min-width: $viewport-extra-large) {
        @content;
      }
    }
    @if $type == ixs {
      @media only screen and (max-width: $viewport-ixs - 1) {
        @content;
      }
    }
    @if $type == vvsm {
      @media only screen and (max-width: $viewport-vsmall){
        @content;
      }
    }
    @if $type == sm6 {
      @media only screen and (min-width: $viewport-vsmall) and (max-width: $viewport-smedia6 - 1) {
        @content;
      }
    }
    // @if $type == iPad {
    //   @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    //     @content;
    //   }
    // }

    @if $type == tablet {
      @media (min-width: 481px) and (max-width: 600px) { 
        @content;
      }
    }
    @if $type == iPad {
      @media only screen and (min-width: 768px){
        @content;
      }
    }
    @if $type == iPadPro {
      @media only screen and (min-device-width: 1024px) 
      and (max-device-width: 1366px) 
      and (-webkit-min-device-pixel-ratio: 2){
        @content;
      }
    }
    @if $type == phone {
      @media only screen and (max-width: 480px){
        @content;
      }
    }
    @if $type == iPadAir {
      @media only screen and (min-width: 600px) and (max-width: 1023px) { 
        @content;
      }
    }
    @if $type == smallPhone {
      @media only screen and (max-width: 375px){
        @content;
      }
    }
    @if $type == smalliPhone5 {
      @media only screen and (max-width: 320px){
        @content;
      }
    }
    @if $type == iFrameMobile {
      @media only screen and (max-width: 550px){
        @content;
      }
    }
    @if $type == iFrameTablet {
      @media only screen and (max-width: 770px){
        @content;
      }
    }
  }
}

@mixin media-orientation($orientation) {
  @if $orientation == portrait {
    @media screen and (orientation: portrait) {
      @content;
    }
  }

  @if $orientation == landscape {
    @media screen and (orientation: landscape) and (min-width: $viewport-small) {
      @content;
    }
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin placeholder-color {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}

@mixin box-shadow($left, $top, $blur, $spread, $color, $inset:false) {
  @if ($inset) {
    -webkit-box-shadow: inset $left $top $blur $spread $color;
    -moz-box-shadow: inset $left $top $blur $spread $color;
    box-shadow: inset $left $top $blur $spread $color;
  } @else {
    -webkit-box-shadow: $left $top $blur $spread $color;
    -moz-box-shadow: $left $top $blur $spread $color;
    box-shadow: $left $top $blur $spread $color;
  }
}

@mixin round-button-with-shadow {
  height: 44px;
  width: 44px;
  box-shadow: 0 1px 3px 0 rgba(62, 87, 85, 0.22);
  border: solid 0.5px #e3e6e6;
  background: #fff;
  border-radius: 100%;
  cursor: pointer;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  outline: none;
  transition: all .3s;

  @media (pointer: fine) {
    &:hover {
      box-shadow: 0 3px 5px 0 rgba(93, 116, 114, 0.35);
    }
  }
  &:active {
    background-color: #c8cccc;
    box-shadow: 0 0 0 0 rgba(62, 87, 85, 0.22);
  }
}

@mixin slideFromLeft($size, $expandedClass: "open") {
  transform: translate3d(-$size, 0, 0);
  transition: clip 0.35s cubic-bezier(.25, .75, .5, 1), transform 0.35s cubic-bezier(.25, .75, .5, 1);
  clip: rect(0px,436px,100vh,436px);

  &.#{$expandedClass} {
    transition: clip 0.22s cubic-bezier(.25, .75, .5, 1), transform 0.35s cubic-bezier(.25, .75, .5, 1);
    clip: rect(0px,436px,100vh,0px);
    transform: none;
  }
}

@mixin slideFromRight($size, $expandedClass: "open") {
  transform: translate3d($size, 0, 0);
  transition: transform 0.35s cubic-bezier(.25, .75, .5, 1);
  will-change: transform, box-shadow, z-index;
  &.#{$expandedClass} {
    transform: none;
  }
}

@mixin slideFromBottom($size, $expandedClass: "open") {
  transform: translate3d(0, $size, 0);
  transition: transform 0.5s cubic-bezier(.25, .75, .5, 1);
  &.#{$expandedClass} {
    transform: none;
  }
}

@mixin grab-cursor {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

@mixin grabbing-cursor {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

@mixin osc-btn{
  height: 36px;
  display: flex;
  border-radius: 22px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
}



