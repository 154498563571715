@import 'styles/mixins';
@import 'styles/variables';
.video-main-wrapper{
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
   
    @media (max-width: 990px) and (orientation: landscape){
        @media (orientation: landscape){
            padding:14px 0 0 0;
        }
    }
    @media (min-width: 1601px) and (max-width: 1920px) {
        max-width: 754px; //975px
    }
    @media (min-width: 1112px) and (max-width: 1600px) {
        max-width: 754px;
    }
    @media (min-width: 1024px) and (max-width: 1112px){
        @media (orientation: portrait){
            max-width: 754px;
        }
        @media (orientation: landscape){
            max-width: 585px;
        }
    }
    @media (min-width: 990px) and (max-width: 1112px) and (orientation: portrait){
        @media (orientation: portrait){
            max-width: 757px;
        }
        @media (orientation: landscape){
            max-width: 557px;
        }
    }
    @media (min-width: 320px) and (max-width: 1024px) and (orientation: portrait){
       .video-skeleton{
            padding: 16px 0px;
        }
        .video-no-skeleton{
            padding: 14px 0 0 0;
        }
    }
    @media (min-width: 320px) and (max-width: 900px) and (orientation: landscape){
        .video-skeleton{
            padding: 16px 0px;
        }
        .video-no-skeleton{
            padding: 14px 0 0 0;
        }
    }
    @media only screen and (min-height: 822px) and (max-height: 842px) {
        max-width:  734px !important;
    }
    @media only screen and (min-height: 801px) and (max-height: 821px) {
        max-width:  714px !important;
    }
    @media only screen and (min-height: 780px) and (max-height: 800px) {
        max-width:  664px !important;
    }
    @media only screen and (min-height: 759px) and (max-height: 779px) {
        max-width:  614px !important;
    }
    @media only screen and (min-height: 738px) and (max-height: 758px) {
        max-width:  564px !important;
    }
    @media only screen and (max-height: 734px) {
        max-width:  515px !important;
    }
    @media only screen and (min-width: 320px) and (max-width: 568px) and (orientation: portrait){
        max-width: calc(100% - 28px) !important; 
    }
}
.video-skeleton{
    padding: 16px 0px;
}
.video-no-skeleton{
    padding: 24px 0 0 0;
}
.video-title{
    font-weight: normal;
    font-size: 25px;
    line-height: 34px;
    color: $charcoal;
    padding-left: 13px;
    min-height: 46px;
    color: $white;
    @media(orientation: landscape){
        margin-top: 0;
    }
}
.vedio-player-section{
    position: relative;
    @media (min-width: 1200px){
      width: 100% !important;
    }
    @media (max-width: 991px) and (orientation: landscape){
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.video-play{
    @include media(xs sm) {
      @include media-orientation(landscape) {
        background-color: $gray-overlay; 
      }
    }
}
.video-play-icon{
    cursor: pointer;
    height: 44px;
    width: 44px;
    user-select: none;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (pointer: fine) {
        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }
    }
    &:active {
        background: $blue;
    }
}
.video-play-btn{
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $white;
}
.input-range{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 0;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    -webkit-appearance: none;
    outline: none;
}
.video-description{
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: #727373;
    padding: 14px 20px;
    border: 1px solid #536980;
    color: #EEF0F2;
    background: #536980;
    filter: drop-shadow(0px 6px 20px rgba(6, 10, 26, 0.557173));
    border-radius: 10px;
    margin: 14px auto 0 auto;
    max-width: 754px;
    width: calc(100% - 16px);
}
.inner-skeleton-h-video{
    margin-top: 28px;
    background-size: 400px 100% !important;
    border-radius: 10px !important;
    height: 54px !important;
    &::after {
        background-image: linear-gradient(270deg, #4A677E 0%, #344D67 61.29%, #2D3B48 72.52%, #27343F 75.78%, #2D3B48 79.6%, #344D67 88.08%, #4A677E 100%) !important;
        animation-timing-function: linear !important;
        animation-duration: 2s !important;
    }
  }
  .inner-skeleton-d-video{
    background-size: 400px 100% !important;
    margin-top: 12px;
    border-radius: 10px !important;
    &::after {
        background-image: linear-gradient(270deg, #4A677E 0%, #344D67 61.29%, #2D3B48 72.52%, #27343F 75.78%, #2D3B48 79.6%, #344D67 88.08%, #4A677E 100%) !important;
        animation-timing-function: linear !important;
        animation-duration: 2s !important
    }
  }