@import 'styles/variables';
@import 'styles/mixins';

.support-page-items-container {
  font-size: 15px;
  //padding-right: 16px;

  @include media(xs sm) {
    padding-right: 0px;

    @media(orientation: landscape) {
      padding-top: 56px;
    }
  }
}

.support-right-items-container {
  background: linear-gradient(138.13deg, #2EBCB4 0%, #4893FF 100%);
  box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 100%;
  width: 100%;
  //padding: 0 20px;
}

.support-page-item {
  display: block;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  font-size: 15px;
  color: #2D3838;
}

.support-right-page-item {
  display: block;

  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  font-weight: normal;
}

.support-page-item-container {
  position: relative;
  padding: 15px 20px 15px 20px;

  &:hover {
    background: #EFF4F9;
    border-radius: 8px;

    @include media(xs sm) {
      border-radius: 0px;
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      background: none;
    }

    &:active {
      background: none;
    }
  }
}

.support-page-right-item-container {
  position: relative;
  padding: 15px 15px 15px 20px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      background: none;
    }

    &:active {
      background: none;
    }
  }
}

.forward-arrow-icon {
  position: absolute;
  right: 0;
  top: calc(50% - 8px);
  padding-right: 20px;
}

.forward-right-arrow-icon {
  position: absolute;
  right: 0;
  top: calc(50% - 8px);
  padding-right: 20px;
}

.support-title-container {
  display: flex;
  align-items: center;
}

.support-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
  line-height: 1.2;
}

.support-left-section {
  width: 391px;

  @include media(xs) {
    width: 100%;
    padding-left: 0px;
  }
}


.support-manager {
  .page-title {
    padding-left: 20px;
    padding-bottom: 14px;

    @include media(xs) {
      @media(orientation: landscape) {
        display: none;
      }
    }
  }

  @include media(xs) {
    border: none;
    border-radius: 0;
    padding: 0 0;
    max-width: initial;
    align-self: flex-start;
    margin-top: 56px;
    width: 100%;

    @media(orientation: landscape) {
      overflow: auto;
      height: 100%;
    }
  }
}
