@import 'styles/variables';

.simple-button {
  font-weight: bold;
  font-size: 13px;
  color: $turquoise;
  outline: none;
  cursor: pointer;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: inherit;
  &.inverted {
    color: white;
    @media (pointer: fine) {
      &:hover {
        opacity: 0.75;
      }
    }
    &:active {
      color: #0A7670; 
    }

    &:disabled {
      opacity: .4;
      cursor: default;
    }
  }

  &:active {
    color: darken($turquoise, 10);
  }
}
