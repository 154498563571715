@import 'styles/_variables';
@import 'styles/_mixins';

.register-page {
  padding: 15px 20px 0 20px;
  background: white;
  max-width: 40%;

  @include media(xs sm) {
    max-width: initial;
  }

  .back-button-wrapper {
    display: inline-block;
  }

  .register-title {
    padding-top: 32px;
    font-size: 32px;
    color: #2C3838;
    font-weight: bolder;
  }

  .sign-up-btn {
    margin: 22px 0;
    border-radius: 27px;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 2px;
    box-shadow: 0 1px 3px 0 rgba(93, 116, 114, 0.35);
    font-weight: bold;
    height: 54px;
    transition: all .3s;
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 3px 5px 0 rgba(93, 116, 114, 0.35);
      }
    }
    &:active {
      background-image: linear-gradient(99deg, #3dad8d, #3d9bad);
      box-shadow: none;
    }
  }

  .disabled-btn {
    background: #c8cccc;
    cursor: initial;
    @media (pointer: fine) {
      &:hover {
        box-shadow: 0 1px 3px 0 rgba(93, 116, 114, 0.35);
      }
    }
    &:active {
      background: #c8cccc;
      box-shadow: 0 1px 3px 0 rgba(93, 116, 114, 0.35);
    }
  }

  .horizontal-separator {
    background-color: $grey-light;
    width: 100%;
  }

  .choose-avatar-container {
    margin-top: 22px;
    cursor: pointer;
  }
  .choose-avatar-label {
    font-size: 14px;
    font-weight: 600;
    color: #a2a3a3;

    &.active {
      color: $turquoise;
    }
  }
  .choose-avatar-placeholder {
    display: block;
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0;
    white-space: normal;
    border-bottom: 1px solid #e3e6e6;
    line-height: 35px;
    font-size: 15px;
    margin-top: 4px;
    color: #c8cecf;
    font-weight: 300;
  }

  .form-error-message {
    color: $error-color;
    padding: 12px 0;
    text-align: center;
    font-size: 12px;
  }
}
