@import "styles/variables";
@import "styles/_mixins";

.swal-overlay {
  background-color: rgba(#133956, .75);
}

.swal-modal {
  width: 280px;
  border-radius: 8px;
}

.swal-content {
  margin-top: 0;
  padding: 0;
  font-size: 100%;
}

.swal-button-container {
  width: 100%;
}

.swal-button {
  background-color: transparent;
  color: $turquoise;
  outline: 0;
  box-shadow: none;
  padding: 13px 0 17px 0;
  font-weight: 600;
  font-size: 17px;
  width: 100%;

  &:active {
    background-color: transparent;
  }

  &:focus {
    box-shadow: none;
  }
}

.swal-footer {
  border-top: 1px solid $grey-light;
  text-align: center;
  font-size: 17px;
  font-weight: 100;
  padding: 0;
  margin: 0;
}

.swal-button-container {
  margin: 0;
}

.swal-button {
  padding: 10px 0 17px 0;
  font-size: 17px;
  line-height: 19px;
}

.swal-notification {
  font-family: "Open Sans", sans-serif;
  padding: 0 13px;

  .swal-notification-image {
    padding: 20px 0 18px 0;
  }

  .swal-notification-title {
    font-size: 17px;
    line-height: normal;
    text-align: center;
    color: $charcoal;
    font-weight: 700;

    .highlighted {
      color: $turquoise;
      word-break: break-all;
      display: inline-block;

      .sentence-end-dot {
        color: $charcoal;
      }
    }
  }
  .swal-notification-close {
    background: $blue;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    cursor: pointer;
    @media (pointer: fine) {
      &:hover {
        background: $hover-blue;
      }
    } 
    &:active {
      background: $active-blue;
    }
  }

  .swal-notification-text {
    font-family: "Open Sans", sans-serif;
    padding: 16px 0 20px 0;
    text-align: center;
    color: $grey-light-2;
    line-height: 18px;
    font-weight: 400;
    font-size: 15px;

    .swal-link {
      font-weight: 600;
    }

    .add-to-home-bottom-text {
      font-size: 13px;
      font-weight: 700;
      padding-top: 20px;
      margin: 0 -6px;
    }

    .add-to-home-icon {
      padding: 0 3px;
      vertical-align: bottom;
    }

    a {
      color: $turquoise;
    }
  }
  img{
    display: unset !important;
  }
}

.modal-tutorial-content {
  text-align: center;
}

.image-text-container {
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  .text {
    margin-left: 14px;
  }
}

.modal-tutorial-text {
  color: $white;
  border-radius: 8px;
  border: solid 1px $white;
  padding: 18px 14px;
  font-size: 15px;
  line-height: 1.2;
  margin-top: 10px;
  text-align: left;
  .title {
    padding-bottom: 16px;
    text-align: center;
  }
  &.less-padding {
    padding: 14px 8px;
  }
  &.swal-container {
    text-align: center;
    color: $grey-light-2;
    border: solid 1px $bx-grey;
    margin: 13px;
    .title {
      color: black;
      font-weight: bold;
      font-size: 17px;
    }
  }
}

.notification-text {
  color: $grey-light-2;
  font-size: 13px;
  line-height: normal;
  padding: 0 25px 14px;
}

.swal-container {
  position: relative;
}

.swal-container:after,
.swal-container:before {
  content: '';
  display: block;
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  transform: translate(-50%, 0);
}

.swal-container:after {
  top: -20px;
  left: 50%;
  border-color: transparent transparent $white transparent;
  border-width: 10px;
}

.swal-container:before {
  top: -24px;
  left: 50%;
  border-color: transparent transparent $bx-grey transparent;
  border-width: 12px;
}

.start-tutorial-button, .notification-button {
  font-size: 17px;
  text-align: center;
  color: $turquoise;
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
}

.notification-button {
  border-top: solid 1px $grey-light-12;
  font-weight: bold;

  &.thin-text {
    font-weight: 400;
  }
}

.swal-notification {
  &.start-tutorial-container {
    padding: 15px 0 0 0;
  }

  &.clear-margin {
    margin-bottom: -20px;
    padding: 0;
  }
}

.notification-content {
  padding: 18px 20px;
}

.notification-icon {
  margin-bottom: 18px;
}

.accept-button {
  border-top: solid 1px $grey-light-12;
  border-bottom: solid 1px $grey-light-12;
}

.later-button {
  padding-bottom: 0;
}

.thin-text {
  font-weight: 400;
}

.extra-paragraph {
  color: black;
  font-weight: bold;
  &.add-space {
    padding-top: 10px;
  }
}

.single-button {
  border-bottom: none;
  padding-bottom: 0;
}

.swal-email-text {
  color: black;
  padding-top: 5px;
  font-weight: bold;
}
.subject-notification {
  background: $gradient-bg-red-color;
  border: 2px solid $white;
  border-radius: 13px;
  height: 26px;
  min-width: 42px;
}
.counter-notification-wrapper{
  background: $gradient-bg-red-color;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  display: flex;
  color: $white;
  text-align: center;
  font-size: 13px;
  font-weight: 600;;
  padding: 0 5px;
  line-height: 1.1;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
}
.exercise-start-notification-wrapper{
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 125px;
  background: rgba(32, 61, 60, 0.8);
  border-radius: 8px;
  padding: 19px 0 24px 0;
  margin-bottom: -20px;
  align-items: center;
  justify-content: space-between;
}
.highlighted {
  color: $turquoise;
  word-break: break-all;
  display: inline-block;

  .sentence-end-dot {
    color: $charcoal;
  }
}
img{
  display: unset !important;
}

.notification-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 488px;
	height: 34px;
	-webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
	background-image: linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
	box-shadow: 0 4px 12px 0 rgba(62, 87, 85, 0.29);
  position: fixed;
  top: 8px;
  right: 0;
  left: 0;
  border-radius: 17px;
  padding-left: 16px;
  padding-right: 8px;
  z-index: 9999;
  @include box-shadow(0px, 2px, 12px, 0, rgba(62, 87, 85, .29));
  animation: slideIn 0.35s linear;
  margin: 0 auto;
  &.notification-hidden{
    animation: slideOut 0.35s linear;
  }
  @media (max-width: 991px) and (orientation: portrait){
    width: 95%;
    height: 38px;
    border-radius: 20px;
  }
  @media (min-width: 600px) and (max-width: 1111px) and (orientation: portrait) {
    width: 488px !important;
    height: 38px !important;
    border-radius: 17px !important;
  }
}
.answer-text{
  font-size: 13px;
  color: $white;
  font-weight: bold;
  padding-left: 8px;
  @media (max-width: 991px) and (orientation: portrait){
    text-align: left;
    margin-right: 5px;
    line-height: 16px;
  }
}
.answer-correct-text{
  font-size: 13px;
  color: $white;
  @media (max-width: 991px) and (orientation: portrait){
    text-align: left;
    margin-right: 5px;
    line-height: 16px;
  }
}
.xxl-text{
  font-size: 13px;
  color: $white;
  font-weight: bold;
  @media (max-width: 991px) and (orientation: portrait){
    text-align: left;
    margin-right: 5px;
    line-height: 16px;
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50px);
  }
}
