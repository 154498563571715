@import 'styles/_mixins';
@import 'styles/variables';

.media-tab-section{
    display: flex;
    flex-direction: column;
    flex: 1;
    cursor: pointer;
    
    img{
        height: 40px;
    }
}
.active-tab{
    &:after{
        content: '';
        background: $white;
        box-shadow: 0px 1px 3px rgba(30, 76, 172, 0.514423);
        border-radius: 14px;
        height: 6px;
        min-width: 83px;
        margin-top: 6px;
        align-items: center;
        display: flex;
    }
}
.pointer-none{
    pointer-events: none;
    user-select: none;
}
.media-status-bar-wrapper{
    background: $white;
    box-shadow: 0px 6px 20px rgba(62, 87, 85, 0.2);
    border-radius: 10px;
}
.study-meta-info{
    box-shadow: 0 0 0 1px #e1e6e6;
    min-width: 298px ;
    border-radius: 10px;
    .mediaTab-image{
        width: 40px;
        height: 40px;
        // position: absolute;
    }
}
.media-tab-inner{
    width: 100%;
    background: linear-gradient(225deg, #43DAF0 0%, #395BF8 100%);
    padding: 16px 20px;
}
.media-study-btn{
    background: $blue;
    border-radius: 22px;
    padding: 8px 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin: ${props => props.isMarging ? "14px 0 24px 0" : props.exercise ? '14px 0 23px 0' : '14px 0 46px 0' };
    cursor: pointer;
    width: 100%;
    >svg{
        margin-right: 8px;
    }
    @media (pointer: fine) {
        &:hover {
            background: $hover-blue;
        }
    } 
    &:active {
        background: $active-blue;
    }
}
.media-haese{
    background: $grey-light-7;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: $grey-light-3;
    padding: 8px 12px;
    flex-flow: wrap;
    margin-bottom: 24px;
    a{
        color: $blue;
        @media (pointer: fine) {
            &:hover {
                color: $hover-blue;
            } 
        }
        &:active {
            color: $active-blue;
        }
    }
}
.quick-study-btn{
    max-height: 36px;
    background: $white;
    box-shadow: 0 0 0 1px $bx-grey;
    border-radius: 22px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    position: relative;
    svg{
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
    @media (pointer: fine) {
        &:hover {
            box-shadow: 0 0 0 1px $bx-hover-grey;
        }
    } 
    &:active {
        box-shadow: none;
        background: $blue; 
        .quick-text{
            color: $white;
        }
        svg{
          circle{
            fill: $white;
          }
          ellipse{
              stroke: $white;
          }
          path{
            stroke: $white;
          }
          path.path-number {
              fill: $blue;
          }
        }
    }
}
.quick-study-btn-loading{
    height:36px;
    padding:0 !important;
    pointer-events: none;
    color: $bx-hover-grey; 
    .icon-wrapper {
        opacity: 0.5; 
        rect, circle {
          stroke: $grey-light-2;
          fill: $grey-light-2;
        }
        path, ellipse {
          stroke: $grey-light-2;
        }
    }
}
.continue-btn{
    background: $white;
    max-height: 36px;
    height: 36px;
    box-shadow: 0 0 0 1px $bx-grey;
    border-radius: 22px;    
    padding: 8px 0;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    position: relative;
    svg{
        margin-right: 8px;
    }
    svg{
      margin-right: 12px;
    }
    @media (pointer: fine) {
        &:hover {
            box-shadow: 0 0 0 1px $bx-hover-grey;
        }
    } 
    &:active {
        box-shadow: none;
        background: $blue;
        .quick-text{
            color: $white;
        }
        svg{
          circle{
            fill: $white;
          }
          path{
            stroke: $white;
          }
          path.path-number {
              fill: $white;
          }
        }
      }
}